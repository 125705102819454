const { checkStartWith } = require('./validation');

const getExistTabCounts = (cardIdFirstLine, tabs) => {
  const existCounts = [];

  for (let i = 0; i < tabs.length; i += 1) {
    const tabId = tabs[i].id;

    if (checkStartWith(tabId, cardIdFirstLine)) {
      const idCount = Number(tabId.split('-').pop());

      if (!existCounts.includes(idCount)) {
        existCounts.push(idCount);
      }
    }
  }

  return existCounts;
};

const getCardCounter = (existCounts = []) => {
  let counter = 1;
  const maxExistCounter = Math.max(...existCounts);

  while (counter <= maxExistCounter) {
    if (!existCounts.includes(counter)) {
      return counter;
    }

    counter += 1;
  }

  return counter;
};

const sortTabsByCounter = (tabs) => tabs.sort((a, b) => String(a.id).localeCompare(b.id))
  .sort((a, b) => {
    const idA = typeof a.id === 'string' ? Number(a.id.split('-').pop()) : a.id;
    const idB = typeof b.id === 'string' ? Number(b.id.split('-').pop()) : b.id;

    if (a.componentName === b.componentName) {
      return idA - idB;
    } return 1;
  });

const isTabExist = (tab, tabs) => {
  for (let i = 0; i < tabs.length; i += 1) {
    if (tab.id === tabs[i].id && tab.componentName === tabs[i].componentName) {
      return true;
    }
  }

  return false;
};

const removeTab = (tab, source) => {
  const result = [];

  for (let i = 0; i < source.length; i += 1) {
    const card = source[i];

    if (card.componentName === tab.componentName) {
      if (card.id !== tab.id) {
        result.push(card);
      }
    } else {
      result.push(card);
    }
  }

  return result;
};

module.exports = {
  getExistTabCounts,
  getCardCounter,
  sortTabsByCounter,
  isTabExist,
  removeTab,
};
