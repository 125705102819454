var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ao-button",
      class: [
        _vm.buttonClasses,
        {
          invalid: _vm.invalid,
          "ao-button_rounded": _vm.rounded,
          "ao-button_minified": _vm.minified
        }
      ],
      attrs: { type: "button", disabled: _vm.disabled },
      on: { click: _vm.handleClick }
    },
    [
      _vm._t("default", function() {
        return [_vm._v("Button")]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }