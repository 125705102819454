var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _vm.isVisible
      ? _c(
          "div",
          {
            staticClass: "custom-modal__wrapper",
            class: ["y-" + _vm.positionY, "x-" + _vm.positionX],
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.closeModal.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "custom-modal",
                style: { minWidth: _vm.minWidth }
              },
              [
                _c("header", { staticClass: "custom-modal__header" }, [
                  _c("p", { staticClass: "custom-modal__title" }, [
                    _vm._v(_vm._s(_vm.modalTitle))
                  ]),
                  _vm._v(" "),
                  _vm.closeButton && _vm.closable
                    ? _c(
                        "button",
                        {
                          staticClass: "custom-modal__close-btn",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal }
                        },
                        [_c("cross-icon")],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "main",
                  {
                    staticClass: "custom-modal__main",
                    class: { separated: _vm.sectionSeparated }
                  },
                  [_vm._t("main")],
                  2
                ),
                _vm._v(" "),
                _vm.$slots.footer
                  ? _c(
                      "footer",
                      { staticClass: "custom-modal__footer" },
                      [_vm._t("footer")],
                      2
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }