<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <ao-popup
    closable
    :parent="parent"
    :scrollableAreaSelector="scrollableAreaSelector"
    class="column-config-popup"
    @onClose="hideElement"
  >
    <template>
      <div class="column-config">
        <div v-if="isSortable">
          <button
            type="button"
            class="column-config__button sorted-button"
            :class="{ active: this.sortBy === 'asc' }"
            @click="changeSorting('asc')"
          >
            <arrow-icon />
            <l10n value="Сортировка по возрастанию" />
          </button>
          <button
            type="button"
            class="column-config__button sorted-button desc"
            :class="{ active: this.sortBy === 'desc' }"
            @click="changeSorting('desc')"
          >
            <arrow-icon />
            <l10n value="Сортировка по убыванию" />
          </button>
        </div>

        <div v-if="isFilterable" class="column-config__filter-wrapper">
          <button
            type="button"
            class="column-config__button column-config__filter-button"
            :class="{ opened: showFilter.byCondition, invalid: !isFilterValid && tryToSave }"
            @click="toggleFilterByCondition"
          >
            <button-arrow-icon />
            <l10n value="Фильтр по условию" />
          </button>
          <transition name="slide">
            <div v-if="showFilter.byCondition" class="column-config__filter">
              <div class="column-config__filter-row">
                <my-multiselect
                  v-model="filterRule"
                  :options="filterRules"
                  closeOnSelect
                  label="name"
                  trackBy="id"
                  placeholder="Выберите условие"
                />
              </div>
              <div v-if="!isFilterValueBoolean" class="column-config__filter-row">
                <ao-input
                  v-if="isNumericFilter"
                  v-model="filterValue"
                  :invalid="tryToSave && !isFilterValueValid"
                  :disabled="!filterRule"
                  :onlyInteger="!isNotInteger"
                  :maxLength="filterValueMaxLength"
                  :fractionalMaxLength="numericFractionalMaxLength"
                  :integerMaxLength="numericIntegerMaxLength"
                  type="number"
                  placeholder="Введите значение"
                  size="small"
                />
                <ao-input
                  v-if="isDatesFilter"
                  v-model="filterValue"
                  :invalid="tryToSave && !isFilterValueValid"
                  :disabled="!filterRule"
                  type="date"
                  placeholder="Введите значение"
                  size="small"
                />
                <ao-input
                  v-if="isTextFilter"
                  v-model="filterValue"
                  :maxLength="100"
                  :invalid="tryToSave && !isFilterValueValid"
                  :disabled="!filterRule"
                  placeholder="Введите значение"
                  size="small"
                />
              </div>
            </div>
          </transition>
        </div>

        <div v-if="useFilterByUniqueValue" class="column-config__filter-wrapper">
          <button
            type="button"
            class="column-config__button column-config__filter-button"
            :class="{ opened: showFilter.byUniqueValue }"
            @click="toggleFilterByUniqueValues"
          >
            <button-arrow-icon />
            <l10n value="Фильтр по значению" />
          </button>
          <transition name="slide">
            <div v-if="showFilter.byUniqueValue" class="column-config__filter unique-filter">
              <div class="column-config__filter-row">
                <div class="unique-filter__input-wrapper">
                  <ao-input
                    v-model="searchUniqueValue"
                    class="unique-filter__input"
                    placeholder="Введите значение"
                  />
                  <i class="unique-filter__icon-wrapper">
                    <search-icon />
                  </i>
                </div>
              </div>
              <div class="column-config__filter-row">
                <ul v-if="uniqueValuesAfterSearch.length > 0" class="unique-filter__list">
                  <li class="unique-filter__list-item">
                    <button
                      type="button"
                      class="unique-filter__item-button"
                      @click.prevent="checkAllUniqueValues"
                    >
                      <ao-checkbox :checked="isAllUniqueValueChecked" />
                      <l10n value="Выбрать все" />
                    </button>
                  </li>
                  <li
                    v-for="uniqueValue of uniqueValuesAfterSearch"
                    :key="uniqueValue"
                    class="unique-filter__list-item"
                  >
                    <button
                      type="button"
                      class="unique-filter__item-button"
                      @click.prevent="toggleUniqueValue(uniqueValue)"
                    >
                      <ao-checkbox :checked="isUniqueValueChecked(uniqueValue)" />
                      <span>{{ uniqueValue }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="footer__button-row">
        <ao-button type="secondary" @click="resetChanges">
          <l10n value="Сбросить" />
        </ao-button>
        <ao-button :disabled="isConfigNotChanged" @click="confirmChanges">
          <l10n value="Применить" />
        </ao-button>
      </div>
    </template>
  </ao-popup>
</template>

<script>
const { DOMAIN } = require('../../constants/endpoint');
const { getTrimmedString } = require('../../utils/formatters');
const { mathsFilterRules, textFilterRules } = require('../../constants/filterRules');
const AoPopup = require('../ao-popup.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const AoCheckbox = require('../shared/ao-checkbox.vue').default;
const AoInput = require('../shared/ao-input.vue').default;
const MyMultiselect = require('../../../my-multiselect.vue').default;
const ArrowIcon = require('../../assets/icons/arrow-icon.vue').default;
const SearchIcon = require('../../assets/icons/search-icon.vue').default;
const ButtonArrowIcon = require('../../assets/icons/button-arrow-icon.vue').default;

module.exports = {
  model: {
    prop: 'isVisible',
    event: 'onChangeVisibility',
  },

  components: {
    AoPopup,
    AoButton,
    AoCheckbox,
    AoInput,
    MyMultiselect,
    ArrowIcon,
    SearchIcon,
    ButtonArrowIcon,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    parent: {
      type: [Object, Element],
      required: false,
      default: () => null,
    },
    scrollableAreaSelector: {
      type: String,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: () => ({
        sorting: false, // boolean
        filterType: null, // 'numeric' | 'dates' | 'text'
        meta: {},
      }),
    },
    modelName: {
      type: String,
      required: false,
      default: null,
    },
    columnId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    configPreset: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      sortBy: null,
      filterRule: null,
      filterValue: null,
      showFilter: {
        byUniqueValue: false,
        byCondition: false,
      },
      uniqueValues: [],
      searchUniqueValue: '',
      checkedUniqueValues: [],
      tryToSave: false,
      uniqueFilterVisibleChanged: false,
    };
  },

  computed: {
    isFilterable() {
      return this.config?.filterType;
    },
    useFilterByUniqueValue() {
      return this.config?.meta?.filterByUniqueValue || false;
    },
    isSortable() {
      return this.config?.sorting;
    },
    isNumericFilter() {
      return this.config?.filterType === 'numeric';
    },
    isNotInteger() {
      return this.config?.meta?.isNotInteger || false;
    },
    numericFractionalMaxLength() {
      return this.config?.meta?.fractionalMaxLength || null;
    },
    numericIntegerMaxLength() {
      return this.config?.meta?.integerMaxLength || null;
    },
    filterValueMaxLength() {
      return this.config?.meta?.maxLength || null;
    },
    isDatesFilter() {
      return this.config?.filterType === 'dates';
    },
    isTextFilter() {
      return this.config?.filterType === 'text';
    },
    filterRules() {
      // eslint-disable-next-line no-nested-ternary
      return this.isNumericFilter || this.isDatesFilter
        ? mathsFilterRules
        : this.isTextFilter ? textFilterRules : [];
    },
    isFilterValueBoolean() {
      return this.filterRule?.id === 'is_null' || this.filterRule?.id === 'is_not_null';
    },
    isFilterValueValid() {
      switch (this.config?.filterType) {
        case 'numeric':
          return !!(this.filterValue && typeof Number(this.filterValue) === 'number');

        case 'dates':
          return !!this.filterValue?.length;

        case 'text':
          return this.filterValue && !!getTrimmedString(this.filterValue);

        default:
          return false;
      }
    },
    isFilterValid() {
      return !this.config?.filterType || !this.filterRule
        || (!!this.filterRule && this.isFilterValueBoolean)
        || (!!this.filterRule && this.isFilterValueValid);
    },
    configBody() {
      return {
        sortBy: this.sortBy,
        filter: {
          rule: this.filterRule,
          value: this.isFilterValueBoolean ? true : this.filterValue,
        },
        ...(this.checkedUniqueValues?.length ? { uniqueValues: this.checkedUniqueValues } : {}),
      };
    },
    isConfigNotChanged() {
      return !this.sortBy && !this.filterRule && !this.checkedUniqueValues.length;
    },
    uniqueValuesAfterSearch() {
      return this.uniqueValues.filter((value) => String(value).includes(this.searchUniqueValue));
    },
    isAllUniqueValueChecked() {
      return this.checkedUniqueValues.length === this.uniqueValues.length;
    },
    presetUniqueValues() {
      return this.configPreset?.uniqueValues || [];
    },
  },

  mounted() {
    if (this.configPreset) {
      this.sortBy = this.configPreset.sortBy || null;
      this.filterRule = this.configPreset.filter?.rule || null;
      this.filterValue = this.configPreset.filter?.value ?? null;
      this.checkedUniqueValues = [...this.presetUniqueValues];
    }

    if (this.useFilterByUniqueValue && this.modelName) {
      this.fetchUniqueValues();
    }
  },

  watch: {
    filterRule(newValue) {
      if (!newValue || this.isFilterValueBoolean) {
        this.filterValue = null;
      }
    },
  },

  methods: {
    hideElement() {
      this.$emit('onChangeVisibility', false);
    },
    confirmChanges() {
      this.tryToSave = true;

      if (this.isFilterValid) {
        const payload = {
          id: this.columnId,
          ...this.configBody,
        };

        this.$emit('onApply', payload);
        this.$emit('onChangeVisibility', false);
      }
    },
    resetChanges() {
      this.clearConfig();
      this.$emit('onReset', this.columnId);
      this.$emit('onChangeVisibility', false);
    },
    changeSorting(rule) {
      this.sortBy = this.sortBy === rule ? null : rule;
    },
    clearConfig() {
      this.sortBy = null;
      this.filterRule = null;
      this.filterValue = null;
      this.checkedUniqueValues = [];
    },
    toggleFilterByCondition() {
      if (!this.showFilter.byCondition) {
        this.showFilter.byCondition = true;
        this.showFilter.byUniqueValue = false;
        this.searchUniqueValue = '';
        this.checkedUniqueValues = [];
      } else {
        this.showFilter.byCondition = false;
      }
    },

    // filter by unique value
    async fetchUniqueValues() {
      try {
        const url = `${DOMAIN}/filters/get-unique-values/`;
        const body = JSON.stringify({
          model_name: this.modelName,
          fields_names: [this.columnId],
        });
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body,
        };
        const response = await fetch(url, options);

        if (response.ok) {
          const { fields } = await response.json();
          const uniqueValuesContainer = fields?.find(
            (item) => item.field_name === this.columnId,
          ) || {};
          const { values } = uniqueValuesContainer;
          this.uniqueValues = values || [];
        } else {
          throw new Error();
        }
      } catch (error) {
        this.createNotification('Ошибка при получении уникальных значений', 'error');
      }
    },
    toggleUniqueValue(value) {
      if (this.isUniqueValueChecked(value)) {
        this.checkedUniqueValues = [...this.checkedUniqueValues].filter((item) => item !== value);
      } else {
        this.checkedUniqueValues.push(value);
      }
    },
    checkAllUniqueValues() {
      this.checkedUniqueValues = this.isAllUniqueValueChecked
        ? []
        : [...this.uniqueValues];
    },
    isUniqueValueChecked(value) {
      return this.checkedUniqueValues.includes(value);
    },
    toggleFilterByUniqueValues() {
      if (!this.showFilter.byUniqueValue) {
        this.showFilter.byUniqueValue = true;
        this.showFilter.byCondition = false;
        this.filterRule = null;
        this.filterValue = null;
      } else {
        this.showFilter.byUniqueValue = false;
      }

      if (!this.uniqueFilterVisibleChanged) {
        this.checkedUniqueValues = this.presetUniqueValues?.length
          ? [...this.presetUniqueValues]
          : this.uniqueValues;
        this.uniqueFilterVisibleChanged = true;
      }
    },

    getTrimmedString,
  },
};
</script>

<style scoped>
  .column-config-popup>>>.ao-popup__main {
    padding: 8px 0 0 0;
  }

  .column-config-popup>>>.ao-popup__footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .column-config {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .column-config__button {
    width: 100%;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    outline: none;
    border: none;
    background-color: #fff;
    cursor: pointer;
  }

  .column-config__button:hover svg {
    color: #000000;
  }

  .column-config__button svg {
    color: #AFAFAF;
    width: 24px;
    height: 24px;
    transition: all 0.1s ease;
  }

  .column-config__button.desc svg {
    transform: rotate(180deg);
  }

  .sorted-button.active {
    background-color: #F7F7F7;
  }

  .column-config__filter-button:not(.opened).invalid {
    background-color: #FAE6EB;
  }

  .column-config__filter-button:not(.opened) svg {
    transform: rotate(180deg);
  }

  .column-config__filter {
    padding-left: 42px;
    padding-right: 18px;
  }

  .column-config__filter-row {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .unique-filter  {
    max-height: 150px;
    overflow-y: auto;
  }

  .unique-filter__input-wrapper {
    position: relative;
  }

  .unique-filter__icon-wrapper {
    position: absolute;
    top: 6px;
    right: 8px;
  }

  .unique-filter__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .unique-filter__list-item {
    padding: 0;
    margin: 0;
  }

  .unique-filter__item-button {
    width: 100%;
    border-radius: 4px;
    padding: 4px 12px;
    margin: 0;
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }

  .unique-filter__item-button:hover {
    background-color: #F7F7F7;
  }

  .slide-leave-active,
  .slide-enter-active {
    position: relative;
    z-index: -1;
    transition: all 0.1s ease;
  }

  .slide-enter, .slide-leave-to {
    transform: translateY(-50%);
    opacity: 0;
    position: relative;
    z-index: -1;
  }

  .column-config>>>.multiselect__tags {
    padding-top: 8px;
    min-height: 36px;
  }

  .column-config>>>.multiselect__select {
    width: 36px;
    height: 36px;
  }

  .column-config>>>.ao-input {
    padding-top: 9px;
    padding-bottom: 9px;
    width: 100%;
  }

  .column-config>>>.ao-input[type="date"] {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .footer__button-row {
    display: flex;
    gap: 8px;
  }
</style>
