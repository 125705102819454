<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vue/max-len -->
<template>
  <div class="forecast-management-container">
    <div class="left-side">
      <table class="ao-table">
        <tr
          id="tr-without-borders"
          v-for="row in forecastRows"
          :key="row.id"
          :style="{ 'padding-left': `${row.level * 16}px` }"
          :class="{ 'row-selected': selectedChart === row.value }"
          @click="changeTemplate(row.value)"
        >
          <td class="node-button">
            <node-button
              v-if="row.nodeType === 'node'"
              :isOpen="row.isOpen"
            />
          </td>
          <td>
            {{ row.value }}
          </td>
        </tr>
      </table>
      <div
        v-if="selectedChart === 'History Review'"
        class="dates">
        <p class="exclude-title">Исключить даты:</p>
        <div class="date-row wihout-calendar">
          <input
            v-model="date3"
            class="inputblock__input input"
            id="date1"
            placeholder="Выберите период"
            type="date"
          />
          <div class="input-group-addon">—</div>
          <input
            v-model="date4"
            class="inputblock__input input"
            id="date2"
            placeholder="Выберите период"
            type="date"
          />
        </div>
        <div class="date-row wihout-calendar">
          <input
            v-model="date5"
            class="inputblock__input input"
            id="date1"
            placeholder="Выберите период"
            type="date"
          />
          <div class="input-group-addon">—</div>
          <input
            v-model="date6"
            class="inputblock__input input"
            id="date2"
            placeholder="Выберите период"
            type="date"
          />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10" /><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>
      </div>
    </div>
    <div class="right-side">
      <div class="filters">
        <div class="date-row wihout-calendar">
          <input
            v-model="date1"
            class="inputblock__input input"
            id="date1"
            placeholder="Выберите период"
            type="date"
          />
          <div class="input-group-addon">—</div>
          <input
            v-model="date2"
            class="inputblock__input input"
            id="date2"
            placeholder="Выберите период"
            type="date"
          />
        </div>
        <div class="inputblock">
          <my-multiselect
            id="shops"
            :options="shops"
            :selectedOption="shops[0]"
            placeholder="Выберите узел"
          />
        </div>
        <div class="inputblock">
          <my-multiselect
            id="products"
            :options="products"
            :selectedOption="products[0]"
            placeholder="Выберите товар"
          />
        </div>
        <label v-if="selectedChart === 'Alert Review'" for="date7">Дата расчета: </label>
        <input
          v-if="selectedChart === 'Alert Review'"
          v-model="date7"
          class="inputblock__input input"
          id="date7"
          placeholder="Дата расчета"
          type="date"
        />
        <div
          v-if="selectedChart === 'Alert Review'"
          class="inputblock">
          <my-multiselect
            id="versions"
            :options="versions"
            :selectedOption="versions[0]"
            placeholder="Выберите версию"
          />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw ml-auto"><polyline points="23 4 23 10 17 10" /><polyline points="1 20 1 14 7 14" /><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" /></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" /><polyline points="17 21 17 13 7 13 7 21" /><polyline points="7 3 7 8 15 8" /></svg>
      </div>
      <template v-if="selectedChart === 'Alert Review'">
        <div class="right-side-container">
          <table style="width: 100%" class="ao-table">
            <tbody>
              <tr class="ao-table__row bold">
                <td
                  v-for="column in tableColumns"
                  :key="column.title"
                  class="ao-table__cell"
                >
                  <span class="ao-table__cell-text">
                    {{ column.title }}
                  </span>
                </td>
              </tr>
              <tr
                v-for="(row, i) in rows"
                :key="`row-${i}`"
                class="ao-table__row"
              >
                <td
                  v-for="column, i in row"
                  :key="`column-${i}`"
                  class="ao-table__cell"
                >
                  <span
                    class="ao-table__cell-text"
                    :contenteditable="i === 10 || i === 14 || i === 18 ? true : false"
                  >
                    {{ column }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <vue-chart ref="canvas" id="chart-container" type="bar" :data="chart1Data" :options="chartOptions" />
        </div>
      </template>
      <template v-if="selectedChart === 'History Review'">
        <vue-chart type="bar" :data="chart2Data" :options="chartOptions" />
      </template>
      <template v-if="selectedChart === 'Event Simulation'">
        <div class="event-simulation-container">
          <div class="event-simulation-table">
            <table style="width: 100%" class="ao-table">
              <tbody>
                <tr class="ao-table__row bold">
                  <td
                    v-for="column, i in table3Columns"
                    :key="column"
                    class="ao-table__cell"
                    :class="{ 'without-border': i === 0, 'width-limited': i !== 0 }"
                  >
                    <span class="ao-table__cell-text">
                      {{ column }}
                    </span>
                  </td>
                </tr>
                <tr
                  v-for="(row, i) in rows3"
                  :key="`row-${i}`"
                  class="ao-table__row"
                >
                  <td
                    v-for="column, i in row"
                    :key="`column-${i}`"
                    class="ao-table__cell"
                    :class="{ bold: i === 0, 'without-border': i === 0, 'width-limited': i !== 0 }"
                  >
                    <span
                      class="ao-table__cell-text"
                      :contenteditable="i === 0 ? false : true"
                    >
                      {{ column }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <vue-chart ref="canvas" id="chart_number3" type="bar" :data="chart3Data" :options="chartOptions" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const MyMultiSelect = require('../my-multiselect.vue').default;
const NodeButton = require('./components/shared/ao-node-button.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
    'node-button': NodeButton,
  },

  data() {
    return {
      date1: '2023-08-02',
      date2: '2023-10-03',
      date3: '2023-08-01',
      date4: '2023-08-01',
      date5: '2024-01-01',
      date6: '2024-12-31',
      date7: '2023-09-10',
      shops: [
        { id: 1, name: 'ТЦ "Колумбус"' },
        { id: 2, name: 'Новая Рига' },
        { id: 3, name: 'Белая Дача' },
        { id: 4, name: 'ИМ Москва' },
        { id: 5, name: 'Новосибирск ТЦ' },
        { id: 6, name: 'Рязань Премьер' },
      ],
      products: [
        { id: 1, name: 'Салфетки однослойные HOFF, 24х24 см, 100 шт.' },
        { id: 2, name: 'Салфетки двухслойные HOFF, 24х24 см, 100 шт.' },
        { id: 3, name: 'Набор ёмкостей для продуктов BERKRAFT FUSION' },
        { id: 4, name: 'Лампа галогенная ЭРА G4-JC-20W-12V' },
        { id: 5, name: 'Ёмкость для сыпучих продуктов IDEA М 1221' },
        { id: 6, name: 'Контейнер для лимона HOFF Lemon 12887 4342731' },
        { id: 7, name: 'Набор ёмкостей для продуктов BERKRAFT FUSION' },
        { id: 8, name: 'Набор ёмкостей для продуктов BERKRAFT FUSION' },
        { id: 9, name: 'Банка для сыпучих продуктов FORMA CUBBO' },
        { id: 10, name: 'Лампа светодиодная gauss Elementary 23217А, белый матовый (7W, E27, груша, тёплый)' },
        { id: 11, name: 'Контейнер для лимона phibo 12887 4312887, жёлтый/белый' },
        { id: 12, name: 'Лампа светодиодная gauss Basic 10502362 (5,5W, E27, шар, тёплый)' },
        { id: 13, name: 'Салфетки двухслойные Art Bouquet С новым годом 57838, 20х20 см, 30 шт.' },
        { id: 14, name: 'Ёмкость для сыпучих продуктов IDEA М 1221, 1,5 л, фисташковый' },
      ],
      versions: [
        { id: 1, name: 'ID000001' },
        { id: 2, name: 'ID000002' },
        { id: 3, name: 'ID000003' },
        { id: 4, name: 'ID000004' },
        { id: 5, name: 'ID000005' },
        { id: 6, name: 'ID000006' },
      ],
      selectedChart: 'Alert Review',
      tableColumns: [
        { title: '№', width: '33' },
        { title: 'Дата расчёта', width: '76' },
        { title: 'ID расчёта', width: '76' },
        { title: 'Код узла', width: '49' },
        { title: 'Название узла', width: '87' },
        { title: 'Код товара', width: '67' },
        { title: 'Наименование товара', width: '127' },
        { title: 'Расчет нед.', width: '68' },
        { title: 'Факт нед.', width: '53' },
        { title: 'Потенциал нед.', width: '96' },
        { title: 'Корректировка нед.', width: '130' },
        { title: 'Расчёт мес.', width: '68' },
        { title: 'Факт мес.', width: '53' },
        { title: 'Потенциал мес.', width: '96' },
        { title: 'Корректировка мес.', width: '130' },
        { title: 'Расчет кв.', width: '68' },
        { title: 'Факт кв.', width: '53' },
        { title: 'Потенциал кв.', width: '96' },
        { title: 'Корректировка кв.', width: '130' },
      ],
      table3Columns: ['', '2023-08-02', '2023-08-03', '2023-08-04', '2023-08-05', '2023-08-06', '2023-08-07', '2023-08-08', '2023-08-09', '2023-08-10', '2023-08-11', '2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18', '2023-08-19', '2023-08-20', '2023-08-21', '2023-08-22', '2023-08-23', '2023-08-24', '2023-08-25', '2023-08-26', '2023-08-27', '2023-08-28', '2023-08-29', '2023-08-30', '2023-08-31', '2023-09-01', '2023-09-02', '2023-09-03', '2023-09-04', '2023-09-05', '2023-09-06', '2023-09-07', '2023-09-08', '2023-09-09', '2023-09-10', '2023-09-11', '2023-09-12', '2023-09-13', '2023-09-14', '2023-09-15', '2023-09-16', '2023-09-17', '2023-09-18', '2023-09-19', '2023-09-20', '2023-09-21', '2023-09-22', '2023-09-23', '2023-09-24', '2023-09-25', '2023-09-26', '2023-09-27', '2023-09-28', '2023-09-29', '2023-09-30', '2023-10-01', '2023-10-02', '2023-10-03'],
      rows: [
        [
          1,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80400059,
          'Салфетки однослойные HOFF, 24х24 см, 100 шт., белый',
          94,
          104,
          122,
          1.1,
          424,
          466,
          551,
          0,
          1271,
          1398,
          1652,
          0,
        ],
        [
          2,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80400060,
          'Салфетки двухслойные HOFF, 24х24 см, 100 шт., белый',
          50,
          55,
          57,
          1.1,
          224,
          247,
          258,
          0,
          673,
          741,
          774,
          0,
        ],
        [
          3,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80533088,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1113/Hoff, 6 шт., в асс.',
          22,
          23,
          29,
          0,
          101,
          103,
          131,
          0,
          302,
          308,
          393,
          0,
        ],
        [
          4,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80261768,
          'Лампа галогенная ЭРА G4-JC-20W-12V (20W, G4, капсула, тёплый)',
          18,
          22,
          21,
          1.2,
          81,
          97,
          93,
          0,
          242,
          291,
          279,
          0,
        ],
        [
          5,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80348905,
          'Ёмкость для сыпучих продуктов IDEA М 1221, 1,5 л, фисташковый',
          10,
          10,
          13,
          9,
          46,
          47,
          60,
          0,
          138,
          141,
          180,
          0,
        ],
        [
          6,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80530230,
          'Контейнер для лимона HOFF Lemon 12887 4342731, 8,7х9,6х8,7 см, жёлтый',
          9,
          9,
          10,
          0,
          41,
          41,
          47,
          0,
          122,
          124,
          140,
          0,
        ],
        [
          7,
          '10.09.2023',
          'ID000001',
          509,
          'ТЦ "Колумбус"',
          80533087,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1108/Hoff, 630 мл, 3 шт., в асс.',
          9,
          9,
          12,
          0,
          40,
          41,
          52,
          0,
          120,
          123,
          156,
          0,
        ],
        [
          8,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80533087,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1108/Hoff, 630 мл, 3 шт., в асс.',
          11,
          12,
          13,
          1.1,
          50,
          55,
          58,
          0,
          150,
          165,
          173,
          0,
        ],
        [
          9,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80322397,
          'Банка для сыпучих продуктов FORMA CUBBO 26602, 600 мл, в асс.',
          10,
          10,
          12,
          0,
          43,
          44,
          56,
          0,
          129,
          132,
          168,
          0,
        ],
        [
          10,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80293544,
          'Лампа светодиодная gauss Elementary 23217А, белый матовый (7W, E27, груша, тёплый)',
          9,
          9,
          10,
          0,
          40,
          41,
          46,
          0,
          121,
          123,
          139,
          0,
        ],
        [
          11,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80317108,
          'Контейнер для лимона phibo 12887 4312887, жёлтый/белый',
          9,
          8,
          12,
          0.9,
          40,
          36,
          52,
          0,
          120,
          108,
          157,
          0,
        ],
        [
          12,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80512738,
          'Лампа светодиодная gauss Basic 10502362 (5,5W, E27, шар, тёплый)',
          9,
          10,
          10,
          0,
          40,
          44,
          46,
          0,
          120,
          132,
          138,
          0,
        ],
        [
          13,
          '10.09.2023',
          'ID000002',
          711,
          'Новая Рига',
          80588884,
          'Салфетки двухслойные Art Bouquet С новым годом 57838, 20х20 см, 30 шт.',
          9,
          9,
          11,
          0,
          39,
          42,
          50,
          0,
          116,
          127,
          150,
          0,
        ],
        [
          14,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80348905,
          'Ёмкость для сыпучих продуктов IDEA М 1221, 1,5 л, фисташковый',
          15,
          17,
          17,
          0,
          68,
          75,
          78,
          0,
          205,
          225,
          235,
          0,
        ],
        [
          15,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80530230,
          'Контейнер для лимона HOFF Lemon 12887 4342731, 8,7х9,6х8,7 см, жёлтый',
          15,
          16,
          19,
          0,
          67,
          74,
          88,
          0,
          202,
          222,
          263,
          0,
        ],
        [
          16,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80533087,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1108/Hoff, 630 мл, 3 шт., в асс.',
          15,
          16,
          17,
          1.1,
          66,
          73,
          76,
          0,
          199,
          219,
          229,
          0,
        ],
        [
          17,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80303362,
          'Лампа светодиодная gauss Elementary 53228, белый матовый (8W, E27, шар, естественный)',
          12,
          13,
          16,
          0,
          55,
          56,
          72,
          0,
          166,
          169,
          216,
          0,
        ],
        [
          18,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80293559,
          'Лампа светодиодная gauss Elementary 53226, белый матовый (6W, E27, шар, естественный)',
          11,
          11,
          12,
          0,
          48,
          49,
          56,
          0,
          145,
          148,
          167,
          0,
        ],
        [
          19,
          '10.09.2023',
          'ID000003',
          732,
          'Белая Дача',
          80531428,
          'Набор контейнеров BERKRAFT Optimum 67027, 17 шт., малиновый',
          10,
          10,
          13,
          0,
          46,
          47,
          60,
          0,
          139,
          142,
          181,
          0,
        ],
        [
          20,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80327289,
          'Диван-кровать Парма 001.1, тёмно-серый (Модерно Домино 02)',
          63,
          64,
          72,
          0,
          282,
          287,
          324,
          0,
          845,
          862,
          972,
          0,
        ],
        [
          21,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80354526,
          'Зеркало напольное в раме M0021-MW-87/M0049, 33х153 см, светлый дуб',
          51,
          52,
          66,
          0,
          227,
          232,
          295,
          0,
          682,
          695,
          886,
          0,
        ],
        [
          22,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80290195,
          'Стул с подлокотниками TIFFANY 2176, коричневый (ZS1811/WT2185)',
          42,
          42,
          48,
          0,
          187,
          191,
          215,
          0,
          561,
          572,
          645,
          0,
        ],
        [
          23,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80309495,
          'Стол Уно МСТ-СДУ-01-БТ-16, белый',
          36,
          43,
          47,
          1.2,
          163,
          195,
          212,
          0,
          489,
          586,
          635,
          0,
        ],
        [
          24,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80323924,
          'Одеяло 1,5 сп. MITTE 212-864, 140х205 см',
          25,
          28,
          29,
          0,
          114,
          125,
          131,
          0,
          342,
          376,
          393,
          0,
        ],
        [
          25,
          '10.09.2023',
          'ID000004',
          799,
          'ИМ Москва',
          80340458,
          'Письменный стол SCANDICA Хортен, дуб натуральный светлый/бланж',
          25,
          28,
          33,
          0,
          113,
          124,
          147,
          0,
          339,
          373,
          441,
          0,
        ],
        [
          26,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80533087,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1108/Hoff, 630 мл, 3 шт., в асс.',
          10,
          8,
          11,
          0.9,
          44,
          35,
          50,
          0,
          131,
          105,
          150,
          0,
        ],
        [
          27,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80293543,
          'Лампа светодиодная gauss Elementary 23220, белый матовый (10W, E27, груша, естественный)',
          9,
          10,
          12,
          0,
          41,
          45,
          53,
          0,
          122,
          134,
          158,
          0,
        ],
        [
          28,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80317108,
          'Контейнер для лимона phibo 12887 4312887, жёлтый/белый',
          8,
          8,
          9,
          1.1,
          34,
          38,
          39,
          0,
          103,
          113,
          118,
          0,
        ],
        [
          29,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80412819,
          'Контейнер BERKRAFT Easy S 66802, 750 мл, бирюзовый',
          7,
          8,
          10,
          0,
          33,
          37,
          43,
          0,
          100,
          110,
          130,
          0,
        ],
        [
          30,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80261768,
          'Лампа галогенная ЭРА G4-JC-20W-12V (20W, G4, капсула, тёплый)',
          7,
          7,
          8,
          0,
          30,
          33,
          34,
          0,
          89,
          98,
          102,
          0,
        ],
        [
          31,
          '10.09.2023',
          'ID000005',
          825,
          'Новосибирск ТЦ Красный Мамонт',
          80523786,
          'Салфетки двухслойные Art Bouguet Дед Мороз 57564, 20х20 см, 30 шт.',
          7,
          7,
          8,
          0,
          29,
          32,
          38,
          0,
          88,
          97,
          114,
          0,
        ],
        [
          32,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80533088,
          'Набор ёмкостей для продуктов BERKRAFT FUSION FC1113/Hoff, 6 шт., в асс.',
          9,
          10,
          10,
          0,
          41,
          45,
          47,
          0,
          123,
          135,
          142,
          0,
        ],
        [
          33,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80530230,
          'Контейнер для лимона HOFF Lemon 12887 4342731, 8,7х9,6х8,7 см, жёлтый',
          9,
          9,
          11,
          0,
          39,
          42,
          50,
          0,
          116,
          127,
          151,
          0,
        ],
        [
          34,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80400060,
          'Салфетки двухслойные HOFF, 24х24 см, 100 шт., белый',
          8,
          9,
          9,
          1.2,
          35,
          42,
          40,
          0,
          105,
          126,
          120,
          0,
        ],
        [
          35,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80530236,
          'Контейнер для лука HOFF ONION 12886 4342733, 9,9х7х9,9 см, бежевый/красный',
          5,
          5,
          6,
          0,
          22,
          24,
          28,
          0,
          65,
          72,
          85,
          0,
        ],
        [
          36,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80400059,
          'Салфетки однослойные HOFF, 24х24 см, 100 шт., белый',
          4,
          5,
          5,
          0,
          19,
          21,
          22,
          0,
          58,
          64,
          67,
          0,
        ],
        [
          37,
          '10.09.2023',
          'ID000006',
          827,
          'Рязань Премьер',
          80412819,
          'Контейнер BERKRAFT Easy S 66802, 750 мл, бирюзовый',
          4,
          5,
          6,
          0,
          19,
          21,
          25,
          0,
          58,
          64,
          75,
          0,
        ],
      ],
      rows3: [
        ['Продажи', 8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Базовое значение', ...new Array(63).fill(4.03)],
        ['Фактор недельной динамики', 1, 1.09, 0.81, 1, 1.09, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94],
        ['Фактор месячной динамики', 1, 1, 1, 1, 1, 1, 1, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 1.29, 1.29, 1.29, 1.29, 1.29, 1.29, 1.29, 1.08, 1.08, 1.08, 1.08, 1.08, 1.08, 1.08, 1.39, 1.39, 1.39, 1.39, 1.39, 1.39, 1.39, 1.33, 1.33, 1.33, 1.33, 1.33, 1.33, 1.33, 1.45, 1.45, 1.45, 1.45, 1.45, 1.45, 1.45, 1.48, 1.48, 1.48, 1.48, 1.48, 1.48, 1.48, 1.56, 1.56, 1.56, 1.56, 1.56, 1.56, 1.56],
        ['Фактор эластичности', 0.82, 0.85, 0.81, 0.82, 0.82, 0.84, 0.89, 0.87, 0.85, 0.82, 0.82, 0.82, 0.8, 0.8, 0.81, 0.82, 0.8, 0.8, 0.81, 0.8, 0.81, 0.8, 0.82, 0.85, 0.82, 0.8, 0.82, 0.83, 0.31, 0.31, 0.32, 0.32, 0.32, 0.32, 0.2, 0.1, 0.12, 0.31, 0.7, 0.98, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Фактор обнуления и виртуального сток', 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, ...new Array(23).fill(0)],
        ['Очищенные продажи', 7.71, 0.88, 5.93, 5.77, 2.66, 5.9, 3.11, 2.06, 4.71, 11.34, 1.01, 1.88, 0, 0, 10.37, 3.4, 3.64, 5.15, 1.36, 3.21, 7.06, 0.88, 3.26, 0, 3.55, 3.26, 2.32, 1.89, 4.94, 1.69, 11.43, 0.61, 6.25, 3.23, 6.38, 0, 0, 3.99, 1.4, 2.74, 7.71, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Прогноз продаж', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.72, 5.09, 5.5, 4.76, 5.08, 6.11, 6.55, 5.07, 5.88, 6.42, 4.77, 5.9, 6.39, 6.75, 5.54, 6, 6.55, 4.87, 6.02, 6.53, 6.89, 5.66, 6.31, 6.89, 5.12, 6.34, 6.87, 7.25, 5.95],
      ],
      chart1Data: {
        labels: ['2023-08-02', '2023-08-03', '2023-08-04', '2023-08-05', '2023-08-06', '2023-08-07', '2023-08-08', '2023-08-09', '2023-08-10', '2023-08-11', '2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18', '2023-08-19', '2023-08-20', '2023-08-21', '2023-08-22', '2023-08-23', '2023-08-24', '2023-08-25', '2023-08-26', '2023-08-27', '2023-08-28', '2023-08-29', '2023-08-30', '2023-08-31', '2023-09-01', '2023-09-02', '2023-09-03', '2023-09-04', '2023-09-05', '2023-09-06', '2023-09-07', '2023-09-08', '2023-09-09', '2023-09-10', '2023-09-11', '2023-09-12', '2023-09-13', '2023-09-14', '2023-09-15', '2023-09-16', '2023-09-17', '2023-09-18', '2023-09-19', '2023-09-20', '2023-09-21', '2023-09-22', '2023-09-23', '2023-09-24', '2023-09-25', '2023-09-26', '2023-09-27', '2023-09-28', '2023-09-29', '2023-09-30', '2023-10-01', '2023-10-02', '2023-10-03'],
        datasets: [
          // {
          //   label: 'Сумма по полю ClearedResult',
          //   data: [7.715555667877197, 0.8895764350891113, 5.93445348739624, 5.772295951843262, 2.6645357608795166, 5.900936603546143, 3.1120235919952393, 2.069221019744873, 4.7188897132873535, 11.340137481689453, 1.0195958614349365, 1.8805489540100098, 0, 0, 10.375873565673828, 3.4036381244659424, 3.6408348083496094, 5.157071113586426, 1.3621817827224731, 3.21516489982605, 7.065043926239014, 0.8871782422065735, 3.2665393352508545, 0, 3.5527145862579346, 3.263806104660034, 2.3243935108184814, 1.8941291570663452, 4.942502021789551, 1.6981765031814575, 11.436501502990723, 0.6162541508674622, 6.25546932220459, 3.2328133583068848, 6.382678508758545, 0, 0, 3.9910643100738525, 1.406543254852295, 2.7485275268554688, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          //   type: 'line',
          //   borderColor: 'rgba(255,255,0,0)',
          //   backgroundColor: 'yellow',
          //   order: 0,
          //   pointRadius: 0,
          // },
          {
            label: 'Продажи',
            data: [8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: 'green',
            borderColor: 'green',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 1,
          },
          // {
          //   label: 'Сумма по полю fr1',
          //   data: [19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766],
          //   type: 'line',
          //   fill: false,
          //   borderColor: 'gray',
          //   backgroundColor: 'gray',
          //   borderDash: [5, 5],
          //   pointRadius: 2,
          //   order: 2,
          // },
          // {
          //   label: 'Сумма по полю fr2',
          //   data: [15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047],
          //   type: 'line',
          //   fill: false,
          //   borderColor: 'lightgreen',
          //   backgroundColor: 'lightgreen',
          //   borderDash: [5, 5],
          //   pointRadius: 2,
          //   order: 3,
          // },
          // {
          //   label: 'Сумма по полю fr3',
          //   data: [23.878564834594727, 24.81728172302246, 23.679990768432617, 24.062955856323242, 24.104442596435547, 24.42902374267578, 25.856300354003906, 25.515031814575195, 24.89271354675293, 23.8445987701416, 23.990802764892578, 23.878564834594727, 23.394540786743164, 23.233198165893555, 23.71722412109375, 24.062955856323242, 23.233198165893555, 23.41470718383789, 23.6480770111084, 23.233198165893555, 23.53106117248535, 23.41470718383789, 24.062955856323242, 24.685274124145508, 24.104442596435547, 23.456594467163086, 23.8445987701416, 24.322254180908203, 9.293279647827148, 9.293279647827148, 9.465376853942871, 9.453508377075195, 9.434086799621582, 9.50448989868164, 6.0406317710876465, 3.0228140354156494, 3.7173118591308594, 9.293279647827148, 20.57797622680664, 28.59470558166504],
          //   type: 'line',
          //   fill: false,
          //   borderColor: 'blue',
          //   backgroundColor: 'blue',
          //   borderDash: [5, 5],
          //   pointRadius: 2,
          //   order: 4,
          // },
          // {
          //   label: 'Сумма по полю fr4',
          //   data: new Array(41).fill(20),
          //   type: 'line',
          //   fill: false,
          //   borderColor: 'black',
          //   backgroundColor: 'black',
          //   borderDash: [5, 5],
          //   pointRadius: 2,
          //   order: 5,
          // },
          // {
          //   label: 'Среднее по полю BaseVall',
          //   data: new Array(63).fill(4.03),
          //   type: 'line',
          //   fill: false,
          //   borderColor: 'orange',
          //   backgroundColor: 'orange',
          //   pointRadius: 2,
          //   order: 6,
          // },
          {
            label: 'Прогноз продаж',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.7246649265289307, 5.094363689422607, 5.501387596130371, 4.76611852645874, 5.081233501434326, 6.118488311767578, 6.551771640777588, 5.070675849914551, 5.8828935623168945, 6.42075777053833, 4.773547649383545, 5.905304908752441, 6.398346900939941, 6.756922721862793, 5.546727657318115, 6.005294322967529, 6.554349899291992, 4.872867584228516, 6.028172016143799, 6.531472682952881, 6.897509574890137, 5.662134647369385, 6.318946838378906, 6.896678447723389, 5.127373695373535, 6.343019008636475, 6.872607231140137, 7.257761478424072, 5.957863807678223],
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 7,
          },
        ],
      },
      chart2Data: {
        labels: ['2023-08-02', '2023-08-03', '2023-08-04', '2023-08-05', '2023-08-06', '2023-08-07', '2023-08-08', '2023-08-09', '2023-08-10', '2023-08-11', '2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18', '2023-08-19', '2023-08-20', '2023-08-21', '2023-08-22', '2023-08-23', '2023-08-24', '2023-08-25', '2023-08-26', '2023-08-27', '2023-08-28', '2023-08-29', '2023-08-30', '2023-08-31', '2023-09-01', '2023-09-02', '2023-09-03', '2023-09-04', '2023-09-05', '2023-09-06', '2023-09-07', '2023-09-08', '2023-09-09', '2023-09-10', '2023-09-11'],
        datasets: [
          {
            label: 'Очищенные продажи',
            data: [7.715555667877197, 0.8895764350891113, 5.93445348739624, 5.772295951843262, 2.6645357608795166, 5.900936603546143, 3.1120235919952393, 2.069221019744873, 4.7188897132873535, 11.340137481689453, 1.0195958614349365, 1.8805489540100098, 0, 0, 10.375873565673828, 3.4036381244659424, 3.6408348083496094, 5.157071113586426, 1.3621817827224731, 3.21516489982605, 7.065043926239014, 0.8871782422065735, 3.2665393352508545, 0, 3.5527145862579346, 3.263806104660034, 2.3243935108184814, 1.8941291570663452, 4.942502021789551, 1.6981765031814575, 11.436501502990723, 0.6162541508674622, 6.25546932220459, 3.2328133583068848, 6.382678508758545, 0, 0, 3.9910643100738525, 1.406543254852295, 2.7485275268554688, 0],
            type: 'line',
            borderColor: 'rgba(255,255,0,0)',
            backgroundColor: 'yellow',
            order: 0,
            pointRadius: 0,
          },
          {
            label: 'Продажи',
            data: [8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4],
            backgroundColor: 'green',
            borderColor: 'green',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Фактор недельной динамики',
            data: [19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465],
            type: 'line',
            fill: false,
            borderColor: 'gray',
            backgroundColor: 'gray',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 2,
          },
          {
            label: 'Фактор месячной динамики',
            data: [15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125],
            type: 'line',
            fill: false,
            borderColor: 'lightgreen',
            backgroundColor: 'lightgreen',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 3,
          },
          {
            label: 'Фактор эластичности',
            data: [23.878564834594727, 24.81728172302246, 23.679990768432617, 24.062955856323242, 24.104442596435547, 24.42902374267578, 25.856300354003906, 25.515031814575195, 24.89271354675293, 23.8445987701416, 23.990802764892578, 23.878564834594727, 23.394540786743164, 23.233198165893555, 23.71722412109375, 24.062955856323242, 23.233198165893555, 23.41470718383789, 23.6480770111084, 23.233198165893555, 23.53106117248535, 23.41470718383789, 24.062955856323242, 24.685274124145508, 24.104442596435547, 23.456594467163086, 23.8445987701416, 24.322254180908203, 9.293279647827148, 9.293279647827148, 9.465376853942871, 9.453508377075195, 9.434086799621582, 9.50448989868164, 6.0406317710876465, 3.0228140354156494, 3.7173118591308594, 9.293279647827148, 20.57797622680664, 28.59470558166504, 0],
            type: 'line',
            fill: false,
            borderColor: 'blue',
            backgroundColor: 'blue',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 4,
          },
          {
            label: 'Фактор обнуления и виртуального сток',
            data: [...new Array(12).fill(20), NaN, NaN, ...new Array(21).fill(20), NaN, NaN, 20, 20, 20],
            type: 'line',
            fill: false,
            borderColor: 'black',
            backgroundColor: 'black',
            pointRadius: 2,
            order: 5,
          },
          {
            label: 'Базовое значение',
            data: new Array(41).fill(4.03),
            type: 'line',
            fill: false,
            borderColor: 'orange',
            backgroundColor: 'orange',
            pointRadius: 2,
            order: 6,
          },
        ],
      },
      chart3Data: {
        labels: ['2023-08-02', '2023-08-03', '2023-08-04', '2023-08-05', '2023-08-06', '2023-08-07', '2023-08-08', '2023-08-09', '2023-08-10', '2023-08-11', '2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18', '2023-08-19', '2023-08-20', '2023-08-21', '2023-08-22', '2023-08-23', '2023-08-24', '2023-08-25', '2023-08-26', '2023-08-27', '2023-08-28', '2023-08-29', '2023-08-30', '2023-08-31', '2023-09-01', '2023-09-02', '2023-09-03', '2023-09-04', '2023-09-05', '2023-09-06', '2023-09-07', '2023-09-08', '2023-09-09', '2023-09-10', '2023-09-11', '2023-09-12', '2023-09-13', '2023-09-14', '2023-09-15', '2023-09-16', '2023-09-17', '2023-09-18', '2023-09-19', '2023-09-20', '2023-09-21', '2023-09-22', '2023-09-23', '2023-09-24', '2023-09-25', '2023-09-26', '2023-09-27', '2023-09-28', '2023-09-29', '2023-09-30', '2023-10-01', '2023-10-02', '2023-10-03'],
        datasets: [
          {
            label: 'Очищенные продажи',
            data: [7.715555667877197, 0.8895764350891113, 5.93445348739624, 5.772295951843262, 2.6645357608795166, 5.900936603546143, 3.1120235919952393, 2.069221019744873, 4.7188897132873535, 11.340137481689453, 1.0195958614349365, 1.8805489540100098, 0, 0, 10.375873565673828, 3.4036381244659424, 3.6408348083496094, 5.157071113586426, 1.3621817827224731, 3.21516489982605, 7.065043926239014, 0.8871782422065735, 3.2665393352508545, 0, 3.5527145862579346, 3.263806104660034, 2.3243935108184814, 1.8941291570663452, 4.942502021789551, 1.6981765031814575, 11.436501502990723, 0.6162541508674622, 6.25546932220459, 3.2328133583068848, 6.382678508758545, 0, 0, 3.9910643100738525, 1.406543254852295, 2.7485275268554688, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: 'line',
            borderColor: 'rgba(255,255,0,0)',
            backgroundColor: 'yellow',
            order: 0,
            pointRadius: 0,
          },
          {
            label: 'Продажи',
            data: [8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: 'green',
            borderColor: 'green',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Фактор недельной динамики',
            data: [19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766],
            type: 'line',
            fill: false,
            borderColor: 'gray',
            backgroundColor: 'gray',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 2,
          },
          {
            label: 'Фактор месячной динамики',
            data: [15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047],
            type: 'line',
            fill: false,
            borderColor: 'lightgreen',
            backgroundColor: 'lightgreen',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 3,
          },
          {
            label: 'Фактор эластичности',
            data: [23.878564834594727, 24.81728172302246, 23.679990768432617, 24.062955856323242, 24.104442596435547, 24.42902374267578, 25.856300354003906, 25.515031814575195, 24.89271354675293, 23.8445987701416, 23.990802764892578, 23.878564834594727, 23.394540786743164, 23.233198165893555, 23.71722412109375, 24.062955856323242, 23.233198165893555, 23.41470718383789, 23.6480770111084, 23.233198165893555, 23.53106117248535, 23.41470718383789, 24.062955856323242, 24.685274124145508, 24.104442596435547, 23.456594467163086, 23.8445987701416, 24.322254180908203, 9.293279647827148, 9.293279647827148, 9.465376853942871, 9.453508377075195, 9.434086799621582, 9.50448989868164, 6.0406317710876465, 3.0228140354156494, 3.7173118591308594, 9.293279647827148, 20.57797622680664, 28.59470558166504],
            type: 'line',
            fill: false,
            borderColor: 'blue',
            backgroundColor: 'blue',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 4,
          },
          {
            label: 'Фактор обнуления и виртуального сток',
            data: [...new Array(12).fill(20), NaN, NaN, ...new Array(21).fill(20), NaN, NaN, 20, 20, 20],
            type: 'line',
            fill: false,
            borderColor: 'black',
            backgroundColor: 'black',
            pointRadius: 2,
            order: 5,
          },
          {
            label: 'Базовое значение',
            data: new Array(63).fill(4.03),
            type: 'line',
            fill: false,
            borderColor: 'orange',
            backgroundColor: 'orange',
            pointRadius: 2,
            order: 6,
          },
          {
            label: 'Прогноз продаж',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.7246649265289307, 5.094363689422607, 5.501387596130371, 4.76611852645874, 5.081233501434326, 6.118488311767578, 6.551771640777588, 5.070675849914551, 5.8828935623168945, 6.42075777053833, 4.773547649383545, 5.905304908752441, 6.398346900939941, 6.756922721862793, 5.546727657318115, 6.005294322967529, 6.554349899291992, 4.872867584228516, 6.028172016143799, 6.531472682952881, 6.897509574890137, 5.662134647369385, 6.318946838378906, 6.896678447723389, 5.127373695373535, 6.343019008636475, 6.872607231140137, 7.257761478424072, 5.957863807678223],
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 7,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: 30,
            },
          }],
        },
        tooltips: {
          mode: 'x',
          position: 'nearest',
        },
      },
      forecastRows: [
        {
          id: 1,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Product/Location Clonning Administration',
        },
        {
          id: 2,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Administration',
        },
        {
          id: 3,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Approval',
        },
        {
          id: 4,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Forecast Management',
        },
        {
          id: 5,
          level: 0,
          nodeType: 'leaf',
          value: 'Alert Review',
        },
        {
          id: 6,
          level: 0,
          nodeType: 'leaf',
          value: 'History Review',
        },
        {
          id: 666,
          level: 0,
          nodeType: 'leaf',
          value: 'Event Simulation',
        },
        {
          id: 7,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Delete',
        },
        {
          id: 8,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Storecard',
        },
        {
          id: 9,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Interactive Forecasting',
        },
        {
          id: 10,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Like-Item, Sister-Store',
        },
        {
          id: 11,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Forecast Maintenance',
        },
        {
          id: 12,
          level: 0,
          nodeType: 'node',
          isOpen: false,
          value: 'Run Batch Forecast',
        },
      ],
    };
  },

  methods: {
    changeTemplate(value) {
      if (value === 'Alert Review') {
        this.selectedChart = 'Alert Review';
      } else if (value === 'History Review') {
        this.selectedChart = 'History Review';
      } else if (value === 'Event Simulation') {
        this.selectedChart = 'Event Simulation';
      }
    },
  },
};
</script>

<style scoped>
.forecast-management-container {
    width: 100%;
    display: flex;
    gap: 1%;
    padding: 15px 0;
}

.ao-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
}

.left-side {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  padding: 10px;
}

.node-button {
  width: 25px;
}

.row-selected {
  background-color: #CCE5FF;
}

.right-side {
    width: 79%;
    height: 100%;
}

.right-side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.right-side-container .ao-table {
  height: 35%;
  table-layout: fixed;
  overflow: overlay;
}

.ao-table__row.bold,
.ao-table__cell.bold {
  font-weight: bold;
  font-size: 16px;
}

.right-side-container .ao-table .ao-table__row .ao-table__cell:not(:last-of-type) {
  border-right: 1px solid #E9E9E9;
}

.right-side-container .ao-table tbody {
  overflow: auto;
}

.right-side-container .ao-table tbody tr {
  border: 1px solid #E9E9E9;
  border-bottom: none;
}

.right-side-container .ao-table tbody tr:last-of-type {
  border-bottom: 1px solid #E9E9E9;
}

.right-side-container .ao-table__cell {
  padding: 8px;
}
.ao-table__cell-text {
  width: 100%;
  max-width: 300px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
  font-size: 10.5px;
}
.right-side-container .ao-table__cell-text {
  display: block;
  width: 100%;
  max-width: 300px;
  text-overflow: ellipsis;
  text-wrap: balance;
  overflow-x: hidden;
  font-size: 10.5px;
}
#chart-container {
  height: 59% !important;
  padding-bottom: 10px;
}
.left-side #tr-without-borders:nth-of-type(5),
.left-side #tr-without-borders:nth-of-type(6),
.left-side #tr-without-borders:nth-of-type(7) {
  cursor: pointer;
}

.right-table {
  width: 40%;
}
.filters {
  display: flex;
  height: 5%;
  gap: 10px;
  align-items: baseline;
  margin-bottom: 10px;
}
.filters svg {
  cursor: pointer;
}
.ml-auto {
  margin-left: auto;
}
.input[type='date'],
.input[type='date']:hover,
.input[type='date']:active,
.input[type='date']:focus {
  height: 30px;
  border: none;
  outline: none;
}
.date-row {
  width: 250px;
  display: flex;
  justify-content: space-between;
}
.dates {
  margin-top: auto;
}
.exclude-title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
}
.input-group-addon {
  margin-left: -40px;
  text-align: center;
}
.wihout-calendar input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.ao-table__cell.width-limited .ao-table__cell-text {
   display: block;
   width: 70px;
}
.event-simulation-container {
  height: 100%;
}
.event-simulation-table {
  height: 30%;
  overflow: overlay;
}
.event-simulation-table .ao-table__cell {
  padding: 0 5px;
  border: 1px solid #E9E9E9;
}
.event-simulation-table .ao-table__cell.without-border {
  border: none;
}
#chart_number3 {
  padding-bottom: 20px;
}
.feather-plus-circle {
  cursor: pointer;
}
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: #c1d6ec;
}
</style>
