// save/get dynamic tab ids
const saveToStorageDynamicTabs = ({ key, tabs }) => {
  sessionStorage.setItem(key, JSON.stringify(tabs));
};

const getFromStorageDynamicTabs = (key) => JSON.parse(sessionStorage.getItem(key)) || [];

// save/get/clear dynamic cards states
const getEntityCardCached = (entity) => JSON.parse(sessionStorage.getItem(entity)) || {};

const saveCardStateToCache = ({ entity, cardId, cardState }) => {
  const entityCache = getEntityCardCached(entity);
  entityCache[cardId] = cardState;

  sessionStorage.setItem(entity, JSON.stringify(entityCache));
};

const getCardStateFromCache = ({ entity, cardId }) => {
  const entityCache = getEntityCardCached(entity);
  return entityCache[cardId] || null;
};

const clearCardStatesCache = ({ entity, tabIds }) => {
  const oldEntityCache = getEntityCardCached(entity);
  const cleanedCache = {};

  tabIds.forEach((key) => {
    if (oldEntityCache[key]) {
      cleanedCache[key] = oldEntityCache[key];
    }
  });

  sessionStorage.setItem(entity, JSON.stringify(cleanedCache));
};

// table columns sizes settings
const getColumnsSizesFromStorage = (key) => JSON.parse(localStorage.getItem(key)) || {};
const setColumnsSizesToStorage = (key, payload) => {
  localStorage.setItem(key, JSON.stringify(payload));
};

module.exports = {
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
  saveCardStateToCache,
  getCardStateFromCache,
  clearCardStatesCache,
  getColumnsSizesFromStorage,
  setColumnsSizesToStorage,
};
