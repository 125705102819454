var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-filter" },
    [
      _c("feather-icon", { attrs: { name: "filter" } }),
      _vm._v(" "),
      _vm._l(_vm.filter, function(conditions, i) {
        return _c(
          "span",
          { staticClass: "gp-filter-conditions" },
          [
            _vm._l(conditions, function(values, calc) {
              return [
                _vm.activeControl == i + calc
                  ? [
                      _c(
                        "my-popup",
                        {
                          key: _vm.activeControl,
                          attrs: {
                            placement: "bottom-start",
                            portal: _vm.popupPortal
                          },
                          on: {
                            clickoutside: function($event) {
                              _vm.cleanupConditions()
                              _vm.activeControl = null
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "anchor",
                                fn: function() {
                                  return [
                                    _c("span", {
                                      staticClass: "ui-front",
                                      staticStyle: { display: "inline-block" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gp-filter-popup" },
                            [
                              _c("gp-select", {
                                ref: "attribute",
                                refInFor: true,
                                attrs: {
                                  options: _vm.sortedAttributes,
                                  autofocus: !_vm.attribute,
                                  placeholder: _vm.l10n("Attribute"),
                                  recentOptionsKey: "recentAttributes"
                                },
                                model: {
                                  value: _vm.attribute,
                                  callback: function($$v) {
                                    _vm.attribute = $$v
                                  },
                                  expression: "attribute"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "gp-filter-values" },
                                [
                                  _vm.loading
                                    ? _c("feather-icon", {
                                        attrs: { name: "clock" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "textarea",
                                    {
                                      ref: "values",
                                      refInFor: true,
                                      staticClass:
                                        "form-control form-control-sm",
                                      on: {
                                        focus: function($event) {
                                          return _vm.autocomplete.autocomplete(
                                            "search"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(values.join("\n")))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                ref: "autocomplete",
                                refInFor: true
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "gp-filter-popup-notes" },
                                [
                                  _c("l10n", {
                                    attrs: {
                                      value:
                                        "Use multiple lines to filter by multiple values"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "gp-filter-popup-actions" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-xs",
                                      attrs: {
                                        disabled: _vm.attribute == null
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.changeCondition(i, calc)
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [_c("l10n", { attrs: { value: "OK" } })],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary btn-xs",
                                      on: {
                                        click: function($event) {
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "Cancel" } })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger btn-xs",
                                      on: {
                                        click: function($event) {
                                          _vm.deleteCondition(i, calc)
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "Delete" } })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.attribute = _vm.getAttributeByCalc(calc)
                        _vm.activeControl = i + calc
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.getAttributeByCalc(calc).name) +
                        ":\n        " +
                        _vm._s(
                          values.length < 10
                            ? values.join(", ")
                            : values.slice(0, 8).join(", ") +
                                " " +
                                _vm
                                  .l10n("and {more} more...")
                                  .replace(
                                    "{more}",
                                    new Number(
                                      values.length - 8
                                    ).toLocaleString()
                                  )
                        )
                    )
                  ]
                ),
                _vm._v(" "),
                _c("l10n", {
                  staticClass: "operator",
                  attrs: { value: "AND" }
                }),
                _vm._v(" "),
                _c("span")
              ]
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "new",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.$set(conditions, "", [])
                    _vm.attribute = null
                    _vm.activeControl = i
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "plus" } })],
              1
            ),
            _vm._v(" "),
            _vm.filter.length > 0
              ? _c("l10n", { staticClass: "operator", attrs: { value: "OR" } })
              : _vm._e(),
            _vm._v(" "),
            _c("span")
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("span", { staticClass: "gp-filter-conditions" }, [
        _c(
          "a",
          {
            staticClass: "new",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.activeControl = _vm.filter.length
                _vm.filter.push({ "": [] })
              }
            }
          },
          [_c("feather-icon", { attrs: { name: "plus" } })],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }