var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dataset-settings" },
    [
      _c(
        "div",
        { staticClass: "dataset-settings__radiobuttons-container" },
        _vm._l(_vm.chartTypes, function(chartType) {
          return _c("div", { key: chartType.value }, [
            _c(
              "label",
              {
                staticClass: "dataset-settings__radiobutton",
                attrs: { for: chartType.value + "-" + _vm.currentDataset.label }
              },
              [
                _c("ao-radio", {
                  attrs: {
                    checked: _vm.currentDataset.type === chartType.value,
                    name: _vm.currentDataset.label,
                    id: chartType.value + "-" + _vm.currentDataset.label,
                    value: chartType.value
                  },
                  on: { onUpdate: _vm.chartTypeUpdate },
                  model: {
                    value: _vm.currentDataset.type,
                    callback: function($$v) {
                      _vm.$set(_vm.currentDataset, "type", $$v)
                    },
                    expression: "currentDataset.type"
                  }
                }),
                _vm._v("\n        " + _vm._s(chartType.title) + "\n      ")
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("my-transition-expand", [
        _vm.currentDataset.type === "line"
          ? _c("div", [
              _c("div", { staticClass: "dataset-settings__inputs-container" }, [
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "span",
                      { staticClass: "inputblock__label" },
                      [_c("l10n", { attrs: { value: "Тип точки" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("my-multiselect", {
                      attrs: {
                        selectedOption: _vm.pointStyles.find(function(style) {
                          return style.value === _vm.currentDataset.pointStyle
                        }).title,
                        options: _vm.pointStyles.map(function(item) {
                          return item.title
                        }),
                        label: "pointType",
                        placeholder: "Выберите тип точки"
                      },
                      on: { select: _vm.pointStyleSelect }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "pointRadius" }
                      },
                      [_c("l10n", { attrs: { value: "Радиус точки" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("ao-input", {
                      attrs: {
                        id: "pointRadius",
                        type: "number",
                        min: "0",
                        placeholder: "Введите радиус точки",
                        onlyInteger: "",
                        invalid: false
                      },
                      on: { onInput: _vm.pointRadiusUpdate },
                      model: {
                        value: _vm.currentDataset.pointRadius,
                        callback: function($$v) {
                          _vm.$set(_vm.currentDataset, "pointRadius", $$v)
                        },
                        expression: "currentDataset.pointRadius"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "dataset-settings__rangecontainer",
                    attrs: { for: _vm.currentDataset.label + "-lineTension" }
                  },
                  [
                    _c("l10n", {
                      attrs: { value: "Степень изгиба кривой (интерполяция)" }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "range",
                        id: _vm.currentDataset.label + "-lineTension",
                        name: _vm.currentDataset.label + "-lineTension",
                        min: "0",
                        max: "1",
                        step: "0.01"
                      },
                      domProps: { value: _vm.currentDataset.lineTension },
                      on: { input: _vm.selectLineTension }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "dataset-settings__rangecontainer",
                    attrs: { for: _vm.currentDataset.label + "-pointHitRadius" }
                  },
                  [
                    _c("l10n", {
                      attrs: { value: "Радиус захвата точки курсором" }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "range",
                        id: _vm.currentDataset.label + "-pointHitRadius",
                        name: _vm.currentDataset.label + "-pointHitRadius",
                        min: "0",
                        max: "20",
                        step: "1"
                      },
                      domProps: { value: _vm.currentDataset.pointHitRadius },
                      on: { input: _vm.selectPointHitRadius }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dataset-settings__checkboxes-container" },
                [
                  _c(
                    "div",
                    { staticClass: "dataset-settings__checkbox-wrapper" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dataset-settings__checkbox",
                          attrs: {
                            for: _vm.currentDataset.label + "-steppedLine"
                          }
                        },
                        [
                          _c("ao-checkbox", {
                            attrs: {
                              id: _vm.currentDataset.label + "-steppedLine",
                              checked: _vm.currentDataset.steppedLine
                            },
                            on: { onChange: _vm.changeSteppedLine }
                          }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Ступенчатый график" } })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dataset-settings__checkbox-wrapper" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dataset-settings__checkbox",
                          attrs: { for: _vm.currentDataset.label + "-spanGaps" }
                        },
                        [
                          _c("ao-checkbox", {
                            attrs: {
                              id: _vm.currentDataset.label + "-spanGaps",
                              checked: !_vm.currentDataset.spanGaps
                            },
                            on: { onChange: _vm.changeSpanGaps }
                          }),
                          _vm._v(" "),
                          _c("l10n", {
                            attrs: {
                              value: "Разрывы в графике с неполными данными"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "label",
                      {
                        staticClass: "dataset-settings__checkbox",
                        attrs: { for: _vm.currentDataset.label + "-showLine" }
                      },
                      [
                        _c("ao-checkbox", {
                          attrs: {
                            id: _vm.currentDataset.label + "-showLine",
                            checked: _vm.currentDataset.showLine
                          },
                          on: { onChange: _vm.changeShowLine }
                        }),
                        _vm._v(" "),
                        _c("l10n", { attrs: { value: "Показывать линию" } })
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dataset-settings__inputs-container" }, [
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "borderWidth" }
              },
              [_c("l10n", { attrs: { value: "Ширина линии" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              attrs: {
                id: "borderWidth",
                type: "number",
                min: "0",
                placeholder: "Введите ширину линии",
                onlyInteger: "",
                invalid: false
              },
              on: { onInput: _vm.borderWidthUpdate },
              model: {
                value: _vm.currentDataset.borderWidth,
                callback: function($$v) {
                  _vm.$set(_vm.currentDataset, "borderWidth", $$v)
                },
                expression: "currentDataset.borderWidth"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "order" } },
              [_c("l10n", { attrs: { value: "Порядок видимости" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              attrs: {
                id: "order",
                type: "number",
                min: "0",
                placeholder: "Введите порядок отображения",
                onlyInteger: "",
                invalid: false
              },
              on: { onInput: _vm.orderUpdate },
              model: {
                value: _vm.currentDataset.order,
                callback: function($$v) {
                  _vm.$set(_vm.currentDataset, "order", $$v)
                },
                expression: "currentDataset.order"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "dataset-settings__colorinput",
            attrs: { for: _vm.currentDataset.label + "-background-color" }
          },
          [
            _c("input", {
              attrs: {
                type: "color",
                id: _vm.currentDataset.label + "-background-color",
                name: _vm.currentDataset.label + "-colorpicker"
              },
              domProps: { value: _vm.currentDataset.backgroundColor },
              on: { input: _vm.selectBackgroundColor }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Цвет фона" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "dataset-settings__colorinput",
            attrs: { for: _vm.currentDataset.label + "-border-color" }
          },
          [
            _c("input", {
              attrs: {
                type: "color",
                id: _vm.currentDataset.label + "-border-color",
                name: _vm.currentDataset.label + "-colorpicker"
              },
              domProps: { value: _vm.currentDataset.borderColor },
              on: { input: _vm.selectBorderColor }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Цвет границы" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
        [
          _c(
            "div",
            { staticStyle: { padding: "5px" } },
            [
              _vm.currentDataset.type === "line"
                ? _c(
                    "label",
                    {
                      staticClass: "dataset-settings__checkbox",
                      attrs: { for: _vm.currentDataset.label + "-fill" }
                    },
                    [
                      _c("ao-checkbox", {
                        attrs: {
                          id: _vm.currentDataset.label + "-fill",
                          checked: _vm.currentDataset.fill
                        },
                        on: { onChange: _vm.changeFill }
                      }),
                      _vm._v(" "),
                      _c("l10n", {
                        attrs: { value: "Заливка области под графиком" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "label",
                  {
                    staticClass: "dataset-settings__checkbox",
                    attrs: {
                      for: _vm.currentDataset.label + "-YAxisAppearance"
                    }
                  },
                  [
                    _c("ao-checkbox", {
                      attrs: {
                        id: _vm.currentDataset.label + "-YAxisAppearance",
                        checked: Boolean(_vm.currentDataset.yAxisID)
                      },
                      on: { onChange: _vm.changeYAxisAppearance }
                    }),
                    _vm._v(" "),
                    _c("l10n", { attrs: { value: "Добавить отдельную шкалу" } })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("my-transition-expand", [
                _vm.currentDataset.type === "bar"
                  ? _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "dataset-settings__checkbox",
                          attrs: {
                            for: _vm.currentDataset.label + "-YAxisAppearancea"
                          }
                        },
                        [
                          _c("ao-checkbox", {
                            attrs: {
                              id:
                                _vm.currentDataset.label + "-YAxisAppearancea",
                              checked: _vm.isBarWidthUsed
                            },
                            on: { onChange: _vm.changeBarWidthAvailability }
                          }),
                          _vm._v(" "),
                          _c("l10n", {
                            attrs: { value: "Использовать толщину бара" }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("my-transition-expand", [
            _vm.currentDataset.type === "line"
              ? _c(
                  "div",
                  {
                    staticClass: "dataset-settings__inputs-container",
                    staticStyle: { padding: "10px" }
                  },
                  [
                    _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "dataset-settings__rangecontainer",
                          attrs: {
                            for: _vm.currentDataset.label + "-borderdash__line"
                          }
                        },
                        [
                          _c("l10n", { attrs: { value: "Линия" } }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "range",
                              id:
                                _vm.currentDataset.label + "-borderdash__line",
                              name:
                                _vm.currentDataset.label + "-borderdash__line",
                              min: "0",
                              max: "20"
                            },
                            domProps: {
                              value: _vm.currentDataset.borderDash[0]
                            },
                            on: { input: _vm.selectBorderDashLine }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "dataset-settings__rangecontainer",
                          attrs: {
                            for: _vm.currentDataset.label + "-borderdash__gap"
                          }
                        },
                        [
                          _c("l10n", { attrs: { value: "Разрыв" } }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "range",
                              id: _vm.currentDataset.label + "-borderdash__gap",
                              name:
                                _vm.currentDataset.label + "-borderdash__gap",
                              min: "0",
                              max: "20"
                            },
                            domProps: {
                              value: _vm.currentDataset.borderDash[1]
                            },
                            on: { input: _vm.selectBorderDashGap }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm.currentDataset.type === "bar"
              ? _c(
                  "div",
                  {
                    staticClass: "dataset-settings__inputs-container",
                    staticStyle: { padding: "10px" }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "dataset-settings__rangecontainer",
                        attrs: {
                          for: _vm.currentDataset.label + "-barPercentage"
                        }
                      },
                      [
                        _c("l10n", { attrs: { value: "Ширина бара" } }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "range",
                            id: _vm.currentDataset.label + "-barPercentage",
                            name: _vm.currentDataset.label + "-barPercentage",
                            min: "0",
                            max: "1",
                            step: "0.1"
                          },
                          domProps: { value: _vm.currentDataset.barPercentage },
                          on: { input: _vm.selectBarPercentage }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "dataset-settings__rangecontainer",
                        attrs: {
                          for: _vm.currentDataset.label + "-categoryPercentage"
                        }
                      },
                      [
                        _c("l10n", { attrs: { value: "Ширина категории" } }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "range",
                            id:
                              _vm.currentDataset.label + "-categoryPercentage",
                            name:
                              _vm.currentDataset.label + "-categoryPercentage",
                            min: "0",
                            max: "1",
                            step: "0.1"
                          },
                          domProps: {
                            value: _vm.currentDataset.categoryPercentage
                          },
                          on: { input: _vm.selectCategoryPercentage }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.isBarWidthUsed && _vm.currentDataset.type === "bar"
            ? _c("div", { staticClass: "dataset-settings__inputs-container" }, [
                _c(
                  "label",
                  {
                    staticClass: "dataset-settings__rangecontainer",
                    attrs: { for: _vm.currentDataset.label + "-barThickness" }
                  },
                  [
                    _c("l10n", { attrs: { value: "Толщина бара" } }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "range",
                        id: _vm.currentDataset.label + "-barThickness",
                        name: _vm.currentDataset.label + "-barThickness",
                        min: "0",
                        max: "1000",
                        step: "1"
                      },
                      domProps: { value: _vm.currentDataset.barThickness },
                      on: { input: _vm.selectBarThickness }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }