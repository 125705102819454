import { render, staticRenderFns } from "./gp-show.vue?vue&type=template&id=490a4183&"
import script from "./gp-show.vue?vue&type=script&lang=js&"
export * from "./gp-show.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/a.mavrayev/dev/work/gloriajeans_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('490a4183')) {
      api.createRecord('490a4183', component.options)
    } else {
      api.reload('490a4183', component.options)
    }
    module.hot.accept("./gp-show.vue?vue&type=template&id=490a4183&", function () {
      api.rerender('490a4183', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/gp/gp-show.vue"
export default component.exports