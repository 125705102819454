const getListOfDates = (endDate) => {
  const dates = [];

  for (let i = 1; i <= endDate; i += 1) {
    const body = { id: i, name: `${i}`, shortName: `${i}` };

    dates.push(body);
  }

  return dates;
};

module.exports = {
  getListOfDates,
};
