var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-simulation-container" },
    [
      _c("div", { staticClass: "event-simulation-table" }, [
        _c(
          "table",
          { staticClass: "ao-table", staticStyle: { width: "100%" } },
          [
            _c(
              "tbody",
              [
                _c(
                  "tr",
                  { staticClass: "ao-table__row bold" },
                  _vm._l(_vm.tableColumns, function(column, i) {
                    return _c(
                      "td",
                      {
                        key: column,
                        staticClass: "ao-table__cell",
                        class: {
                          "without-border": i === 0,
                          "width-limited": i !== 0
                        }
                      },
                      [
                        _c("span", { staticClass: "ao-table__cell-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(column) +
                              "\n            "
                          )
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._l(_vm.rows, function(row, i) {
                  return _c(
                    "tr",
                    { key: "row-" + i, staticClass: "ao-table__row" },
                    _vm._l(row, function(column, i) {
                      return _c(
                        "td",
                        {
                          key: "column-" + i,
                          staticClass: "ao-table__cell",
                          class: {
                            bold: i === 0,
                            "without-border": i === 0,
                            "width-limited": i !== 0
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ao-table__cell-text",
                              attrs: { contenteditable: i === 0 ? false : true }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(column) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                })
              ],
              2
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("vue-chart", {
        attrs: {
          id: "chart-container",
          type: "bar",
          data: _vm.chartData,
          options: _vm.chartOptions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }