import { render, staticRenderFns } from "./ao-policies.vue?vue&type=template&id=2c9f0029&scoped=true&"
import script from "./ao-policies.vue?vue&type=script&lang=js&"
export * from "./ao-policies.vue?vue&type=script&lang=js&"
import style0 from "./ao-policies.vue?vue&type=style&index=0&id=2c9f0029&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9f0029",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/a.mavrayev/dev/work/gloriajeans_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c9f0029')) {
      api.createRecord('2c9f0029', component.options)
    } else {
      api.reload('2c9f0029', component.options)
    }
    module.hot.accept("./ao-policies.vue?vue&type=template&id=2c9f0029&scoped=true&", function () {
      api.rerender('2c9f0029', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/autoorder/views/policies/ao-policies.vue"
export default component.exports