var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ao-pagination" },
    [
      _c("div", { staticClass: "ao-pagination__buttons-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "ao-pagination__button ao-pagination__button_prev",
            attrs: {
              type: "button",
              disabled: _vm.page === 1 || _vm.isDisabled
            },
            on: { click: _vm.goToPrevPage }
          },
          [_c("button-arrow-icon")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ao_pagination__pages" },
          _vm._l(_vm.buttons, function(pageBtn, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass: "ao-pagination__button",
                class: {
                  "ao-pagination__button_active": pageBtn.value === _vm.page
                },
                attrs: {
                  type: "button",
                  disabled: pageBtn.inactive || _vm.isDisabled
                },
                on: {
                  click: function($event) {
                    return _vm.pageChange(pageBtn)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(pageBtn.title) + "\n      ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ao-pagination__button ao-pagination__button_next",
            attrs: {
              type: "button",
              disabled: _vm.page === _vm.lastPage || _vm.isDisabled
            },
            on: { click: _vm.goToNextPage }
          },
          [_c("button-arrow-icon")],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ao-pagination__options" },
        [
          _c("span", { staticClass: "ao-pagination__items-info" }, [
            _vm._v(_vm._s(_vm.paginationOptionsTitle))
          ]),
          _vm._v(" "),
          _c(
            "ao-button",
            {
              ref: "countButton",
              staticClass: "ao-pagination__items-count-button",
              attrs: { minified: "", type: "text" },
              on: {
                click: function($event) {
                  _vm.showItemsPerPagePopover = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.itemsPerPageTitle))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showItemsPerPagePopover
        ? _c(
            "ao-popup",
            {
              staticClass: "ao-pagination__popup",
              attrs: {
                closable: "",
                xPosition: "left",
                yPosition: "top",
                parent: _vm.$refs.countButton.$el
              },
              on: {
                onClose: function($event) {
                  _vm.showItemsPerPagePopover = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "h2",
                          { staticClass: "ao-pagination__popup-title" },
                          [_vm._v("Строк на странице")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2629956782
              )
            },
            [
              _vm._v(" "),
              [
                _c(
                  "ul",
                  { staticClass: "ao-pagination__list" },
                  _vm._l(_vm.itemsOnPageVariants, function(variant) {
                    return _c(
                      "li",
                      { key: variant, staticClass: "ao-pagination__list-item" },
                      [
                        variant === _vm.itemsPerPage
                          ? _c(
                              "i",
                              { staticClass: "ao-pagination__list-check-icon" },
                              [_c("check-large-icon")],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "ao-pagination__list-button",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.changeItemsPerPage(variant)
                              }
                            }
                          },
                          [_vm._v(_vm._s(variant) + " / стр")]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }