var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-container" }, [
    _c("canvas", {
      staticStyle: {
        width: "100%",
        height: "100%",
        "max-height": "100%",
        "max-width": "100%"
      },
      style:
        "background-color: " +
        this.data.canvas.backgroundColor +
        "; border-radius: " +
        this.data.canvas.borderRadius +
        "px",
      attrs: { id: "my-chart" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }