var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forecast-management-container" }, [
    _c("div", { staticClass: "left-side" }, [
      _c(
        "table",
        { staticClass: "ao-table" },
        _vm._l(_vm.forecastRows, function(row) {
          return _c(
            "tr",
            {
              key: row.id,
              class: { "row-selected": _vm.selectedChart === row.value },
              style: { "padding-left": row.level * 16 + "px" },
              attrs: { id: "tr-without-borders" },
              on: {
                click: function($event) {
                  return _vm.changeTemplate(row.value)
                }
              }
            },
            [
              _c(
                "td",
                { staticClass: "node-button" },
                [
                  row.nodeType === "node"
                    ? _c("node-button", { attrs: { isOpen: row.isOpen } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [
                _vm._v("\n          " + _vm._s(row.value) + "\n        ")
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.selectedChart === "History Review"
        ? _c("div", { staticClass: "dates" }, [
            _c("p", { staticClass: "exclude-title" }, [
              _vm._v("Исключить даты:")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "date-row wihout-calendar" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.date3,
                    expression: "date3"
                  }
                ],
                staticClass: "inputblock__input input",
                attrs: {
                  id: "date1",
                  placeholder: "Выберите период",
                  type: "date"
                },
                domProps: { value: _vm.date3 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.date3 = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-addon" }, [_vm._v("—")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.date4,
                    expression: "date4"
                  }
                ],
                staticClass: "inputblock__input input",
                attrs: {
                  id: "date2",
                  placeholder: "Выберите период",
                  type: "date"
                },
                domProps: { value: _vm.date4 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.date4 = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "date-row wihout-calendar" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.date5,
                    expression: "date5"
                  }
                ],
                staticClass: "inputblock__input input",
                attrs: {
                  id: "date1",
                  placeholder: "Выберите период",
                  type: "date"
                },
                domProps: { value: _vm.date5 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.date5 = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-addon" }, [_vm._v("—")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.date6,
                    expression: "date6"
                  }
                ],
                staticClass: "inputblock__input input",
                attrs: {
                  id: "date2",
                  placeholder: "Выберите период",
                  type: "date"
                },
                domProps: { value: _vm.date6 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.date6 = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "feather feather-plus-circle",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }
              },
              [
                _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
                _c("line", {
                  attrs: { x1: "12", y1: "8", x2: "12", y2: "16" }
                }),
                _c("line", { attrs: { x1: "8", y1: "12", x2: "16", y2: "12" } })
              ]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-side" },
      [
        _c("div", { staticClass: "filters" }, [
          _c("div", { staticClass: "date-row wihout-calendar" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.date1,
                  expression: "date1"
                }
              ],
              staticClass: "inputblock__input input",
              attrs: {
                id: "date1",
                placeholder: "Выберите период",
                type: "date"
              },
              domProps: { value: _vm.date1 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.date1 = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-addon" }, [_vm._v("—")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.date2,
                  expression: "date2"
                }
              ],
              staticClass: "inputblock__input input",
              attrs: {
                id: "date2",
                placeholder: "Выберите период",
                type: "date"
              },
              domProps: { value: _vm.date2 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.date2 = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c("my-multiselect", {
                attrs: {
                  id: "shops",
                  options: _vm.shops,
                  selectedOption: _vm.shops[0],
                  placeholder: "Выберите узел"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c("my-multiselect", {
                attrs: {
                  id: "products",
                  options: _vm.products,
                  selectedOption: _vm.products[0],
                  placeholder: "Выберите товар"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedChart === "Alert Review"
            ? _c("label", { attrs: { for: "date7" } }, [
                _vm._v("Дата расчета: ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedChart === "Alert Review"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.date7,
                    expression: "date7"
                  }
                ],
                staticClass: "inputblock__input input",
                attrs: {
                  id: "date7",
                  placeholder: "Дата расчета",
                  type: "date"
                },
                domProps: { value: _vm.date7 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.date7 = $event.target.value
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedChart === "Alert Review"
            ? _c(
                "div",
                { staticClass: "inputblock" },
                [
                  _c("my-multiselect", {
                    attrs: {
                      id: "versions",
                      options: _vm.versions,
                      selectedOption: _vm.versions[0],
                      placeholder: "Выберите версию"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "feather feather-refresh-cw ml-auto",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            },
            [
              _c("polyline", { attrs: { points: "23 4 23 10 17 10" } }),
              _c("polyline", { attrs: { points: "1 20 1 14 7 14" } }),
              _c("path", {
                attrs: {
                  d:
                    "M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "feather feather-save",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
                }
              }),
              _c("polyline", { attrs: { points: "17 21 17 13 7 13 7 21" } }),
              _c("polyline", { attrs: { points: "7 3 7 8 15 8" } })
            ]
          )
        ]),
        _vm._v(" "),
        _vm.selectedChart === "Alert Review"
          ? [
              _c(
                "div",
                { staticClass: "right-side-container" },
                [
                  _c(
                    "table",
                    { staticClass: "ao-table", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "tbody",
                        [
                          _c(
                            "tr",
                            { staticClass: "ao-table__row bold" },
                            _vm._l(_vm.tableColumns, function(column) {
                              return _c(
                                "td",
                                {
                                  key: column.title,
                                  staticClass: "ao-table__cell"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "ao-table__cell-text" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(column.title) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.rows, function(row, i) {
                            return _c(
                              "tr",
                              { key: "row-" + i, staticClass: "ao-table__row" },
                              _vm._l(row, function(column, i) {
                                return _c(
                                  "td",
                                  {
                                    key: "column-" + i,
                                    staticClass: "ao-table__cell"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ao-table__cell-text",
                                        attrs: {
                                          contenteditable:
                                            i === 10 || i === 14 || i === 18
                                              ? true
                                              : false
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(column) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          })
                        ],
                        2
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("vue-chart", {
                    ref: "canvas",
                    attrs: {
                      id: "chart-container",
                      type: "bar",
                      data: _vm.chart1Data,
                      options: _vm.chartOptions
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedChart === "History Review"
          ? [
              _c("vue-chart", {
                attrs: {
                  type: "bar",
                  data: _vm.chart2Data,
                  options: _vm.chartOptions
                }
              })
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedChart === "Event Simulation"
          ? [
              _c(
                "div",
                { staticClass: "event-simulation-container" },
                [
                  _c("div", { staticClass: "event-simulation-table" }, [
                    _c(
                      "table",
                      {
                        staticClass: "ao-table",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _c(
                              "tr",
                              { staticClass: "ao-table__row bold" },
                              _vm._l(_vm.table3Columns, function(column, i) {
                                return _c(
                                  "td",
                                  {
                                    key: column,
                                    staticClass: "ao-table__cell",
                                    class: {
                                      "without-border": i === 0,
                                      "width-limited": i !== 0
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "ao-table__cell-text" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(column) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.rows3, function(row, i) {
                              return _c(
                                "tr",
                                {
                                  key: "row-" + i,
                                  staticClass: "ao-table__row"
                                },
                                _vm._l(row, function(column, i) {
                                  return _c(
                                    "td",
                                    {
                                      key: "column-" + i,
                                      staticClass: "ao-table__cell",
                                      class: {
                                        bold: i === 0,
                                        "without-border": i === 0,
                                        "width-limited": i !== 0
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ao-table__cell-text",
                                          attrs: {
                                            contenteditable:
                                              i === 0 ? false : true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(column) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vue-chart", {
                    ref: "canvas",
                    attrs: {
                      id: "chart_number3",
                      type: "bar",
                      data: _vm.chart3Data,
                      options: _vm.chartOptions
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }