<template>
  <div>
    <div v-if="isLoading" class="loader">
      <div />
      <div />
      <div />
    </div>
    <chart
      v-if="isDataLoaded"
      :data="data"
      ref="chart"
    />
    <div class="ao-chart__button-container">
      <ao-button
        type="text"
        @click="onModalOpen"
        class="modal-btn"
      >
        Настройки графика
      </ao-button>
    </div>
    <chart-modal
      v-model="isModalOpened"
      headerTitle="Настройки графика"
      @chart-updated="updateChart"
      :datasets="data.datasets"
    />
  </div>
</template>

<script>
const { DOMAIN } = require('../../constants/endpoint');
const Chart = require('./autoorder-chartjs.vue').default;
const ChartModal = require('./chart-modal.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const ls = require('../../../api/localStorage');

module.exports = {
  components: {
    Chart,
    ChartModal,
    AoButton,
  },

  ENDPOINTS: {
    REPLENISHMENT_PLAN_CHARTS: `${DOMAIN}/replenishment_plan/chart/data/`,
  },
  props: {
    chartConfig: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  provide() {
    return {
      providedChartData: {
        title: this.data.title,
        datasets: this.data.datasets,
        scales: this.data.scales,
        legend: this.data.legend,
        canvas: this.data.canvas,
        layout: this.data.layout,
        onTitleChanged: this.onTitleChanged,
        onLegendChanged: this.onLegendChanged,
        onScalesChanged: this.onScalesChanged,
        onCanvasChanged: this.onCanvasChanged,
        onLayoutChanged: this.onLayoutChanged,
        onChartUpdate: this.updateChart,
      },
    };
  },

  data() {
    return {
      data: {
        labels: [],
        datasets: [],
        legend: {
          display: true,
          reverse: false,
          position: 'top',
          fullWidth: true,
          labels: {
            fontColor: '#666666',
            fontSize: 14,
            fontStyle: 'normal',
            padding: 10,
            boxWidth: 40,
            usePointStyle: false,
          },
        },
        title: {
          display: true,
          text: 'Название графика',
          fontSize: 12,
          fontColor: '#666666',
          fontStyle: 'bold',
          padding: 10,
        },
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
            gridLines: {
              display: true,
              lineWidth: 1,
            },
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: 30,
            },
            gridLines: {
              display: true,
              lineWidth: 1,
            },
          }],
        },
        canvas: {
          backgroundColor: '#f7f7f7',
          borderRadius: 10,
        },
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
        },
      },
      isModalOpened: false,
      isLoading: false,
      isDataLoaded: false,
    };
  },

  mounted() {
    this.getChartData(this.chartConfig);

    if (localStorage.getItem('dataLegend')) {
      this.data.legend = JSON.parse(localStorage.getItem('dataLegend'));
    }
    if (localStorage.getItem('dataTitle')) {
      this.data.title = JSON.parse(localStorage.getItem('dataTitle'));
    }
    if (localStorage.getItem('dataScales')) {
      this.data.scales = JSON.parse(localStorage.getItem('dataScales'));
    }
    if (localStorage.getItem('dataCanvas')) {
      this.data.canvas = JSON.parse(localStorage.getItem('dataCanvas'));
    }
    if (localStorage.getItem('dataLayout')) {
      this.data.layout = JSON.parse(localStorage.getItem('dataLayout'));
    }
  },

  watch: {
    chartConfig(config) {
      this.getChartData(config);
    },
    data: {
      deep: true,
      handler: _.debounce((newData) => {
        localStorage.setItem('dataLegend', JSON.stringify(newData.legend));
        localStorage.setItem('dataTitle', JSON.stringify(newData.title));
        localStorage.setItem('dataScales', JSON.stringify(newData.scales));
        localStorage.setItem('dataCanvas', JSON.stringify(newData.canvas));
        localStorage.setItem('dataLayout', JSON.stringify(newData.layout));

        newData.datasets.forEach((dataset) => {
          ls.saveToLocalStorage(dataset.id, 'type', dataset.type);
          ls.saveToLocalStorage(dataset.id, 'label', dataset.label);
          ls.saveToLocalStorage(dataset.id, 'fill', dataset.fill);
          ls.saveToLocalStorage(dataset.id, 'borderColor', dataset.borderColor);
          ls.saveToLocalStorage(dataset.id, 'backgroundColor', dataset.backgroundColor);
          ls.saveToLocalStorage(dataset.id, 'borderDash', dataset.borderDash);
          ls.saveToLocalStorage(dataset.id, 'pointRadius', dataset.pointRadius);
          ls.saveToLocalStorage(dataset.id, 'pointStyle', dataset.pointStyle);
          ls.saveToLocalStorage(dataset.id, 'yAxisID', dataset.yAxisID);
          ls.saveToLocalStorage(dataset.id, 'lineTension', dataset.lineTension);
          ls.saveToLocalStorage(dataset.id, 'steppedLine', dataset.steppedLine);
          ls.saveToLocalStorage(dataset.id, 'spanGaps', dataset.spanGaps);
          ls.saveToLocalStorage(dataset.id, 'showLine', dataset.showLine);
          ls.saveToLocalStorage(dataset.id, 'pointHitRadius', dataset.pointHitRadius);
          ls.saveToLocalStorage(dataset.id, 'borderWidth', dataset.borderWidth);
          ls.saveToLocalStorage(dataset.id, 'barPercentage', dataset.barPercentage);
          ls.saveToLocalStorage(dataset.id, 'categoryPercentage', dataset.categoryPercentage);
          ls.saveToLocalStorage(dataset.id, 'barThickness', dataset.barThickness);
        });
      }, 500),
    },
  },

  methods: {
    async getChartData(config) {
      this.isLoading = true;
      let result = [];

      try {
        const URL = this.$options.ENDPOINTS.REPLENISHMENT_PLAN_CHARTS;
        const body = this.getRequestJsonBody(config);
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body,
        };

        const response = await fetch(URL, options);
        this.isLoading = true;

        if (response.ok) {
          result = await response.json();

          this.data.labels = result.labels;
          this.data.datasets = result.datasets.map((dataset) => ({
            id: dataset.id,
            type: ls.loadFromLocalStorage(dataset.id, 'type') ?? dataset.type,
            label: ls.loadFromLocalStorage(dataset.id, 'label') ?? dataset.label,
            data: dataset.data,
            fill: ls.loadFromLocalStorage(dataset.id, 'fill') ?? false,
            borderColor: ls.loadFromLocalStorage(dataset.id, 'borderColor') ?? '#888ddd',
            backgroundColor: ls.loadFromLocalStorage(dataset.id, 'backgroundColor') ?? '#888ddd',
            borderDash: ls.loadFromLocalStorage(dataset.id, 'borderDash') ?? [5, 5],
            pointRadius: ls.loadFromLocalStorage(dataset.id, 'pointRadius') ?? 2,
            order: ls.loadFromLocalStorage(dataset.id, 'order') ?? 4,
            pointStyle: ls.loadFromLocalStorage(dataset.id, 'pointStyle') ?? 'circle',
            yAxisID: ls.loadFromLocalStorage(dataset.id, 'yAxisID') ?? null,
            lineTension: ls.loadFromLocalStorage(dataset.id, 'lineTension') ?? 0.2,
            steppedLine: ls.loadFromLocalStorage(dataset.id, 'steppedLine') ?? false,
            spanGaps: ls.loadFromLocalStorage(dataset.id, 'spanGaps') ?? false,
            showLine: ls.loadFromLocalStorage(dataset.id, 'showLine') ?? true,
            pointHitRadius: ls.loadFromLocalStorage(dataset.id, 'pointHitRadius') ?? 5,
            borderWidth: ls.loadFromLocalStorage(dataset.id, 'borderWidth') ?? 1,
            barPercentage: ls.loadFromLocalStorage(dataset.id, 'barPercentage') ?? 0.9,
            categoryPercentage: ls.loadFromLocalStorage(dataset.id, 'categoryPercentage') ?? 0.9,
            barThickness: ls.loadFromLocalStorage(dataset.id, 'barThickness') ?? null,
          }));
          this.isDataLoaded = true;
          this.updateChart();
        } else {
          this.createNotification('Ошибка при получении данных графика', 'error');
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
      } finally {
        this.isLoading = false;
      }

      return result;
    },
    getRequestJsonBody(config) {
      const { sorting, ...rest } = config;
      return JSON.stringify(rest);
    },
    onModalOpen() {
      this.isModalOpened = true;
    },
    updateChart() {
      this.$refs.chart?.update();
    },
    updateScales() {
      this.$refs.chart?.updateScales();
    },
    updateLegend() {
      this.$refs.chart?.updateLegend();
    },
    updateTitle() {
      this.$refs.chart?.updateTitle();
    },
    updateLayout() {
      this.$refs.chart?.updateLayout();
    },
    updateCanvas() {
      this.$refs.chart?.updateCanvas();
    },
    onScalesChanged(axes) {
      this.data.scales = axes;
      this.updateScales();
    },
    onLegendChanged(legend) {
      this.data.legend = legend;
      this.updateLegend();
    },
    onTitleChanged(title) {
      this.data.title = title;
      this.updateTitle();
    },
    onCanvasChanged(canvas) {
      this.data.canvas = canvas;
      this.updateCanvas();
    },
    onLayoutChanged(layout) {
      this.data.layout = layout;
      this.updateLayout();
    },
  },
};
</script>

<style scoped>
.ao-chart__button-container {
  position: absolute;
  top: 19px;
  right: 62px;
  z-index: 10;
}
.loader {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 12px;
}
.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: #fff;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  left: 4px;
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  left: 14px;
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  left: 24px;
  animation-delay: 0;
}
@keyframes loader {
  0% {
    top: 2px;
    height: 12px;
  }
  50%, 100% {
    top: 6px;
    height: 4px;
  }
}
</style>
