<template>
  <div class="gp-portal">
    <template v-if="fullscreen || indialog">
      <div ref="content"><slot/></div>
    </template>
    <portal v-else :to="to">
      <div ref="content"><slot/></div>
    </portal>
  </div>
</template>
<script>
module.exports = {
  props: {
    to: { type: String },
  },
  data() {
    return {
      indialog: false,
      fullscreen:
                document.fullscreenElement != null &&
                !this.isChildNode(document.fullscreenElement),
    };
  },
  mounted() {
    this.indialog = $(this.$el).closest('.modal-body').length > 0;
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  },
  methods: {
    isChildNode(node) {
      while (node) {
        if (node == this.$refs.content) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    handleFullscreenChange() {
      this.fullscreen =
                document.fullscreenElement != null &&
                !this.isChildNode(document.fullscreenElement);
    },
  },
};
</script>
