<template>
  <div :class="{'gp-chart': true, 'gp-chart-compact': compact}">
    <date-filter
      :stream="stream"
      function="date"
      :notBefore="notBefore"
      :notAfter="notAfter"
      :groups="['timerange']"/>
    <my-tooltip
      ref="tooltip"
      :meta="meta"
      :chart="chart"
      :keepOnly="keepOnly"
      :exclude="exclude"
      :accessor="tooltipAccessor"
      :actions="actions"
    />
    <div class="my-chart" ref="base">
      <gp-data
        id="gp-charts"
        :controls="true"
        :stream="stream"
        :source="source"
        :groups="groups"
        :dims="dims"
        :vals="vals"
        :cols="cols"
        :filter1="filter1"
        :filter2="filter2"
        :instant="instant"
        :throttled="throttled"
        @report="report=$event"
        @reportId="reportId=$event"
        ref="data"/>
      <div ref="chart" :style="{opacity: reportId ? 0.3 : 1}"></div>
    </div>
    <div :class="{'gp-chart-metrics': true, opened: metricsOpened}">
      <a
        href="javascript:void(0)"
        @click="metricsOpened = !metricsOpened">
        <feather-icon name="chevrons-right"/>
        <l10n value="Metrics"/>
      </a>
      <gp-stored
        :compact="true"
        family="chart"
        :username="username"
        v-model="checked"
      />
      <input
        class="form-control form-control-sm"
        :placeholder="l10n('Search...')"
        type="search"
        v-model="metricsSearchString"/>
      <ul class="gp-chart-metrics-list">
        <li
          :key="metric.formula"
          v-for="metric in visibleMetrics"
          v-if="checked[metric.formula] !== undefined">
          <div
            v-if="checked[metric.formula] && nestedColors"
            class="gp-chart-marker">
            <input
              type="color"
              :value="metricColor(metric.name)"
              @change="
                $set(colorsOverride, metric.name, $event.target.value)
                chart.render()
              "/>
            <span :style="{'background-color': metricColor(metric.name)}"/>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`metric-${metric.formula}`"
              :checked="checked[metric.formula]"
              @click="$set(checked, metric.formula, !checked[metric.formula])"
            />
            <label class="form-check-label" :for="`metric-${metric.formula}`">
              <l10n :value="metric.name"/>
              <a class="gp-chart-remove" href="javascript:void(0)" @click="$delete(checked, metric.formula)">
                <feather-icon name="x"/>
              </a>
            </label>
          </div>
        </li>
        <li v-if="visibleMetrics.find(metric => checked[metric.formula] !== undefined)"><hr/></li>
        <li
          :key="metric.formula"
          v-for="metric in visibleMetrics"
          v-if="checked[metric.formula] === undefined">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`metric-${metric.formula}`"
              :checked="checked[metric.formula]"
              @click="$set(checked, metric.formula, !checked[metric.formula])"
            />
            <label class="form-check-label" :for="`metric-${metric.formula}`">
              <l10n :value="metric.name"/>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
let utils = require('../my-utils');
let FuzzySearch = require('fuzzy-search').default;
module.exports = {
  mixins: [
    utils.configHelpers,
    require('../dc/base.js'),
    require('../dc/margin.js'),
    require('../dc/coordinate-grid.js'),
    require('../dc/composite-chart.js'),
  ],
  data() {
    let gpndx = crossfilter([]);
    let dim = gpndx.dimension((row) => row[0]);
    let checked = {};
    if (localStorage.gp_chart_checked) {
      try {
        checked = JSON.parse(localStorage.gp_chart_checked);
      } catch (ex) {
        console.warn(ex);
      }
    }
    let colorsOverride = {};
    if (localStorage.gp_chart_colors) {
      try {
        colorsOverride = JSON.parse(localStorage.gp_chart_colors);
      } catch (ex) {
        console.warn(ex);
      }
    }
    return {
      l10n: utils.l10n,
      gpndx,
      dim,
      meta: null,
      rows: [],
      checked,
      report: null,
      reportId: null,
      metricsOpened: false,
      nestedCharts: {},
      colorsOverride,
      metricsSearchString: '',
    };
  },
  props: {
    stream: { type: String, default: 'default' },
    source: { type: Object, default: null },
    groups: { type: Array, default: () => [] },
    notBefore: { type: String, default: '2019-01-01' },
    notAfter: { type: String, default: '2020-12-31' },
    filter1: { type: String },
    filter2: { type: String },

    sections: { type: Array, default: () => [] },
    metrics: { type: Array, default: () => [] },
    formats: { type: Object, default: () => ({}) },
    formulas: { type: Object, default: () => ({}) },
    timeframes: { type: Object, default: () => ({}) },
    attributes: { type: Array, default: () => [] },
    instant: { type: Boolean, default: false },
    throttled: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    username: { type: String },
    actions: { type: Array },

    defaultMetrics: { type: Array, default: () => [] },

    rightYAxisFormats: { type: Array, default: () => ['value'] },
  },
  mounted() {
    let colors = this.nestedColors;
    if (colors) {
      for (let formula in this.checked) {
        let metric = this.metrics.find((metric) => metric.formula === formula);
      }
    }
  },
  computed: {
    metricsSearch() {
      return new FuzzySearch(this.metrics, ['name'], { caseSensitive: false, sort: true });
    },
    visibleMetrics() {
      return (this.metricsSearchString ? this.metricsSearch.search(this.metricsSearchString) : this.metrics).filter(metric => !metric.deleted);
    },
    vals() {
      let vals = {};

      for (let metric of this.checkedMetrics) {

        let timeframe = 'date';

        let resolveSubstitutes = (calc, depth = 0) => {
          if (depth == 10) {
            return calc;
          }
          return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            let formula = this.formulas[symbol];
            if (formula !== undefined && !this.isAggregationFormula(formula)) {
              return `(${resolveSubstitutes(formula, depth + 1)})`;
            } else {
              return symbol;
            }
          });
        };

        let registerFormula = (symbol) => {
          let formula = this.formulas[symbol];

          if (formula !== undefined) {
            if (this.isAggregationFormula(formula)) {
              vals[`${symbol}_${timeframe}`] =
                                this.resolveDateConditions(
                                  resolveSubstitutes(formula));
            } else {
              for (let [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                registerFormula(symbol);
              }
            }
          }
        };

        registerFormula(metric.formula.split(/[\s,]+/g)[0]);
      }

      return _(vals)
        .toPairs()
        .map(([name, calc]) => ({
          name,
          calc: `${calc} as ${name}`,
          show: false }))
        .value();
    },
    dims() {
      return [{
        calc: 'date',
        name: utils.l10n('date'),
      }];
    },
    cols() {
      cols = [];
      for (let metric of this.checkedMetrics) {
        let calc = undefined;
        let symbol = metric.formula.split(/[\s,]+/g)[0];
        let formula = this.formulas[symbol];
        let timeframe = 'date';
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) {
            calc = `${symbol}_${timeframe}`;
          } else {
            let resolveSubstitutes = (calc, depth = 0) => {
              if (depth == 10) {
                return calc;
              }
              return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                let formula = this.formulas[symbol];
                if (formula !== undefined) {
                  if (this.isAggregationFormula(formula)) {
                    return `${symbol}_${timeframe}`;
                  } else {
                    return `(${resolveSubstitutes(formula, depth + 1)})`;
                  }
                } else {
                  return symbol;
                }
              });
            };
            calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
              return resolveSubstitutes(symbol);
            });
          }
        }
        if (calc !== undefined) {
          cols.push({ calc, name: metric.name, metric });
        }
      }
      return cols;
    },
    checkedMetrics() {
      return this.metrics.filter(({ formula }) => this.checked[formula]);
    },
    nestedColors() {
      try {
        return eval(this.colors);
      } catch {
        return undefined;
      }
    },
  },
  watch: {
    nestedColors() {

    },
    checked: {
      deep: true,
      handler(checked) {
        localStorage.gp_chart_checked = JSON.stringify(checked);
      },
    },
    colorsOverride: {
      deep: true,
      handler(colors) {
        localStorage.gp_chart_colors = JSON.stringify(colors);
      },
    },
    report(report) {
      if (report) {

        this.gpndx.remove(() => true);
        this.gpndx.add(report.rows);

        // for tooltip
        this.rows = report.rows;
        this.meta = report.meta;

        let knownKeys = new Set();
        let needCompose = false;

        let series = {};

        _.forEach(report.meta.columns, ({ calc, metric }, i) => {
          if (metric) {
            let { name, format } = metric;
            let key = JSON.stringify({ calc, name, format });
            let min = 0;
            let max = 0;
            let sum = 0;
            let cnt = 0;
            let useRightYAxis = _.includes(this.rightYAxisFormats, format);
            for (let row of report.rows) {
              min = Math.min(min, row[i]);
              max = Math.max(max, row[i]);
              sum += row[i];
              cnt += 1;
            }
            series[key] = { min, max, sum, cnt, useRightYAxis };
          }
        });

        let groups = _.keys(series).map(key => [key]);
        let closeBy = (a,b) => {
          let d = Math.abs(a.sum-b.sum) / (a.sum+b.sum);
          return d < 0.8;
        };
        let mergeGroups = () => {
          for (let i=0; i<groups.length-1; ++i) {
            for (let j=i+1; j<groups.length; ++j) {
              let xs = groups[i];
              let ys = groups[j];
              if (_.some(xs, x => _.some(ys, y =>
                series[x].useRightYAxis == series[y].useRightYAxis &&
                                    closeBy(series[x], series[y])))) {
                xs.push(...ys);
                groups.splice(j,1);
                return true;
              }
            }
          }
          return false;
        };
        while (mergeGroups()) {}

        for (let useRightYAxis of [false, true]) {
          let axisScale = undefined;
          for (let group of groups) {
            if (series[group[0]].useRightYAxis == useRightYAxis) {
              let max = _(group).map(x => series[x].max).max();
              let scale = 1;
              if (max > 0) {
                while (max * scale > 100) {
                  scale /= 10;
                }
                while (max * scale < 10) {
                  scale *= 10;
                }
              }
              if (axisScale === undefined) {
                axisScale = scale;
                scale = 1;
              } else {
                scale /= axisScale;
              }
              for (let x of group) {
                series[x].scale = scale;
              }
            }
          }
        }

        _.forEach(report.meta.columns, ({ calc, metric }, i) => {
          if (metric) {
            let { name, format } = metric;
            let key = JSON.stringify({ calc, name, format });
            let { min, max, useRightYAxis, scale } = series[key];
            let grp = this.dim.group().reduce(
              (p,v) => p + v[i] * scale,
              (p,v) => p - v[i] * scale,
              () => 0);
            if (!this.nestedCharts[key]) {
              let chart = dc.lineChart(this.chart);
              chart.dimension(this.dim);
              chart.x(d3.scaleTime());
              chart.colors(this.metricColor);
              chart.useRightYAxis(useRightYAxis);
              // chart.render()
              this.nestedCharts[key] = chart;
              needCompose = true;
            }
            let chart = this.nestedCharts[key];
            chart.group(grp, name, (d) => d.value);
            chart.colors(this.metricColor);
            knownKeys.add(key);
          }
        });
        for (let key of _.keys(this.nestedCharts)) {
          if (!knownKeys.has(key)) {
            delete this.nestedCharts[key];
            needCompose = true;
          }
        }

        if (needCompose) {
          this.chart.compose(_.values(this.nestedCharts));
        }
        this.chart.render();
      }
    },
  },
  methods: {
    tooltipAccessor(data) {
      let row = this.rows.find((row) => row[0] == data.key);
      if (!row) {
        return [];
      }
      return _.map(this.meta.columns, ({ metric }, i) => {
        if (metric) {
          let value = row[i];
          try {
            value = eval(this.formats[metric.format])(value);
          } catch {}
          return {
            name: metric.name,
            value,
            color: this.metricColor(metric.name),
          };
        }
      }).filter(_.identity);
    },
    metricColor(formula) {
      if (this.colorsOverride[formula]) {
        return this.colorsOverride[formula];
      }
      return this.nestedColors ? this.nestedColors(formula) : null;
    },
    createChart(instant) {
      if (!this.chart) {
        this.chart = dc.compositeChart(this.$refs.chart);
        dc.chartRegistry.deregister(this.chart);
        this.setupChart(this.chart);
      }
      this.renderChart(instant);
    },
    isAggregationFormula(formula) {
      return formula.match(/(sum|min|max|one|and|any|cnt|avg|first|last)\s*\(.*\)/);
    },
  },

};
</script>
<style>
.gp-chart {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.gp-chart .my-date-filter {
    position: absolute;
    left: 10px;
    top: 2px;
}
.gp-chart .my-chart {
    flex-basis: 1px;
    flex-grow: 1;
    margin-top: 0!important;
}
.gp-chart-metrics-list {
    font-size: 14px;
    line-height: 18px;
    list-style: none;
    margin: 0;
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100% - 35px);
}
.gp-chart-metrics-list input {
    margin-top: 3px;
}
.gp-chart-metrics-list li {
    position: relative;
    padding-left: 8px;
    padding-right: 16px;
}
.gp-chart-metrics-list li:last-child {
    padding-bottom: 10px;
}
.gp-chart-remove {
    margin-top: 0px;
    margin-bottom: -1px;
    visibility: hidden;
    display: inline-block;
    vertical-align: top;
}
.gp-chart li:hover .gp-chart-remove {
    visibility: initial;
}
.gp-chart-remove,
.gp-chart-remove:hover {
    color: var(--dark);
}
.my-dark-theme .gp-chart-remove,
.my-dark-theme .gp-chart-remove:hover {
    color: var(--light);
}
.gp-chart-remove svg {
    width: 16px;
    height: 16px;
}
.gp-chart-marker {
    position: absolute;
    margin-top: 4px;
    margin-left: -18px;
    width: 11px;
    height: 11px;
    overflow: hidden;
}
.gp-chart-marker input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.gp-chart-marker span {
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 6px solid white;
    border-radius: 50%;
    pointer-events: none;
}
.my-dark-theme .gp-chart-marker span {
    border-color: #222;
}
.gp-chart > * {
    transition: opacity 0.3s ease;
}
.gp-chart-metrics {
    width: calc(300px + var(--scrollbar-size));
    max-width: 50%;
}
.gp-chart-metrics > a {
    display: none;
}
.gp-chart-metrics input[type="search"] {
    margin-top: 4px;
    margin-left: 0px;
    line-height: 30px;
    width: calc(100% - 50px);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid
    var(--gray-dark);
    background-color: transparent;
}
.gp-chart-metrics input[type="search"]:focus {
    outline: none;
    box-shadow: none;
}
.my-dark-theme .gp-chart-metrics input[type="search"] {
    color: white;
    border-color: var(--light);
}
.gp-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
}
.gp-chart .my-date-filter {
    top: 4px;
}
@media only screen and (max-width: 1024px) {
    .gp-chart-metrics {
        z-index: 2;
        background-color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        max-width: 100%;
        border-left: 1px solid var(--gray);
        padding-left: 40px!important;
        right: 0;
        transform: translate(100%) translate(-31px);
    }
    .my-dark-theme .gp-chart-metrics {
        color: white;
        background-color: #333;
    }
    .my-dark-theme .gp-chart-metrics > a span {
        color: #ccc;
    }
    .gp-chart {
        padding-right: 30px;
    }
    .gp-chart-metrics.opened {
        transform: none;
    }
    .gp-chart-metrics > a {
        position: absolute;
        left: 0;
        width: 30px;
        top: 0;
        bottom: 0;
        display: block;
    }
    .gp-chart-metrics > a span {
        color: #444;
    }
    .gp-chart-metrics > a span.feather-icon {
        display: none;
        opacity: 0.7;
    }
    .gp-chart-metrics > a:hover span.feather-icon {
        opacity: 1;
    }
    .gp-chart-metrics.opened > a span.feather-icon {
        display: block;
        position: absolute;
        margin: 5px;
    }
    .gp-chart-metrics > a span:not(.feather-icon) {
        position: absolute;
        cursor: pointer;
        transform-origin: 0 0;
        transform: rotate(-90deg) translate(-100%) translate(-40px, 4px);
    }
}

.gp-chart-compact .gp-chart-metrics {
    z-index: 2;
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    max-width: 100%;
    border-left: 1px solid var(--gray);
    padding-left: 40px!important;
    right: 0;
    transform: translate(100%) translate(-31px);
}
.my-dark-theme .gp-chart-compact .gp-chart-metrics {
    color: white;
    background-color: #333;
}
.my-dark-theme .gp-chart-compact .gp-chart-metrics > a span {
    color: #ccc;
}
.gp-chart.gp-chart-compact  {
    padding-right: 30px;
}
.gp-chart-compact .gp-chart-metrics.opened {
    transform: none;
}
.gp-chart-compact .gp-chart-metrics > a {
    position: absolute;
    left: 0;
    width: 30px;
    top: 0;
    bottom: 0;
    display: block;
}
.gp-chart-compact .gp-chart-metrics > a span {
    color: #444;
}
.gp-chart-compact .gp-chart-metrics > a span.feather-icon {
    display: none;
    opacity: 0.7;
}
.gp-chart-compact .gp-chart-metrics > a:hover span.feather-icon {
    opacity: 1;
}
.gp-chart-compact .gp-chart-metrics.opened > a span.feather-icon {
    display: block;
    position: absolute;
    margin: 5px;
}
.gp-chart-compact .gp-chart-metrics > a span:not(.feather-icon) {
    position: absolute;
    cursor: pointer;
    transform-origin: 0 0;
    transform: rotate(-90deg) translate(-100%) translate(-40px, 4px);
}

.gp-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
}
.gp-chart .my-date-filter {
    top: 4px;
}
.gp-chart .dc-chart svg {
    position: absolute;
}
.gp-chart .gp-date .feather-icon {
    display: none;
}
.gp-chart .gp-stored {
    margin-top: 4px;
    margin-right: 30px;
    margin-bottom: -10px;
}
.gp-chart .gp-stored-select > a.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.gp-chart .gp-stored-select > a + a {
    margin-left: 6px;
}
.gp-chart .gp-stored-select > a svg {
    width: 18px!important;
}
.gp-chart .gp-select > input,
.gp-chart-metrics input[type="search"] {
    width: calc(100% - 10px);
    font-size: 15px;
    height: 28px!important;
    padding: 2px 6px!important;
    line-height: 20px!important;
    border-color: var(--dark);
}
</style>
