<template>
  <div class="common-settings">
    <div class="commmon-settings__checkboxes-container">
      <label
        for="legendDisplay"
        class="common-settings__checkbox common-settings__checkbox--first"
      >
        <ao-checkbox
          id="legendDisplay"
          :checked="currentLegend.display"
          @onChange="changeLegendDisplay"
        />
        <l10n value="Показывать легенду" />
      </label>
      <label
        v-if="currentLegend.display"
        for="legendReverse"
        class="common-settings__checkbox"
      >
        <ao-checkbox
          id="legendReverse"
          :checked="currentLegend.reverse"
          @onChange="changeLegendReverse"
        />
        <l10n value="Развернуть легенду" />
      </label>
    </div>

    <my-transition-expand>
      <div v-if="currentLegend.display">
        <div class="common-settings__inputs-container">
          <div class="inputblock">
            <span class="inputblock__label">
              <l10n value="Расположение легенды" />
            </span>
            <my-multiselect
              :selectedOption="legendPositions
                .find((pos) => pos.value === currentLegend.position).title"
              :options="legendPositions.map(item => item.title)"
              label="legendPosition"
              placeholder="Выберите расположение легенды"
              @select="selectLegendPosition"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="legendLabelFontSize">
              <l10n value="Размер шрифта" />
            </label>
            <ao-input
              id="legendLabelFontSize"
              v-model="currentLegend.labels.fontSize"
              type="number"
              placeholder="Введите размер шрифта"
              onlyInteger
              :invalid="false"
              @onInput="updateLegengLabelFontSize"
            />
          </div>
          <div class="inputblock">
            <span class="inputblock__label">
              <l10n value="Стиль текста" />
            </span>
            <my-multiselect
              :selectedOption="legendLabelStyleSelectedOption"
              :options="fontStyles.map(item => item.title)"
              label="fontStyles"
              placeholder="Выберите стиль текста"
              @select="legendLabelStyleSelect"
            />
          </div>
          <label
            for="usePointStyle"
            class="common-settings__checkbox"
          >
            <ao-checkbox
              id="usePointStyle"
              :checked="currentLegend.labels.usePointStyle"
              @onChange="changeUsePointStyle"
            />
            <l10n value="Повторять стиль точки" />
          </label>
        </div>
        <div class="commmon-settings__labelsettings-container">
          <div class="inputblock">
            <label class="inputblock__label" for="labelLegendPadding">
              <l10n value="Отступы элементов легенды" />
            </label>
            <ao-input
              id="labelLegendPadding"
              v-model="currentLegend.labels.padding"
              type="number"
              placeholder="Введите размер отступов"
              onlyInteger
              :invalid="false"
              @onInput="updateLegendLabelPadding"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="labelLegendBoxWidth">
              <l10n value="Размер контейнера элементов легенды" />
            </label>
            <ao-input
              id="labelLegendBoxWidth"
              v-model="currentLegend.labels.boxWidth"
              type="number"
              placeholder="Введите ширину контейнера"
              onlyInteger
              :invalid="false"
              @onInput="updateLegendLabelBoxWidth"
            />
          </div>
          <div class="common-settings__colorinput">
            <label for="legendLabelColor" class="common-settings__colorinput">
              <input
                type="color"
                id="legendLabelColor"
                name="legendLabelColorpicker`"
                :value="currentLegend.labels.fontColor"
                @input="selectLegendLabelsColor"
              />
              <l10n value="Цвет текста" />
            </label>
          </div>
        </div>
      </div>
    </my-transition-expand>

    <label
      for="titleDisplay"
      class="common-settings__checkbox common-settings__checkbox--first"
    >
      <ao-checkbox
        id="titleDisplay"
        :checked="currentTitle.display"
        @onChange="changeTitleDisplay"
      />
      <l10n value="Показывать название графика" />
    </label>

    <my-transition-expand>
      <div v-if="currentTitle.display">
        <div class="common-settings__inputs-container">
          <div class="inputblock">
            <label class="inputblock__label" for="chartTitle">
              <l10n value="Название графика" />
            </label>
            <ao-input
              id="chartTitle"
              v-model="currentTitle.text"
              placeholder="Введите название графика"
              :invalid="false"
              @onInput="updateChartTitle"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="titleFontSize">
              <l10n value="Размер шрифта" />
            </label>
            <ao-input
              id="titleFontSize"
              v-model="currentTitle.fontSize"
              type="number"
              placeholder="Введите размер шрифта"
              onlyInteger
              :invalid="false"
              @onInput="updateTitleFontSize"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="titlePadding">
              <l10n value="Вертикальные отступы" />
            </label>
            <ao-input
              id="titlePadding"
              v-model="currentTitle.padding"
              type="number"
              placeholder="Введите размер отступов"
              onlyInteger
              :invalid="false"
              @onInput="updateTitlePadding"
            />
          </div>
        </div>
        <div class="common-settings__inputs-container">
          <label for="chartTitleColor" class="common-settings__colorinput">
            <input
              type="color"
              id="chartTitleColor"
              name="chartTitleColorpicker`"
              :value="currentTitle.fontColor"
              @input="selectTitleColor"
            />
            <l10n value="Цвет текста" />
          </label>
          <div class="inputblock">
            <span class="inputblock__label">
              <l10n value="Стиль текста" />
            </span>
            <my-multiselect
              :selectedOption="titleStyleSelectedOption"
              :options="fontStyles.map(item => item.title)"
              label="fontStyles"
              placeholder="Выберите стиль текста"
              @select="fontStyleSelect"
            />
          </div>
        </div>
      </div>
    </my-transition-expand>

    <div class="commmon-settings__checkboxes-container">
      <label
        for="xGridlinesDisplay"
        class="common-settings__checkbox common-settings__checkbox--first"
      >
        <ao-checkbox
          id="xGridlinesDisplay"
          :checked="currentScales.xAxes[0].gridLines.display"
          @onChange="changeXGridlinesDisplay"
        />
        <l10n value="Вертикальные линии сетки" />
      </label>
      <label
        for="yGridlinesDisplay"
        class="common-settings__checkbox"
      >
        <ao-checkbox
          id="yGridlinesDisplay"
          :checked="currentScales.yAxes[0].gridLines.display"
          @onChange="changeYGridlinesDisplay"
        />
        <l10n value="Горизонтальные линии сетки" />
      </label>
    </div>
    <div class="common-settings__inputs-container">
      <div class="inputblock">
        <label class="inputblock__label" for="xGridLinesWidth">
          <l10n value="Ширина вертикальных линии" />
        </label>
        <ao-input
          id="xGridLinesWidth"
          v-model="currentScales.xAxes[0].gridLines.lineWidth"
          type="number"
          min="0"
          placeholder="Введите ширину линии"
          onlyInteger
          :invalid="false"
          @onInput="gridLinesWidthUpdate"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="yGridLinesWidth">
          <l10n value="Ширина горизонтальных линии" />
        </label>
        <ao-input
          id="yGridLinesWidth"
          v-model="currentScales.yAxes[0].gridLines.lineWidth"
          type="number"
          min="0"
          placeholder="Введите ширину линии"
          onlyInteger
          :invalid="false"
          @onInput="gridLinesWidthUpdate"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="canvasRadius">
          <l10n value="Радиус закругления" />
        </label>
        <ao-input
          id="canvasRadius"
          v-model="currentCanvas.borderRadius"
          type="number"
          min="0"
          placeholder="Введите радиус закругления"
          onlyInteger
          :invalid="false"
          @onInput="updateCanvasRadius"
        />
      </div>
      <label for="canvasColor" class="common-settings__colorinput">
        <input
          type="color"
          id="canvasColor"
          name="canvasColorpicker"
          :value="currentCanvas.backgroundColor"
          @input="selectCanvasColor"
        />
        <l10n value="Цвет фона полотна" />
      </label>
    </div>
    <h6 style="margin-top: 5px;">Отступы полотна графика</h6>
    <div class="commmon-settings__paddingsettings-container">
      <div class="inputblock">
        <label class="inputblock__label" for="canvasPaddingLeft">
          <l10n value="Слева" />
        </label>
        <ao-input
          id="canvasPaddingLeft"
          v-model="currentLayout.padding.left"
          type="number"
          min="0"
          placeholder="Введите отступ слева"
          onlyInteger
          :invalid="false"
          @onInput="updateCanvasPadding"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="canvasPaddingRight">
          <l10n value="Справа" />
        </label>
        <ao-input
          id="canvasPaddingRight"
          v-model="currentLayout.padding.right"
          type="number"
          min="0"
          placeholder="Введите отступ справа"
          onlyInteger
          :invalid="false"
          @onInput="updateCanvasPadding"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="canvasPaddingTop">
          <l10n value="Сверху" />
        </label>
        <ao-input
          id="canvasPaddingTop"
          v-model="currentLayout.padding.top"
          type="number"
          min="0"
          placeholder="Введите отступ сверху"
          onlyInteger
          :invalid="false"
          @onInput="updateCanvasPadding"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="canvasPaddingBottom">
          <l10n value="Снизу" />
        </label>
        <ao-input
          id="canvasPaddingBottom"
          v-model="currentLayout.padding.bottom"
          type="number"
          min="0"
          placeholder="Введите отступ снизу"
          onlyInteger
          :invalid="false"
          @onInput="updateCanvasPadding"
        />
      </div>
    </div>
  </div>
</template>

<script>
const MyMultiselect = require('../../../my-multiselect.vue').default;
const AoCheckbox = require('../shared/ao-checkbox.vue').default;
const AoInput = require('../shared/ao-input.vue').default;
const MyTransitionExpand = require('../../../my-transition-expand.vue').default;

module.exports = {
  components: {
    AoCheckbox,
    MyMultiselect,
    AoInput,
    MyTransitionExpand,
  },

  inject: {
    providedChartData: {
      default: {
        title: {},
        legend: {},
        scales: {},
        canvas: {},
        layout: {},
        onTitleChanged: () => {},
        onLegendChanged: () => {},
        onScalesChanged: () => {},
        onCanvasChanged: () => {},
        onLayoutChanged: () => {},
      },
    },
  },

  data() {
    return {
      currentLegend: this.providedChartData.legend,
      currentTitle: this.providedChartData.title,
      currentScales: this.providedChartData.scales,
      currentCanvas: this.providedChartData.canvas,
      currentLayout: this.providedChartData.layout,
      legendPositions: [
        { title: 'Вверху', value: 'top' },
        { title: 'Внизу', value: 'bottom' },
        { title: 'Слева', value: 'left' },
        { title: 'Справа', value: 'right' },
      ],
      fontStyles: [
        { title: 'Курсивный', value: 'italic' },
        { title: 'Нормальный', value: 'normal' },
        { title: 'Жирный', value: 'bold' },
      ],
    };
  },

  computed: {
    titleStyleSelectedOption() {
      return this.fontStyles
        .find((style) => style.value === this.currentTitle.fontStyle).title;
    },
    legendLabelStyleSelectedOption() {
      return this.fontStyles
        .find((style) => style.value === this.currentLegend.labels.fontStyle).title;
    },
  },

  methods: {
    changeLegendDisplay(checked) {
      this.currentLegend.display = checked;
      this.providedChartData.onLegendChanged(this.currentLegend);
    },
    changeLegendReverse(checked) {
      this.currentLegend.reverse = checked;
      this.providedChartData.onLegendChanged(this.currentLegend);
    },
    selectLegendPosition(value) {
      this.currentLegend.position = this.legendPositions
        .find((pos) => pos.title === value).value;
      this.providedChartData.onLegendChanged(this.currentLegend);
    },
    changeTitleDisplay(checked) {
      this.currentTitle.display = checked;
      this.providedChartData.onTitleChanged(this.currentTitle);
    },
    updateChartTitle() {
      this.$nextTick(() => {
        this.providedChartData.onTitleChanged(this.currentTitle);
      });
    },
    changeXGridlinesDisplay(checked) {
      this.currentScales.xAxes[0].gridLines.display = checked;
      this.providedChartData.onScalesChanged(this.currentScales);
    },
    changeYGridlinesDisplay(checked) {
      this.currentScales.yAxes[0].gridLines.display = checked;
      this.providedChartData.onScalesChanged(this.currentScales);
    },
    changeUsePointStyle(checked) {
      this.currentLegend.labels.usePointStyle = checked;
      this.providedChartData.onLegendChanged(this.currentLegend);
    },
    gridLinesWidthUpdate() {
      this.$nextTick(() => {
        this.providedChartData.onScalesChanged(this.currentScales);
      });
    },
    updateTitleFontSize() {
      this.$nextTick(() => {
        this.providedChartData.onTitleChanged(this.currentTitle);
      });
    },
    updateLegengLabelFontSize() {
      this.$nextTick(() => {
        this.providedChartData.onLegendChanged(this.currentLegend);
      });
    },
    selectTitleColor(event) {
      this.$nextTick(() => {
        this.currentTitle.fontColor = event.target.value;
        this.providedChartData.onTitleChanged(this.currentTitle);
      });
    },
    selectLegendLabelsColor(event) {
      this.$nextTick(() => {
        this.currentLegend.labels.fontColor = event.target.value;
        this.providedChartData.onLegendChanged(this.currentLegend);
      });
    },
    fontStyleSelect(value) {
      this.currentTitle.fontStyle = this.fontStyles
        .find((style) => style.title === value).value;
      this.providedChartData.onTitleChanged(this.currentTitle);
    },
    legendLabelStyleSelect(value) {
      this.currentLegend.labels.fontStyle = this.fontStyles
        .find((style) => style.title === value).value;
      this.providedChartData.onLegendChanged(this.currentLegend);
    },
    updateTitlePadding() {
      this.$nextTick(() => {
        this.providedChartData.onTitleChanged(this.currentTitle);
      });
    },
    updateLegendLabelPadding() {
      this.$nextTick(() => {
        this.providedChartData.onLegendChanged(this.currentLegend);
      });
    },
    updateLegendLabelBoxWidth() {
      this.$nextTick(() => {
        this.providedChartData.onLegendChanged(this.currentLegend);
      });
    },
    updateCanvasRadius() {
      this.$nextTick(() => {
        this.providedChartData.onCanvasChanged(this.currentCanvas);
      });
    },
    selectCanvasColor(event) {
      this.$nextTick(() => {
        this.currentCanvas.backgroundColor = event.target.value;
        this.providedChartData.onCanvasChanged(this.currentCanvas);
      });
    },
    updateCanvasPadding() {
      this.$nextTick(() => {
        this.providedChartData.onLayoutChanged(this.currentLayout);
      });
    },
  },
};
</script>

<style scoped>
.common-settings {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 10px #A0A0AA33;
}
.common-settings__checkbox {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.commmon-settings__checkboxes-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 24px;
}
.inputblock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputblock--first {
  margin-right: 24px;
}
.inputblock__label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
  margin: 0;
}
.inputblock__input {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 14px 8px;
  line-height: 18px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  transition: all 0.15s ease-in-out;
}
.commmon-settings__labelsettings-container,
.common-settings__inputs-container,
.commmon-settings__titlesettings-container,
.commmon-settings__paddingsettings-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
}
.common-settings .multiselect {
  min-width: 223px;
}
.common-settings__colorinput {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  width: 223px;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

input[type='color'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
input[type='color']::-webkit-color-swatch {
    border-radius: 5px;
    border: none;
}
input[type='color']::-moz-color-swatch {
    border-radius: 5px;
    border: none;
}
</style>
