<template>
  <span
    class="gp-date"
    :class="{focused}"
    :title="warningText"
  >
    <a
      href="javascript:void(0)">
      <span>{{formattedDate}}</span>
      <input
        type="date"
        required
        ref="input"
        class="form-control form-control-sm"
        :min="min"
        :max="max"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="handleKeyDown"
        v-model="modelValue"/>
    </a>
    <feather-icon
      name="alert-circle"
      v-if="warningText"/>
  </span>
</template>
<script>
module.exports = {
  props: {
    value: { type: String },
    label: { type: String },
    max: { type: String },
    min: { type: String },
    expectToday: { type: Boolean },
  },
  data() {
    return {
      modelValue: this.value,
      today: moment().format('YYYY-MM-DD'),
      focused: false,
    };
  },
  mounted() {
    this.timer = setInterval(this.updateToday, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
  },
  computed: {
    warningText() {
      if (!this.expectToday) {
        return '';
      }
      if (this.modelValue < this.today) {
        return utils.l10n('Selected date in past');
      }
      if (this.modelValue > this.today) {
        return utils.l10n('Selected date in future');
      }
    },
    formattedDate() {
      return this.modelValue ? this.parseDate(this.modelValue).toLocaleDateString() : 'N/A';
    },
  },
  methods: {
    updateToday() {
      this.today = moment().format('YYYY-MM-DD');
    },
    handleKeyDown(e) {
      if (e.key == 'Enter') {
        e.target.blur();
      }
    },
    handleBlur() {
      this.focused = false;
      if (this.modelValue != '' && this.value != this.modelValue) {
        this.$emit('input', this.modelValue);
      }
    },
    handleFocus() {
      this.focused = true;
    },
    parseDate(text) {
      let [y,m,d] = text.split('-').map((x) => parseInt(x));
      return new Date(y,m-1,d);
    },
  },
};
</script>
<style>
.gp-date a {
    display: inline-block;
    position: relative;
}
.gp-date a input {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    bottom: -2px;
    margin: 0;
    padding: 0;
    opacity: 0;
    padding-left: 4px;
    font-size: inherit;
    max-width: 124px;
    z-index: 1;
}
.gp-date.focused a input {
    opacity: 1;
    z-index: 2;
}
.gp-date:not(.focused) a input {
    overflow: hidden;
}
.gp-date:not(.focused) a input {
    cursor: pointer;
}
.gp-date a input::-webkit-calendar-picker-indicator {
    padding-left: 3px;
    margin-left: 0;
}
.gp-date a input::-webkit-clear-button,
.gp-date a input::-moz-clear-button {
    display: none;
}
</style>
