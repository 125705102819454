var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "popup",
      staticClass: "ao-popup",
      style: _vm.popupPosition,
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _vm.$slots.header
        ? _c(
            "header",
            { staticClass: "ao-popup__header" },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("main", { staticClass: "ao-popup__main" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c(
            "footer",
            { staticClass: "ao-popup__footer" },
            [_vm._t("footer")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }