var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouses", attrs: { id: "warehouses" } },
    [
      _c(
        "header",
        { staticClass: "warehouses__header" },
        [
          _c("ao-tabs", {
            attrs: {
              staticTabs: _vm.tabs,
              dynamicTabs: _vm.cardTabs,
              currentTab: _vm.reactiveData.currentTabView
            },
            on: { onSelect: _vm.selectTab, onCloseTab: _vm.tabCloseInit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "warehouses__main" },
        [
          _vm.currentComponent
            ? _c(
                "keep-alive",
                { attrs: { include: ["warehouses-catalog"] } },
                [_c(_vm.currentComponent, { tag: "component" })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("close-tab-modal", {
        on: { onTabCloseConfirm: _vm.tabCloseConfirm },
        model: {
          value: _vm.closeTabModalVisible,
          callback: function($$v) {
            _vm.closeTabModalVisible = $$v
          },
          expression: "closeTabModalVisible"
        }
      }),
      _vm._v(" "),
      _c("category-remove-modal", {
        staticClass: "category-remove-modal",
        on: {
          onConfirm: _vm.categoryRemoveConfirm,
          onDiscard: function($event) {
            _vm.categoryForRemoveId = null
          }
        },
        model: {
          value: _vm.categoryRemoveModalVisible,
          callback: function($$v) {
            _vm.categoryRemoveModalVisible = $$v
          },
          expression: "categoryRemoveModalVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }