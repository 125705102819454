var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M4 8.44401L14.6667 8.44401M14.6667 8.44401C14.6667 9.91677 15.8606 11.1107 17.3333 11.1107C18.8061 11.1107 20 9.91677 20 8.44401C20 6.97125 18.8061 5.77734 17.3333 5.77734C15.8606 5.77734 14.6667 6.97125 14.6667 8.44401ZM9.33333 15.5551L20 15.5551M9.33333 15.5551C9.33333 17.0279 8.13943 18.2218 6.66667 18.2218C5.19391 18.2218 4 17.0279 4 15.5551C4 14.0824 5.19391 12.8885 6.66667 12.8885C8.13943 12.8885 9.33333 14.0824 9.33333 15.5551Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }