const { DOMAIN } = require('./endpoint');
const {
  getProductName,
  getProductCategoryName,
  getWarehouseName,
  getWarehouseCategoryName,
  getAuthorName,
  getStartAt,
  getEndAt,
  getCreatedAt,
  getPromoName,
  getPolicyName,
  getIdNameField,
  getLeafType,
  getLeafName,
  getLeafArticle,
  getLeafAuthor,
  getLeafStartAt,
  getLeafFinishDate,
  getLeafMechanicsBuy,
  getLeafMechanicsExec,
  getLeafUnionOrderFlag,
} = require('../utils/tableColumnNameGetters');

// promos
const COMPONENT_NAMES = {
  catalog: 'PromosCatalog',
  register: 'PromosRegister',
  promo: 'PromoCard',
  rules: 'PromoRulesCard',
};
const promoTabsStorageKey = 'promos-tabs';
const promoEntityStorageKey = 'promos-cards';
const promoRulesEntityStorageKey = 'promo-rules-cards';
const newPromo = 'new-promo-card';
const newPromoRule = 'new-promo-rule';
const catalogTab = { id: 'promos-catalog', title: 'Справочник промо', componentName: COMPONENT_NAMES.catalog };
const registerTab = { id: 'promos-register', title: 'Реестр привязок промо', componentName: COMPONENT_NAMES.register };

// storage keys
const storageKeys = {
  parentName: 'promos',
  register: {
    visibleColumnsKey: 'promos-register-visible-cols',
    columnsSizeKey: 'promos-register-columns-size',
  },
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID промо', getter: getIdNameField },
  { id: 'name', title: 'Наименование промо', getter: getLeafName },
  { id: 'type', title: 'Тип промо', getter: getLeafType },
  { id: 'code', title: 'Код промо', getter: getLeafArticle },
  { id: 'startAt', title: 'Дата старта промо', getter: getLeafStartAt },
  { id: 'finishAt', title: 'Дата завершения промо', getter: getLeafFinishDate },
  { id: 'mechanicsBuy', title: 'Механика закупки', getter: getLeafMechanicsBuy },
  { id: 'mechanicsExec', title: 'Механика проведения', getter: getLeafMechanicsExec },
  { id: 'author', title: 'Автор', getter: getLeafAuthor },
  { id: 'unionOrder', title: 'Признак объединять заказы', getter: getLeafUnionOrderFlag },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/promos/categories/hierarchy/`,
  leafsField: 'promos',
};

// register
const registerColumns = [
  {
    id: 'id',
    title: 'ID связи',
    field: 'id',
    config: {
      sorting: true,
      filterType: 'numeric',
    },
  },
  {
    id: 'policy_name',
    title: 'Политика',
    field: getPolicyName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'product_name',
    title: 'Товар',
    field: getProductName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'product_category_name',
    title: 'Категория товара',
    field: getProductCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_name',
    title: 'Узел',
    field: getWarehouseName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_category_name',
    title: 'Категория узла',
    field: getWarehouseCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'start_at',
    title: 'Дата действия с',
    field: getStartAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'end_at',
    title: 'Дата действия до',
    field: getEndAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'description',
    title: 'Описание',
    field: 'description',
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'created_at',
    title: 'Дата создания',
    field: getCreatedAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'author',
    title: 'Автор',
    field: getAuthorName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'promo_name',
    title: 'Промо',
    field: getPromoName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  endpoint: `${DOMAIN}/promos/connections/filtered/`,
  configEndpoint: `${DOMAIN}/promos/connections/filtered/config/`,
};

// endpoints
const ENDPOINTS = {
  PROMOS: `${DOMAIN}/promos/`,
  CONNECTIONS: `${DOMAIN}/promos/connections/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/promos/categories/hierarchy/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  promoTabsStorageKey,
  promoEntityStorageKey,
  promoRulesEntityStorageKey,
  newPromo,
  newPromoRule,
  catalogTab,
  registerTab,
  storageKeys,
  hierarchyTableProps,
  registerTableProps,
};
