var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ao-popup",
    {
      staticClass: "config-control-popup",
      attrs: {
        parent: _vm.parent,
        xPosition: "left",
        scrollableAreaSelector: _vm.scrollableAreaSelector
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", { staticClass: "header-row" }, [
                _c(
                  "h2",
                  { staticClass: "header-row__title" },
                  [_c("l10n", { attrs: { value: "Фильтры и сортировка" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "header-row__buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "header-row__button add-button mini-button",
                      attrs: { type: "button", disabled: _vm.isConfigEmpty },
                      on: {
                        click: function($event) {
                          _vm.modalVisibility.saveConfig = true
                        }
                      }
                    },
                    [_c("plus-icon")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "header-row__button mini-button close-button",
                      attrs: { type: "button" },
                      on: { click: _vm.hideElement }
                    },
                    [_c("plus-icon")],
                    1
                  )
                ])
              ])
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "ao-button",
                {
                  staticClass: "reset-button",
                  attrs: { disabled: _vm.isConfigEmpty },
                  on: { click: _vm.confirmReset }
                },
                [_c("l10n", { attrs: { value: "Сбросить всё" } })],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      [
        _vm.configItems.length > 0
          ? _c("div", { staticClass: "saved-configs" }, [
              _c(
                "h3",
                { staticClass: "saved-configs__title" },
                [_c("l10n", { attrs: { value: "Сохранённые фильтры" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "saved-configs__list" },
                _vm._l(_vm.configItems, function(item) {
                  return _c(
                    "li",
                    { key: item.id, staticClass: "saved-configs__list-item" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "saved-configs__item-button",
                          class: { active: _vm.activeConfigId === item.id },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.selectConfig(item)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "check-icon" },
                            [_c("check-large-icon")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "saved-configs__item-text" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "saved-configs__remove-button mini-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onDeleteConfig(item)
                                }
                              }
                            },
                            [_c("trash-icon")],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("save-modal", {
          attrs: {
            modelName: _vm.modelName,
            tableConfigParams: _vm.tableConfigParams
          },
          on: { onConfigSuccessfulSaved: _vm.handleSuccessfulSave },
          model: {
            value: _vm.modalVisibility.saveConfig,
            callback: function($$v) {
              _vm.$set(_vm.modalVisibility, "saveConfig", $$v)
            },
            expression: "modalVisibility.saveConfig"
          }
        }),
        _vm._v(" "),
        _c("delete-modal", {
          attrs: { config: _vm.configForDelete, modelName: _vm.modelName },
          on: { onConfigSuccessfulDeleted: _vm.handleSuccessfulDelete },
          model: {
            value: _vm.modalVisibility.deleteConfig,
            callback: function($$v) {
              _vm.$set(_vm.modalVisibility, "deleteConfig", $$v)
            },
            expression: "modalVisibility.deleteConfig"
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }