var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M11.2222 5.75C8.2 5.75 5.75 8.2 5.75 11.2222C5.75 14.2444 8.2 16.6944 11.2222 16.6944C12.6973 16.6944 14.0361 16.1108 15.0202 15.1618C15.0403 15.1356 15.0623 15.1103 15.0863 15.0863C15.1103 15.0623 15.1356 15.0403 15.1618 15.0202C16.1108 14.0361 16.6944 12.6973 16.6944 11.2222C16.6944 8.2 14.2444 5.75 11.2222 5.75ZM16.6542 15.5936C17.6177 14.3978 18.1944 12.8774 18.1944 11.2222C18.1944 7.37157 15.0729 4.25 11.2222 4.25C7.37157 4.25 4.25 7.37157 4.25 11.2222C4.25 15.0729 7.37157 18.1944 11.2222 18.1944C12.8774 18.1944 14.3978 17.6177 15.5936 16.6542L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L16.6542 15.5936Z",
          fill: "#AFAFAF"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }