var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autoorder-modal", {
    staticClass: "config-delete-modal",
    attrs: {
      modalTitle: "Удалить сохранённый фильтр?",
      closable: false,
      modalVisible: _vm.isVisible
    },
    on: { onClose: _vm.handleDiscard },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function() {
          return [
            _c("p", { staticClass: "config-delete-modal__text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.configName) +
                  " будет удалён. Удалённый сохранённый фильтр нельзя будет восстановить.\n    "
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "config-delete-modal__buttons-row" },
              [
                _c(
                  "ao-button",
                  {
                    attrs: { type: "secondary", disabled: _vm.isLoading },
                    on: { click: _vm.handleDiscard }
                  },
                  [_c("l10n", { attrs: { value: "Отменить" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ao-button",
                  {
                    attrs: { disabled: _vm.isLoading },
                    on: { click: _vm.deleteConfig }
                  },
                  [_c("l10n", { attrs: { value: "Удалить" } })],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }