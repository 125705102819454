const { DOMAIN } = require('./endpoint');
const {
  getIdNameField,
  getLeafName,
  getLeafArticle,
  getLeafLocation,
  getLeafOpenDate,
  getLeafCloseDate,
} = require('../utils/tableColumnNameGetters');

// warehouses
const COMPONENT_NAMES = {
  catalog: 'WarehousesCatalog',
  warehouse: 'WarehouseCard',
  category: 'WarehouseCategoryCard',
};
const warehouseTabsStorageKey = 'warehouse-tabs';
const warehouseEntityStorageKey = 'warehouse-cards';
const warehouseCategoriesEntityStorageKey = 'warehouse-categories';
const newWarehouse = 'new-warehouse-card';
const newCategory = 'new-warehouse-category';
const catalogTab = { id: 'warehouse-catalog', title: 'Справочник объектов', componentName: COMPONENT_NAMES.catalog };

// storage keys
const storageKeys = {
  catalog: {
    visibleColumns: 'warehouse-catalog-visible-columns',
  },
  parentName: 'warehouses',
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID узла', getter: getIdNameField },
  { id: 'code', title: 'Код узла', getter: getLeafArticle },
  { id: 'name', title: 'Название узла', getter: getLeafName },
  { id: 'location', title: 'Местоположение GPS', getter: getLeafLocation },
  { id: 'open_at', title: 'Дата открытия', getter: getLeafOpenDate },
  { id: 'closed_at', title: 'Дата закрытия', getter: getLeafCloseDate },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/warehouses/categories/hierarchy/`,
  leafsField: 'warehouses',
};

// endpoints
const ENDPOINTS = {
  WAREHOUSES: `${DOMAIN}/warehouses/`,
  FEATURES: `${DOMAIN}/warehouses/features/`,
  FEATURE_NAMES: `${DOMAIN}/warehouses/feature_names/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/warehouses/categories/hierarchy/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  warehouseTabsStorageKey,
  warehouseEntityStorageKey,
  warehouseCategoriesEntityStorageKey,
  newWarehouse,
  newCategory,
  catalogTab,
  storageKeys,
  hierarchyTableProps,
};
