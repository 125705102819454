<template>
  <div class="products" id="products">
    <header class="products__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="products__main">
      <keep-alive v-if="currentComponent" :include="['products-catalog']">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />

    <category-remove-modal
      v-model="categoryRemoveModalVisible"
      @onConfirm="categoryRemoveConfirm"
      @onDiscard="categoryForRemoveId = null"
      class="category-remove-modal"
    />
  </div>
</template>

<script>
const { DOMAIN } = require('../../constants/endpoint');
const {
  COMPONENT_NAMES,
  productTabsStorageKey,
  productEntityStorageKey,
  productCategoriesEntityStorageKey,
  newProduct,
  newCategory,
  catalogTab,
} = require('../../constants/products');
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const {
  getExistTabCounts, getCardCounter, sortTabsByCounter, isTabExist, removeTab,
} = require('../../utils/cardTabsHelpers');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const ProductCard = (resolve) => {
  require(['./product-card.vue'], resolve);
};
const ProductCategoryCard = (resolve) => {
  require(['./product-category-card.vue'], resolve);
};
const ProductsCatalog = (resolve) => {
  require(['./products-catalog.vue'], resolve);
};
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
const CategoryRemoveModal = (resolve) => {
  require(['../../components/custom-modals/ao-category-remove-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  ProductsCatalog,
  ProductCard,
  AoTabs,
  CloseTabModal,
  ProductCategoryCard,
  CategoryRemoveModal,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      reactiveProvidedProductData: this.reactiveData, // https://ru.vuejs.org/v2/api/#provide-inject

      productCard: {
        entityStorageKey: productEntityStorageKey,
        openCatalog: this.openCatalog,
        createNew: this.createNewProductCard,
        duplicate: this.duplicateProductCard,
        openExist: this.openExistCard,
        onSuccessfulCreate: this.onSuccessfulCreateProduct,
        onCatalogReloaded: this.onCatalogReloaded,
      },

      productCategory: {
        newPrefix: newCategory,
        entityStorageKey: productCategoriesEntityStorageKey,
        createNew: this.createNewCategory,
        remove: this.categoryRemoveInit,
        openExist: this.openExistCategory,
        onSuccessfulCreate: this.onSuccessfulCreateCategory,
      },
    };
  },

  data() {
    return {
      tabs: [catalogTab],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
        needToRefetchCategories: false,
        duplicatedProductState: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
      categoryForRemoveId: null,
      categoryRemoveModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    productTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.product)
        .map(({ id }) => id);
    },
    categoryTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.category)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: productTabsStorageKey, tabs: newTabs });

      clearCardStatesCache({
        entity: productEntityStorageKey,
        tabIds: this.productTabIds,
      });

      clearCardStatesCache({
        entity: productCategoriesEntityStorageKey,
        tabIds: this.categoryTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(productTabsStorageKey);
  },

  methods: {
    // remove categories
    async categoryRemoveConfirm() {
      if (!this.categoryForRemoveId) {
        return;
      }

      const URL = `${DOMAIN}/products/categories/${this.categoryForRemoveId}/`;
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      };

      try {
        const response = await fetch(URL, options);

        if (response.ok) {
          this.createNotification(`Категория id-${this.categoryForRemoveId} успешна удалена`, 'success');
          this.categoryRemoveModalVisible = false;
          this.reactiveData.needToRefetchCategories = true;
        } else {
          this.createNotification(`Ошибка при удалении категории id-${this.categoryForRemoveId}`, 'error');
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
      }
    },
    categoryRemoveInit(id) {
      this.categoryRemoveModalVisible = true;
      this.categoryForRemoveId = id;
    },

    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.product:
            this.openCatalog();
            break;

          default:
            this.openCatalog();
            break;
        }
      }
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },

    // open static component tab
    openCatalog() {
      this.selectTab(catalogTab);
    },

    // open dynamic component tab
    openProductCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка продукта - ${id}`,
        componentName: COMPONENT_NAMES.product,
      };

      this.selectOrAddTab(newTab);
    },
    openExistCard(id) {
      this.openProductCard({ id });
    },
    openCategoryCard({ id, title }) {
      const newTab = {
        id,
        title: title || `Карточка категории - ${id}`,
        componentName: COMPONENT_NAMES.category,
      };

      this.selectOrAddTab(newTab);
    },
    openExistCategory(id) {
      this.openCategoryCard({ id });
    },

    // create new cards
    createNewProductCard() {
      const counts = getExistTabCounts(newProduct, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newProduct}-${counter}`,
        title: `Новая карточка - ${counter}`,
        isNew: true,
      };

      this.openProductCard(tabBody);
    },
    createNewCategory() {
      const counts = getExistTabCounts(newCategory, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newCategory}-${counter}`,
        title: `Новая категория - ${counter}`,
      };

      this.openCategoryCard(tabBody);
    },

    // duplicate cards
    duplicateProductCard(cardState) {
      this.reactiveData.duplicatedProductState = cardState;
      this.createNewProductCard();
    },

    // successful create card handle
    onSuccessfulCreateProduct(id) {
      if (!id) {
        return;
      }

      const productCard = { id, componentName: COMPONENT_NAMES.product, title: `Карточка продукта - ${id}` };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? productCard : card));
      this.selectTab(productCard);
    },
    onSuccessfulCreateCategory(id) {
      if (!id) {
        return;
      }

      this.selectTab(catalogTab);
      this.cardTabRemove({ id, componentName: COMPONENT_NAMES.category });
      this.reactiveData.needToRefetchCategories = true;
    },
    onCatalogReloaded() {
      this.reactiveData.needToRefetchCategories = false;
    },
  },
};
</script>

<style  scoped>
.products {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow: auto;
  padding: 12px;
}

.products__main {
  flex: 1 0 auto;
}

.category-remove-modal>>>.custom-modal {
  max-width: 420px;
}
</style>
