const { DOMAIN } = require('./endpoint');
const {
  getProductName,
  getProductCategoryName,
  getWarehouseName,
  getWarehouseCategoryName,
  getAuthorName,
  getStartAt,
  getEndAt,
  getCreatedAt,
  getPolicyName,
  getIdNameField,
  getLeafCreatedAt,
  getLeafAuthor,
  getLeafPolicyName,
} = require('../utils/tableColumnNameGetters');

// policies
const COMPONENT_NAMES = {
  catalog: 'PoliciesCatalog',
  register: 'PoliciesRegister',
  policy: 'PolicyCard',
  rules: 'PolicyRulesCard',
};
const policyTabsStorageKey = 'policy-tabs';
const policyEntityStorageKey = 'policy-cards';
const policyRulesEntityStorageKey = 'policy-rules-cards';
const newPolicy = 'new-policy-card';
const newPolicyRule = 'new-policy-rule';

const catalogTab = { id: 'policy-catalog', title: 'Справочник политик', componentName: COMPONENT_NAMES.catalog };
const registerTab = { id: 'policy-register', title: 'Реестр привязок политик', componentName: COMPONENT_NAMES.register };

// storage keys
const storageKeys = {
  parentName: 'policies',
  register: {
    visibleColumnsKey: 'policies-register-visible-cols',
    columnsSizeKey: 'policies-register-columns-size',
  },
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID политики', getter: getIdNameField },
  { id: 'name', title: 'Наименование политики', getter: getLeafPolicyName },
  { id: 'author', title: 'Автор', getter: getLeafAuthor },
  { id: 'createdAt', title: 'Дата создания', getter: getLeafCreatedAt },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/policies/categories/hierarchy/`,
  leafsField: 'policies',
};

// register
const registerColumns = [
  {
    id: 'id',
    title: 'ID связи',
    field: 'id',
    config: {
      sorting: true,
      filterType: 'numeric',
    },
  },
  {
    id: 'product_name',
    title: 'Товар',
    field: getProductName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'product_category_name',
    title: 'Категория товара',
    field: getProductCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_name',
    title: 'Узел',
    field: getWarehouseName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_category_name',
    title: 'Категория узла',
    field: getWarehouseCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'start_at',
    title: 'Дата действия с',
    field: getStartAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'end_at',
    title: 'Дата действия до',
    field: getEndAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'description',
    title: 'Описание',
    field: 'description',
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'created_at',
    title: 'Дата создания',
    field: getCreatedAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'author',
    title: 'Автор',
    field: getAuthorName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'policy_name',
    title: 'Политика',
    field: getPolicyName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  endpoint: `${DOMAIN}/policies/connections/filtered/`,
  configEndpoint: `${DOMAIN}/policies/connections/filtered/config/`,
};

// endpoints
const ENDPOINTS = {
  POLICIES: `${DOMAIN}/policies/`,
  CONNECTIONS: `${DOMAIN}/policies/connections/`,
  FEATURES: `${DOMAIN}/policies/features/`,
  FEATURE_NAMES: `${DOMAIN}/policies/feature_names/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/policies/categories/hierarchy/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  policyTabsStorageKey,
  policyEntityStorageKey,
  policyRulesEntityStorageKey,
  newPolicy,
  newPolicyRule,
  catalogTab,
  registerTab,
  storageKeys,
  hierarchyTableProps,
  registerTableProps,
};
