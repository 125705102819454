<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="parameters-calculation-container">
    <div class="filters">
      <div class="inputblock">
        <label class="inputblock__label" for="parameters">Наименование параметров</label>
        <my-multiselect
          id="parameters"
          :options="parameters"
          :selectedOption="parameters[0]"
          placeholder="Выберите параметр"
        />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw ml-auto"><polyline points="23 4 23 10 17 10" /><polyline points="1 20 1 14 7 14" /><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" /></svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" /><polyline points="17 21 17 13 7 13 7 21" /><polyline points="7 3 7 8 15 8" /></svg>
    </div>
    <div class="parameters-calculation-table">
      <table class="ao-table">
        <thead>
          <tr>
            <td
              v-for="column in tableColumns"
              :key="column.id"
              class="ao-table__cell head-cell"
            >
              <div class="head-cell__container">
                <a
                  href="#"
                  @click.prevent
                  class="ao-table__head-title"
                >
                  {{ column.title }}
                </a>
                <div
                  v-if="column.extraColumns"
                  class="ao-table-extra-column-container">
                  <div
                    class="ao-table__extra-column">
                    <div
                      v-for="extraColumn in column.extraColumns"
                      :key="extraColumn.id"
                      href="#"
                      @click.prevent
                      class="ao-table__extra-head-title"
                    >
                      {{ extraColumn.title || '' }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="ao-table__row">
            <td class="ao-table__cell">
              <table style="width: 100%;">
                <tr
                  id="tr-without-borders"
                  v-for="row in locationRows"
                  :key="row.id"
                  :style="{ 'padding-left': `${row.level * 16}px`, display: 'flex' }"
                  :class="{ 'hierarchy-table__row_leaf': row.nodeType === 'leaf' }"
                >
                  <td class="node-button">
                    <node-button
                      v-if="row.nodeType === 'node'"
                      :isOpen="row.isOpen"
                    />
                  </td>
                  <td>
                    {{ row.value }}
                  </td>
                  <td class="ml-auto">
                    <gp-check
                      :id="`location-checkbox-${row.id}`"
                      :key="`location-checkbox-${row.id}`"
                      :checked="false"
                    />
                  </td>
                </tr>
              </table>
            </td>
            <td class="ao-table__cell">
              <table style="width: 100%;">
                <tr
                  id="tr-without-borders"
                  v-for="row in dfuRows"
                  :key="row.id"
                  :style="{ 'padding-left': `${row.level * 16}px`, display: 'flex' }"
                  :class="{ 'hierarchy-table__row_leaf': row.nodeType === 'leaf' }"
                >
                  <td class="node-button">
                    <node-button
                      v-if="row.nodeType === 'node'"
                      :isOpen="row.isOpen"
                    />
                  </td>
                  <td>
                    {{ row.value }}
                  </td>
                  <td class="ml-auto">
                    <gp-check
                      :id="`dfu-checkbox-${row.id}`"
                      :key="`dfu-checkbox-${row.id}`"
                      :checked="false"
                    />
                  </td>
                </tr>
              </table>
            </td>
            <td class="ao-table__cell">
              <div
                class="radio-level-row"
                v-for="radio in radioLevelItems"
                :key="radio.id">
                <gp-check
                  type="radio"
                  name="level"
                  :checked="selectedLevel === radio.name"
                  @click="selectedLevel === radio.name"
                >
                  {{ radio.value }}
                </gp-check>
              </div>
            </td>
            <td class="ao-table__cell">
              <div class="date-row">
                <input
                  v-model="date1"
                  class="inputblock__input input"
                  id="date1"
                  placeholder="Дата начала"
                  type="date"
                />
                <input
                  v-model="date2"
                  class="inputblock__input input"
                  id="date2"
                  placeholder="Дата окончания"
                  type="date"
                />
              </div>
              <div class="date-row">
                <input
                  v-model="date3"
                  class="inputblock__input input"
                  id="date3"
                  placeholder="Дата начала"
                  type="date"
                />
                <input
                  v-model="date4"
                  class="inputblock__input input"
                  id="date4"
                  placeholder="Дата окончания"
                  type="date"
                />
              </div>
              <div class="date-row wihout-calendar">
                <input
                  v-model="date5"
                  class="inputblock__input input"
                  id="date5"
                  placeholder="Выберите период"
                  type="date"
                />
                <div class="input-group-addon">—</div>
                <input
                  v-model="date6"
                  class="inputblock__input input"
                  id="date6"
                  placeholder="Выберите период"
                  type="date"
                />
              </div>
            </td>
            <td class="ao-table__cell">
              <div
                class="checkbox-row"
                v-for="checkbox in chechboxes"
                :key="checkbox.id"
              >
                <gp-check
                  :checked="checkbox.checked"
                  @change="checkbox.checked = !checkbox.checked"
                >
                  <l10n :value="checkbox.name" />
                </gp-check>
              </div>
            </td>
            <td class="ao-table__cell">
              <div
                class="radio-algorithm-row"
                v-for="radio in radioAlgorithmItems"
                :key="radio.id">
                <gp-check
                  type="radio"
                  name="algorithm"
                  :checked="selectedAlgorithm === radio.name"
                  @click="selectedAlgorithm === radio.name"
                >
                  {{ radio.value }}
                </gp-check>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const MyMultiSelect = require('../my-multiselect.vue').default;
const NodeButton = require('./components/shared/ao-node-button.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
    'node-button': NodeButton,
  },

  data() {
    return {
      parameters: [
        { id: 1, name: 'Недельная динамика' },
        { id: 2, name: 'Месячная динамика' },
        { id: 3, name: 'Коэффициент Промо' },
        { id: 4, name: 'Коэффициент Новый ТТ' },
        { id: 5, name: 'Коэффициент новый ТП' },
        { id: 6, name: 'Коэффициент Открытие конкурента' },
        { id: 7, name: 'Коэффициент каннибализации' },
        { id: 8, name: 'Коэффициент Закрытия конкурента' },
        { id: 10, name: 'Коэффициент Вход в праздники' },
        { id: 11, name: 'Коэффициент Выход из праздников' },
        { id: 12, name: 'Коэффициент Вход в сезон' },
        { id: 13, name: 'Коэффициент Выход из сезона' },
        { id: 14, name: 'Эластичность' },
      ],
      tableColumns: [
        { id: 1, title: 'Уровень расчета Location' },
        { id: 2, title: 'Уровень расчета DFU' },
        { id: 3, title: 'Уровень расчета параметра' },
        { id: 4, title: 'Период выборки данных', extraColumns: [{ id: 1, title: 'Дата начала' }, { id: 2, title: 'Дата окончания' }] },
        { id: 5, title: 'Методы очистки данных' },
        { id: 6, title: 'Алгоритм усреднения' },
      ],
      locationRows: [
        {
          id: 1,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Ассортимент',
        },
        {
          id: 2,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Ширина ассортимента',
        },
        {
          id: 3,
          level: 1,
          nodeType: 'leaf',
          value: '2000',
        },
        {
          id: 4,
          level: 1,
          nodeType: 'leaf',
          value: '3000',
        },
        {
          id: 5,
          level: 1,
          nodeType: 'leaf',
          value: '4000',
        },
        {
          id: 6,
          level: 1,
          nodeType: 'leaf',
          value: '5000',
        },
        {
          id: 7,
          level: 1,
          nodeType: 'leaf',
          value: '6000',
        },
        {
          id: 8,
          level: 1,
          nodeType: 'leaf',
          value: 'выше 6000',
        },
        {
          id: 9,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'География',
        },
        {
          id: 10,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Москва',
        },
        {
          id: 11,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Регионы',
        },
        {
          id: 12,
          level: 2,
          nodeType: 'node',
          isOpen: false,
          value: 'Малые форматы',
        },
        {
          id: 13,
          level: 2,
          nodeType: 'node',
          isOpen: true,
          value: 'Дон',
        },
        {
          id: 14,
          level: 2,
          nodeType: 'leaf',
          value: 'Ростов-на-Дону',
        },
        {
          id: 15,
          level: 2,
          nodeType: 'leaf',
          value: 'Воронеж',
        },
        {
          id: 16,
          level: 2,
          nodeType: 'leaf',
          value: 'Самара',
        },
        {
          id: 17,
          level: 2,
          nodeType: 'leaf',
          value: 'Волгоград',
        },
        {
          id: 18,
          level: 2,
          nodeType: 'node',
          isOpen: false,
          value: 'Юг',
        },
        {
          id: 19,
          level: 2,
          nodeType: 'node',
          isOpen: false,
          value: 'Урал',
        },
        {
          id: 20,
          level: 2,
          nodeType: 'node',
          isOpen: false,
          value: 'Спб + НН',
        },
        {
          id: 21,
          level: 2,
          nodeType: 'node',
          isOpen: false,
          value: 'Поволжье',
        },
        {
          id: 22,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Жизненный цикл',
        },
        {
          id: 23,
          level: 0,
          nodeType: 'leaf',
          value: 'Закрылся конкурент',
        },
        {
          id: 24,
          level: 0,
          nodeType: 'leaf',
          value: 'Зрелый',
        },
        {
          id: 25,
          level: 0,
          nodeType: 'leaf',
          value: 'Новый',
        },
        {
          id: 26,
          level: 0,
          nodeType: 'leaf',
          value: 'Открылся конкурент',
        },
        {
          id: 27,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Конкуренты',
        },
        {
          id: 28,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Доставка',
        },
        {
          id: 29,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Косвенный',
        },
        {
          id: 30,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Прямой',
        },
        {
          id: 31,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Расстояние до конкурента',
        },
        {
          id: 32,
          level: 1,
          nodeType: 'leaf',
          value: 'до 1000 метров',
        },
        {
          id: 33,
          level: 1,
          nodeType: 'leaf',
          value: 'до 2000 метров',
        },
        {
          id: 34,
          level: 1,
          nodeType: 'leaf',
          value: 'до 5000 метров',
        },
        {
          id: 35,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Специализированный',
        },
        {
          id: 36,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Расположение',
        },
        {
          id: 37,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Окружение',
        },
        {
          id: 38,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Размер населенного пункта',
        },
        {
          id: 39,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Соседство',
        },
        {
          id: 40,
          level: 1,
          nodeType: 'leaf',
          value: 'БЦ',
        },
        {
          id: 41,
          level: 1,
          nodeType: 'leaf',
          value: 'Классический ТЦ',
        },
        {
          id: 42,
          level: 1,
          nodeType: 'leaf',
          value: 'Специализированный ТЦ',
        },
        {
          id: 43,
          level: 1,
          nodeType: 'leaf',
          value: 'Отдельное здание',
        },
        {
          id: 44,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Доступность',
        },
        {
          id: 45,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Остановка транспорта',
        },
        {
          id: 46,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Трафик',
        },
        {
          id: 47,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Миграция трафика',
        },
        {
          id: 48,
          level: 1,
          nodeType: 'leaf',
          value: 'Дачный',
        },
        {
          id: 49,
          level: 1,
          nodeType: 'leaf',
          value: 'Морской',
        },
        {
          id: 50,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Среднедневной',
        },
        {
          id: 51,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Структура трафика по ЦС',
        },
        {
          id: 52,
          level: 1,
          nodeType: 'leaf',
          value: 'Высокий',
        },
        {
          id: 53,
          level: 1,
          nodeType: 'leaf',
          value: 'Низкий',
        },
        {
          id: 54,
          level: 1,
          nodeType: 'leaf',
          value: 'Средний',
        },
        {
          id: 55,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Формат',
        },
        {
          id: 56,
          level: 0,
          nodeType: 'leaf',
          value: 'Гипермаркет',
        },
        {
          id: 57,
          level: 0,
          nodeType: 'leaf',
          value: 'Хоум',
        },
        {
          id: 58,
          level: 0,
          nodeType: 'leaf',
          value: 'Мини',
        },
        {
          id: 59,
          level: 0,
          nodeType: 'leaf',
          value: 'ЦО',
        },
        {
          id: 60,
          level: 0,
          nodeType: 'leaf',
          value: 'Пункт выдачи',
        },
        {
          id: 61,
          level: 0,
          nodeType: 'leaf',
          value: 'Интернет магазин',
        },
        {
          id: 62,
          level: 0,
          nodeType: 'leaf',
          value: 'Компактный гипермаркет',
        },
        {
          id: 63,
          level: 0,
          nodeType: 'leaf',
          value: 'Малый гипермаркет',
        },
        {
          id: 64,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Каналы продаж',
        },
        {
          id: 65,
          level: 0,
          nodeType: 'leaf',
          value: 'ЦО',
        },
        {
          id: 66,
          level: 0,
          nodeType: 'leaf',
          value: 'Розница',
        },
        {
          id: 67,
          level: 0,
          nodeType: 'leaf',
          value: 'ИМ',
        },
      ],
      dfuRows: [
        {
          id: 1,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Бренд',
        },
        {
          id: 2,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'SAMURA',
        },
        {
          id: 3,
          level: 1,
          nodeType: 'leaf',
          value: 'SEGUN',
        },
        {
          id: 4,
          level: 1,
          nodeType: 'leaf',
          value: 'Bamboo',
        },
        {
          id: 5,
          level: 1,
          nodeType: 'leaf',
          value: 'PRO-S',
        },
        {
          id: 6,
          level: 1,
          nodeType: 'leaf',
          value: 'Harakiri',
        },
        {
          id: 7,
          level: 1,
          nodeType: 'leaf',
          value: 'SHADOW',
        },
        {
          id: 8,
          level: 1,
          nodeType: 'leaf',
          value: 'OKINAWA',
        },
        {
          id: 9,
          level: 1,
          nodeType: 'leaf',
          value: 'DAMASCUS',
        },
        {
          id: 10,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Место продажи',
        },
        {
          id: 11,
          level: 0,
          nodeType: 'leaf',
          value: 'ДМП',
        },
        {
          id: 12,
          level: 0,
          nodeType: 'leaf',
          value: 'Прикасса',
        },
        {
          id: 13,
          level: 0,
          nodeType: 'leaf',
          value: 'Промо оформление',
        },
        {
          id: 14,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Оборачиваемость',
        },
        {
          id: 15,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'ABC',
        },
        {
          id: 16,
          level: 1,
          nodeType: 'leaf',
          value: 'A',
        },
        {
          id: 17,
          level: 1,
          nodeType: 'leaf',
          value: 'B',
        },
        {
          id: 18,
          level: 1,
          nodeType: 'leaf',
          value: 'C',
        },
        {
          id: 19,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'XYZ',
        },
        {
          id: 20,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Скорость продажи',
        },
        {
          id: 21,
          level: 0,
          nodeType: 'leaf',
          value: 'EDLP',
        },
        {
          id: 22,
          level: 0,
          nodeType: 'leaf',
          value: 'Markdown',
        },
        {
          id: 23,
          level: 0,
          nodeType: 'leaf',
          value: 'PROMO',
        },
        {
          id: 24,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Фасеты',
        },
        {
          id: 25,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Литраж',
        },
        {
          id: 26,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Фасовка',
        },
        {
          id: 27,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'Цвет',
        },
        {
          id: 28,
          level: 1,
          nodeType: 'leaf',
          value: 'Черный',
        },
        {
          id: 29,
          level: 1,
          nodeType: 'leaf',
          value: 'Орех',
        },
        {
          id: 30,
          level: 1,
          nodeType: 'leaf',
          value: 'Белый',
        },
        {
          id: 31,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Ценовой сегмент',
        },
        {
          id: 32,
          level: 0,
          nodeType: 'leaf',
          value: '1',
        },
        {
          id: 33,
          level: 0,
          nodeType: 'leaf',
          value: '2',
        },
        {
          id: 34,
          level: 0,
          nodeType: 'leaf',
          value: '3',
        },
        {
          id: 35,
          level: 0,
          nodeType: 'leaf',
          value: '4',
        },
        {
          id: 36,
          level: 0,
          nodeType: 'leaf',
          value: '5',
        },
        {
          id: 37,
          level: 0,
          nodeType: 'leaf',
          value: '6',
        },
        {
          id: 38,
          level: 0,
          nodeType: 'node',
          isOpen: true,
          value: 'Категория',
        },
        {
          id: 39,
          level: 1,
          nodeType: 'node',
          isOpen: false,
          value: 'Мебель',
        },
        {
          id: 40,
          level: 1,
          nodeType: 'node',
          isOpen: true,
          value: 'ТДД',
        },
        {
          id: 41,
          level: 2,
          nodeType: 'node',
          isOpen: true,
          value: 'Посуда',
        },
        {
          id: 42,
          level: 2,
          nodeType: 'leaf',
          value: 'Ножи',
        },
      ],
      selectedLevel: 'n_day_holiday',
      radioLevelItems: [
        { id: 1, name: 'week_day', value: 'День недели' },
        { id: 2, name: 'n_day_promo', value: 'N дня промо' },
        { id: 3, name: 'n_day_season', value: 'N дня сезона' },
        { id: 4, name: 'n_day_holiday', value: 'N дня праздника' },
        { id: 5, name: 'n_day_week', value: 'N дня недели' },
        { id: 6, name: 'percent', value: '% снижения цены' },
        { id: 7, name: 'n_day_open_con', value: 'N дня после открытия конкурента' },
        { id: 8, name: 'n_day_open_tt', value: 'N дня после открытия ТТ' },
        { id: 9, name: 'n_week_open_con', value: 'N недели после открытия ТТ' },
      ],
      date1: '2023-01-01',
      date2: '2023-12-12',
      date3: '2024-02-24',
      date4: '2024-03-13',
      date5: '2023-12-12',
      date6: '2024-02-01',
      chechboxes: [
        { id: 1, name: 'Сезонность', checked: false },
        { id: 2, name: 'Праздники', checked: false },
        { id: 3, name: 'Промо', checked: true },
        { id: 4, name: 'Markdown', checked: false },
        { id: 5, name: 'Эластичность', checked: true },
        { id: 6, name: 'Обнуления', checked: false },
        { id: 7, name: 'Виртуальные остатки', checked: false },
        { id: 8, name: 'Только зрелые ТТ', checked: false },
      ],
      selectedAlgorithm: 'sliding_without',
      radioAlgorithmItems: [
        { id: 1, name: 'mid', value: 'Средняя' },
        { id: 2, name: 'mid_finish', value: 'Среднезавершенная' },
        { id: 3, name: 'sliding_with', value: 'Скользящая средняя с трендом' },
        { id: 4, name: 'sliding_without', value: 'Скользящая средняя без тренда' },
        { id: 5, name: 'custom_1', value: 'Кастомная логика 1' },
        { id: 6, name: 'custom_2', value: 'Кастомная логика 2' },
      ],
    };
  },
};
</script>

<style scoped>
.parameters-calculation-container {
  padding: 20px;
}

.inputblock {
  width: 340px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}

.ao-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  height: 100%;
}

.ao-table__head-title,
.ao-table__extra-head-title {
  color: black;
}

.ao-table__head-title:hover {
  text-decoration: none;
}

.ao-table tbody {
  display: block;
  flex-grow: 1;
  overflow-y: auto;
}

.ao-table thead,
.ao-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.ao-table__row {
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.ao-table__row:hover {
  background-color: white;
  cursor: unset;
}

.ao-table tbody tr {
  border: 1px solid #E9E9E9;
  border-bottom: none;
}

.ao-table__cell.head-cell {
  vertical-align: middle;
}

.ao-table__cell {
  padding: 8px;
  vertical-align: top;
}

.ao-table-extra-column-container {
  width: 100%;
}

.ao-table__extra-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.node-button {
  width: 25px;
}

.ao-table tbody tr:last-of-type {
  border-bottom: 1px solid #E9E9E9;
}

.date-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.input[type='date'],
.input[type='date']:hover,
.input[type='date']:active,
.input[type='date']:focus {
  border: none;
  outline: none;
}

.wihout-calendar input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.input-group-addon {
  margin-left: -40px;
  text-align: center;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: #c1d6ec;
}

.checkbox-row,
.radio-level-row,
.radio-algorithm-row {
  margin-bottom: 5px;
}

#tr-without-borders {
  padding-bottom: 3px;
  border: none;
}

#tr-without-borders:hover {
  background-color: #CCE5FF;
}

.hierarchy-table__row_leaf {
  background-color: #edf7fc;
}

.ao-table__row .ao-table__cell:not(:last-of-type) {
  border-right: 1px solid #E9E9E9;
}

.ao-table tbody {
  display: block;
  flex-grow: 1;
  overflow-y: auto;
}

.ao-table thead,
.ao-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.ao-table tbody tr {
  border: 1px solid #E9E9E9;
  border-bottom: none;
}

.ao-table tbody tr:first-of-type {
  border-top: none;
}

.ao-table tbody tr:last-of-type {
  border-bottom: 1px solid #E9E9E9;
}

::-webkit-scrollbar {
   width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E9E9E9;
  border-radius: 10px;
  background-clip: content-box;
  border: 2px solid transparent;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5C606F;
}

.head-cell {
  background-color: #F7F7F7;
  border: 1px solid #E9E9E9;
  outline: none;
}

.head-cell__container {
  min-height: 33px;
}

.ao-table__cell {
  padding: 8px;
}

.ao-table__cell:first-child {
  min-width: 250px;
}
.filters {
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin-bottom: 10px;
}
.filters svg {
  cursor: pointer;
}
.ml-auto {
  margin-left: auto;
}
</style>
