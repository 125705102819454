var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "ao-radio" }, [
    _c("input", {
      staticClass: "ao-radio__input",
      attrs: {
        type: "radio",
        id: _vm.id,
        disabled: _vm.disabled,
        name: _vm.name
      },
      domProps: { value: _vm.value, checked: _vm.checked },
      on: { change: _vm.onChange }
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "ao-radio__checkmark",
      class: { disabled: _vm.disabled }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }