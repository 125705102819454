var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autoorder-modal", {
    attrs: {
      modalVisible: _vm.isVisible,
      modalTitle: _vm.headerTitle,
      minWidth: "45%",
      closable: "",
      closeButton: "",
      datasets: _vm.datasets
    },
    on: { onClose: _vm.handleDiscard },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function() {
          return [_c("chart-settings", { attrs: { datasets: _vm.datasets } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }