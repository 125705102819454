<template>
  <div class="gp-paths" v-if="data">
    <div class="form-group">
      <my-search v-model="searchString"/>
    </div>
    <template v-for="stream, name in visibleStreams">
      <p>
        <label>
          <a href="javascript:void(0)" @click="$set(opened, name, !opened[name])">
            <feather-icon :name="opened[name] ? 'folder-minus' : 'folder-plus'"/>
            <l10n :value="name"/>
          </a>
        </label>
        <span class="gp-paths-stream-info">
          {{formatSize(stream.size)}} <l10n value="records"/>
          <!--a href="javascript:void(0)" @click="restartStream(name)">
                        <l10n value="restart"/>
                    </a-->
          <a href="javascript:void(0)" @click="browseStream(name)">
            <l10n value="browse"/>
          </a>
        </span>

        <ul v-if="opened[name]">
          <!-- <template v-for="spout in stream.spouts"> -->
          <template v-for="group, groupKey in stream.groups">
            <template v-if="group.length != 1">
              <li class="gp-paths-group-head">
                <a href="javascript:void(0)" @click="$set(opened, groupKey, !opened[groupKey])">
                  <feather-icon :name="opened[groupKey] ? 'folder-minus' : 'folder-plus'"/>
                  <span class="gp-paths-date">{{extractDate(group[0])}}</span>
                  {{groupKey}}
                  <l10n
                    v-if="stream.loadedPathsRows[groupKey] !== undefined"
                    value="{rows} rows"
                    class="gp-paths-rows"
                    :rows="new Number(stream.loadedPathsRows[groupKey]).toLocaleString()"/>
                </a>
                <a
                  href="javascript:void(0)"
                  @click="includePaths(name, group)">
                  <l10n value="include"/>
                </a>
                <a
                  href="javascript:void(0)"
                  @click="excludePaths(name, group)">
                  <l10n value="exclude"/>
                </a>
                <a href="javascript:void(0)" @click="browseStream(name, [{'__file__': group}])">
                  <l10n value="browse"/>
                </a>
              </li>
            </template>
            <template v-if="group.length == 1 || opened[groupKey]">
              <li v-for="path in group" :class="{'gp-paths-group-item': group.length != 1}">
                <template v-if="stream.excludedPaths.indexOf(path) !== -1">
                  <span style="text-decoration: line-through" class="text-muted">
                    <span class="gp-paths-date">{{extractDate(path)}}</span>
                    {{path}}</span>
                  <a
                    href="javascript:void(0)"
                    @click="includePaths(name, [path])">
                    <l10n value="include"/>
                  </a>
                  <a
                    href="javascript:void(0)"
                    @click="deletePaths(name, [path])">
                    <l10n value="delete"/>
                  </a>
                </template>
                <template v-else-if="stream.pendingPaths.indexOf(path) !== -1">
                  <i class="text-muted">
                    <span class="gp-paths-date">{{extractDate(path)}}</span>
                    {{path}}
                    <l10n value="pending"/>
                  </i>
                </template>
                <template v-else-if="stream.skippedPaths.indexOf(path) !== -1">
                  <i class="text-muted">
                    <span class="gp-paths-date">{{extractDate(path)}}</span>
                    {{path}}
                    <l10n value="skipped"/>
                  </i>
                </template>
                <template v-else>
                  <span class="gp-paths-date">{{extractDate(path)}}</span>
                  <feather-icon name="alert-triangle" v-if="stream.loadedPathsRows[path] === 0"/>
                  {{path}}
                  <l10n v-if="stream.loadedPathsRows[path] !== undefined"
                        value="{rows} rows"
                        class="gp-paths-rows"
                        :rows="new Number(stream.loadedPathsRows[path]).toLocaleString()"/>
                  <a
                    href="javascript:void(0)"
                    @click="excludePaths(name, [path])">
                    <l10n value="exclude"/>
                  </a>
                  <a href="javascript:void(0)" @click="browseStream(name, [{'__file__': [path]}])">
                    <l10n value="browse"/>
                  </a>
                  <a v-if="stream.loadedPathsInfo.has(path)"
                     href="javascript:void(0)" @click="showLoadingLogs(name, [path])">
                    <l10n value="show logs"/>
                  </a>
                </template>
                <a href="javascript:void(0)" @click="downloadFile(name, path)">
                  <l10n value="download"/>
                </a>
              </li>
            </template>
          </template>
          <!-- </template> -->
        </ul>
      </p>
    </template>
    <table>
      <thead>
        <tr>
          <th/>
          <th v-for="stream in streams" v-if="hasSpouts(stream)">
            <span><l10n :value="stream"/></span>
          </th>
          <th/>
        </tr>
      </thead>
      <tbody>
        <tr v-for="date in dates" v-if="date !== 'null'">
          <th>{{date}}</th>
          <td v-for="stream in streams" v-if="hasSpouts(stream)">
            <feather-icon name="clock" v-if="stream == 'combined' && rebuilding[date]"/>
            <template v-else-if="getPaths(date, stream, 'loadedPaths').length > 0">
              <a href="javascript:void(0)" @click="browseStream(stream, [{'__file__': getPaths(date, stream, 'loadedPaths')}])">
                <feather-icon name="check" :title="getPaths(date, stream, 'loadedPaths').join('\n')"/>
                <feather-icon name="check" v-if="getPaths(date, stream, 'loadedPaths').length > 1"/>
              </a>
            </template>
            <feather-icon name="check"
                          v-else-if="getPaths(date, stream, 'skippedPaths').length > 0"
                          :title="getPaths(date, stream, 'skippedPaths').join('\n')"
                          class="gp-path-ignored"/>
          </td>
          <td>
            <a
              v-if="!rebuilding[date]"
              href="javascript:void(0)"
              @click="rebuildDate(date)">
              <l10n value="rebuild"/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <my-dialog
      v-if="browsingStream"
      :xlarge="true"
      :scrollable="true"
      :title="browsingStream"
      @close="
        browsingStream = null
        browsingFilter = null"
    >
      <gp-stream
        :stream="browsingStream"
        :filter="browsingFilter"/>
    </my-dialog>
    <my-dialog
      v-if="logsStream && logsPaths"
      :large="true"
      :scrollable="true"
      :title="logsStream"
      @close="
        logsStream = null
        logsPaths = null">
      <label v-for="path in logsPaths">{{path}}</label>
      <gp-loaded-path-info
        :stream="logsStream"
        :paths="logsPaths"/>
    </my-dialog>
  </div>
</template>
<script>
let utils = require('../my-utils');

module.exports = {
  props: {
    streams: { type: Array, default: () => [] },
    groups: { type: Array, default: () => [] },
    expanded: { type: Boolean },
    // data: { type: Object }
  },
  data() {
    let opened = {};
    if (this.expanded) {
      for (let stream of this.streams) {
        opened[stream] = true;
      }
    }
    return {
      _,
      l10n: utils.l10n,
      data: null,
      destroyed: false,
      opened,
      browsingStream: null,
      browsingFilter: null,
      rebuilding: {},
      updateId: null,
      logsStream: null,
      logsPaths: null,
      searchString: '',
    };
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  computed: {
    compiledGroups() {
      return _(this.groups)
        .map(group => {
          try {
            return new RegExp(group);
          } catch {}
        })
        .filter()
        .value();
    },
    datasetStreams() {
      return this.data ?
        _(this.data.dataset.streams)
          .toPairs()
          .map(([name, stream]) => {
            stream.loadedPaths = this.streamPaths(stream, 'loadedPaths');
            stream.pendingPaths = this.streamPaths(stream, 'pendingPaths');
            stream.skippedPaths = this.streamPaths(stream, 'skippedPaths');
            stream.excludedPaths = this.streamPaths(stream, 'excludedPaths');
            stream.loadedPathsInfo = new Set(_(stream.spouts).map('loadedPathsInfo').flatten().filter().map('path'));
            stream.loadedPathsRows =
                            _(stream.spouts)
                              .map('loadedPathsInfo')
                              .flatten()
                              .filter()
                              .map(({ path, rows }) => [path, rows])
                              .fromPairs()
                              .value();
            stream.paths = _([])
              .concat(stream.loadedPaths)
              .concat(stream.excludedPaths)
              .concat(stream.pendingPaths)
              .concat(stream.skippedPaths)
              .uniq()
              .orderBy([this.extractDate, _.identity], ['desc', 'asc'])
              .value();
            stream.groups = _(stream.paths)
              .groupBy(path => {
                for (let group of this.compiledGroups) {
                  let match = group.exec(path);
                  if (match && match.length > 1) {
                    return match.slice(1).join('-');
                  }
                }
                return path;
              })
              .value();
            for (let groupKey in stream.groups) {
              if (stream.loadedPathsRows[groupKey] === undefined) {
                stream.loadedPathsRows[groupKey] = _.sum(stream.groups[groupKey].map(path => stream.loadedPathsRows[path]));
              }
            }
            return [name, stream];
          })
          .fromPairs()
          .value() : [];
    },
    structured() {
      let structured = {};
      let families = ['loadedPaths', 'skippedPaths'];

      if (this.data) {
        _(this.data.dataset.streams)
          .toPairs()
          .forEach(([stream, { spouts }]) =>
            _.forEach(spouts, (spout) =>
              _.forEach(families, (family) => {
                for (let path of spout[family] || []) {
                  let date = this.extractDate(path);
                  let key = `${date}.${stream}.${family}`;
                  let paths = _.get(structured, key);
                  if (!paths) {
                    paths = [];
                    _.set(structured, key, paths);
                  }
                  paths.push(path);
                }
              })));
      }

      _(structured).values().forEach((streams) =>
        _(streams).values().forEach((families) =>
          _(families).values().forEach((paths) => {
            paths.sort();
            paths.reverse();
          })));

      return structured;
    },
    dates() {
      return _(this.structured).keys().sortBy().reverse().value();
    },
    searchRegex() {
      if (this.searchString != '') {
        try {
          return new RegExp(this.searchString, 'i');
        } catch (ex) {
        }
      }
      return null;
    },
    visibleStreams() {
      return this.searchString ? _(this.datasetStreams)
        .toPairs()
        .filter(([name, stream]) =>
          stream.paths.find(path =>
            this.searchRegex ? path.match(this.searchRegex) :
              path.includes(this.searchString)))
        .map(([name, stream]) =>
          [name, _.assign({}, stream, {
            paths: stream.paths.filter(path =>
              this.searchRegex ? path.match(this.searchRegex) :
                path.includes(this.searchString)),
            groups: _(stream.groups)
              .toPairs()
              .map(([groupKey, paths]) => [groupKey, paths.filter(path =>
                this.searchRegex ? path.match(this.searchRegex) :
                  path.includes(this.searchString))])
              .filter(([groupKey, paths]) => paths.length > 0)
              .fromPairs()
              .value(),
          })])
        .fromPairs()
        .value() : this.datasetStreams;
    },
  },
  methods: {
    showLoadingLogs(stream, paths) {
      this.logsStream = stream;
      this.logsPaths = paths;
    },
    streamPaths(stream, selector) {
      return _(stream.spouts).map(selector).flatten().uniq().filter().sortBy(this.extractDate).value();
    },
    extractDate(path) {
      let parts = undefined;
      parts = path.match(/.*?(\d{2})\.(\d{2})\.(\d{4}).*/);
      if (parts && parts[1] && parts[2] && parts[3]) {
        return `${parts[3]}-${parts[2]}-${parts[1]}`;
      }
      parts = path.match(/.*?(\d{4})[-]?(\d{2})[-]?(\d{2}).*/);
      if (parts && parts[1] && parts[2] && parts[3]) {
        return `${parts[1]}-${parts[2]}-${parts[3]}`;
      }
      return null;
    },
    async excludePaths(stream, paths) {
      if (window.confirm(
        utils.l10n('Are you sure you want to exclude {paths} from stream {stream}?')
          .replace('{paths}', paths.join(', '))
          .replace('{stream}', utils.l10n(stream)))) {
        for (let path of paths) {
          let query = `
                        mutation {
                            excludePath(
                                stream: ${utils.quote(stream)},
                                path: ${utils.quote(path)})
                        }`;
          await utils.fetchWithAjaxOpts({
            url: '/graphql?__excludePathFromStream__',
            method: 'POST',
            data: JSON.stringify({ query }),
            dataType: 'json',
            contentType: 'application/json',
          });
        }
        this.update(true);
      }
    },
    async deletePaths(stream, paths) {
      if (window.confirm(
        utils.l10n('Are you sure you want to delete {paths} from stream {stream}?')
          .replace('{paths}', paths.join(','))
          .replace('{stream}', utils.l10n(stream)))) {
        for (let path of paths) {
          let query = `
                        mutation {
                            deletePath(
                                stream: ${utils.quote(stream)},
                                path: ${utils.quote(path)})
                        }`;
          await utils.fetchWithAjaxOpts({
            url: '/graphql?__deletePathFromStream__',
            method: 'POST',
            data: JSON.stringify({ query }),
            dataType: 'json',
            contentType: 'application/json',
          });
        }
        this.update(true);
      }
    },
    async includePaths(stream, paths) {
      for (let path of paths) {
        let query = `
                    mutation {
                        includePath(
                            stream: ${utils.quote(stream)},
                            path: ${utils.quote(path)})
                    }`;
        await utils.fetchWithAjaxOpts({
          url: '/graphql?__includePathToStream__',
          method: 'POST',
          data: JSON.stringify({ query }),
          dataType: 'json',
          contentType: 'application/json',
        });
      }
      this.update(true);
    },
    hasSpouts(stream) {
      return this.data ? this.data.dataset.streams[stream].spouts.length > 0 : false;
    },
    getPaths(date, stream, family = 'loadedPaths') {
      let key = `${date}.${stream}.${family}`;
      return _.get(this.structured, key) || [];
    },
    formatSize(size) {
      return new Number(size).toLocaleString();
    },
    browseStream(stream, filter = null) {
      this.browsingStream = stream;
      this.browsingFilter = filter;
    },
    downloadFile(stream, path) {
      fetch('/download', {
        method: 'POST',
        body: JSON.stringify({ stream, path }),
      })
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = path;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    },
    async rebuildDate(date) {
      if (_.size(this.rebuilding) >= 10) {
        window.alert(utils.l10n(
          'Please wait for active jobs to complete'));
        return;
      }
      if (window.confirm(
        utils.l10n(
          'Are you sure you want to rebuild data for date {date}?')
          .replace('{date}', date))) {
        this.updateId = null;
        this.$set(this.rebuilding, date, true);
        try {
          await Promise
            .resolve($.ajax({
              url: '/combine',
              method: 'POST',
              data: JSON.stringify({ date }),
              dataType: 'json',
              contentType: 'application/json',
            }));
        } finally {
          this.$set(this.rebuilding, date, false);
          this.update(true);
        }
      }
    },
    async restartStream(stream) {
      if (window.confirm(utils.l10n('Are you sure you want to restart stream {stream}?').replace('{stream}', utils.l10n(stream)))) {
        let query = `mutation { restartStream(name:${utils.quote(stream)}) }`;
        await utils.fetchWithAjaxOpts({
          url: '/graphql?__restartStream__',
          method: 'POST',
          data: JSON.stringify({ query }),
          dataType: 'json',
          contentType: 'application/json',
        });
        this.update(true);
      }
    },
    update(adhoc) {
      let updateId = utils.randomId();
      this.updateId = updateId;
      let query = `
                query {
                    dataset {
                        streams {
                            ${this.streams.map((stream) => `
                                ${stream} {
                                    size
                                    spouts {
                                        ... on DelimitedFile {
                                            loadedPaths
                                            pendingPaths
                                            skippedPaths
                                            excludedPaths
                                            loadedPathsInfo { path rows }
                                        }
                                        ... on JSONFile {
                                            loadedPaths
                                            pendingPaths
                                            skippedPaths
                                            excludedPaths
                                            loadedPathsInfo { path rows }
                                        }
                                    }
                                }`)}
                        }
                    }
                }`;

      utils.fetchWithAjaxOpts({
        url: '/graphql?__updateGpPathsOnMounted__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      })
        .then(({ data }) => {
          if (this.updateId === updateId) {
            this.data = data;
          }
        })
        .finally(() => {
          if (!adhoc && !this.destroyed) {
            setTimeout(this.update, 1000);
          }
        });

      Promise
        .resolve($.ajax({ url: '/combine/jobs' }))
        .then(({ dates }) => {
          if (this.updateId === updateId) {
            this.rebuilding =
                            _(dates)
                              .map((date) => [date, true])
                              .fromPairs()
                              .value();
          }
        });
    },
  },
};
</script>
<style>
.gp-paths {
    font-size: 0.95em;
}
.gp-paths svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
}
.gp-paths table {
    /*font-size: 0.9em;*/
    margin-bottom: 10px;
}
.gp-paths table th {
    vertical-align: bottom;
    white-space: nowrap;
    font-weight: normal;
}
.gp-paths table th span {
    writing-mode: vertical-rl;
    text-orientation: sideways;
    transform: rotate(180deg);
}
.gp-paths table td .feather-icon svg {
    width: 18px;
    height: 18px;
}
.gp-paths table td .feather-icon-check svg {
    color: var(--green);
}
.gp-paths table td .feather-icon-check.gp-path-ignored svg {
    color: #aaa;
}
.gp-paths table td .feather-icon-clock svg {
    color: var(--pink);
}
.gp-paths table td .feather-icon-alert-triangle svg {
    color: var(--yellow);
}
.gp-paths table td a:not(:first-child) {
    margin-left: 4px;
}
.gp-paths p {
    margin: 0;
    padding: 0;
}
/*.gp-paths li a {
    opacity: 0.7;
}
*/
.gp-paths li a {
    margin-left: 6px;
}
.gp-paths {
    white-space: nowrap;
}
.gp-paths-date:not(:empty) {
    margin-right: 8px;
    /*font-style: italic;*/
    color: var(--gray);
}
.gp-paths-rows {
    margin-left: 8px;
    color: var(--gray);
}
.gp-paths .feather-icon-check + .feather-icon-check {
    position: absolute;
    margin-left: -14px;
    pointer-events: none;
}
.gp-paths > table td:last-child > a {
    display: none;
}
.gp-paths > table > tbody td {
    padding: 0 2px;
}
.gp-paths > table > tbody th {
    padding-right: 8px;
}
.gp-paths {
    margin-top: 20px;
}
.gp-paths table {
    font-size: 0.9em;
}
.gp-paths > table td:last-child > a {
    display: none;
}
.gp-paths > table > tbody td {
    padding: 0 2px;
}
.gp-paths > table > tbody th {
    padding-right: 8px;
}
.gp-paths-group-head {
    list-style: none;
    margin-left: -27.5px;
}
</style>
