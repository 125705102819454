var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "replenishment-plan", attrs: { id: "replenishment-plan" } },
    [
      _c(
        "header",
        { staticClass: "replenishment-plan__header" },
        [
          _c("ao-date-range", {
            attrs: { scrollableSelector: "#replenishment-plan" },
            model: {
              value: _vm.period,
              callback: function($$v) {
                _vm.period = $$v
              },
              expression: "period"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "replenishment-plan__main" },
        [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [_c("AutoorderChart", { attrs: { chartConfig: _vm.chartConfig } })],
            1
          ),
          _vm._v(" "),
          _c("ao-plan-table", {
            attrs: {
              parentName: _vm.$options.tableProps.componentName,
              modelName: _vm.$options.tableProps.modelName,
              columns: _vm.$options.tableProps.columns,
              endpoint: _vm.$options.ENDPOINTS.TABLE_FILTERED,
              configEndpoint: _vm.$options.ENDPOINTS.FILTERED_CONFIG,
              reportsEndpoint: _vm.$options.ENDPOINTS.REPORTS,
              reportIdStorageKey: _vm.$options.tableProps.reportIdKey,
              columnsSizeStorageKey:
                _vm.$options.tableProps.tableColumnsSizeKey,
              orderDateFilter: _vm.orderDateFilter
            },
            on: { onConfigChange: _vm.configChangeHandle }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }