<template>
  <div id="replenishment-plan" class="replenishment-plan">
    <header class="replenishment-plan__header">
      <ao-date-range
        v-model="period"
        scrollableSelector="#replenishment-plan"
      />
    </header>
    <main class="replenishment-plan__main">
      <div style="position: relative;">
        <AutoorderChart
          :chartConfig="chartConfig"
        />
      </div>
      <ao-plan-table
        :parentName="$options.tableProps.componentName"
        :modelName="$options.tableProps.modelName"
        :columns="$options.tableProps.columns"
        :endpoint="$options.ENDPOINTS.TABLE_FILTERED"
        :configEndpoint="$options.ENDPOINTS.FILTERED_CONFIG"
        :reportsEndpoint="$options.ENDPOINTS.REPORTS"
        :reportIdStorageKey="$options.tableProps.reportIdKey"
        :columnsSizeStorageKey="$options.tableProps.tableColumnsSizeKey"
        :orderDateFilter="orderDateFilter"
        @onConfigChange="configChangeHandle"
      />
    </main>
  </div>
</template>

<script>
const moment = require('moment');
const { tableProps, ENDPOINTS } = require('../../constants/replenishment');
const { getDateByOffset, getSortingFilteringRequestBody } = require('../../utils/formatters');
const AutoorderChart = require('../../components/charts/autoorder-chart.vue').default;
const AoPlanTable = require('../../components/tables/ao-plan-table.vue').default;
const AoDateRange = require('../../components/shared/ao-date-range.vue').default;

module.exports = {
  name: tableProps.componentName,

  components: {
    AoPlanTable,
    AoDateRange,
    AutoorderChart,
  },

  // $options
  tableProps,
  ENDPOINTS,

  data() {
    return {
      period: {
        start: getDateByOffset(-14),
        end: getDateByOffset(28),
      },
      config: {},
    };
  },

  computed: {
    orderDateFilter() {
      return {
        order_date: {
          gtoe: moment(this.period.start).format('YYYY-MM-DD'),
          ltoe: moment(this.period.end).format('YYYY-MM-DD'),
        },
      };
    },
    chartConfig() {
      return getSortingFilteringRequestBody(
        this.config,
        { filtering: { ...this.orderDateFilter } },
      );
    },
  },

  methods: {
    configChangeHandle(config) {
      this.config = config;
    },
  },
};
</script>

<style  scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E9E9E9;
  border-radius: 10px;
  background-clip: content-box;
  border: 2px solid transparent;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5C606F;
}

.replenishment-plan {
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
  overflow: auto;
}

.replenishment-plan__main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-end;
}

.replenishment-plan >>> .ao-date-field {
  max-width: 350px;
}
</style>
