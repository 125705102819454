<template>
  <div class="gp-menu">
    <a href="javascript:void(0)" @click="expanded = !expanded">
      <feather-icon name="menu"/>
    </a>
    <ul class="gp-menu-items">
      <li v-for="item in menu">
        <a :href="item.link">
          <feather-icon :name="item.icon"/>
          <l10n :value="item.name"/>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
module.exports = {
  props: {
    menu: { type: Array, default: () => [] },
  },
  data() {
    return { expanded: false };
  },
};
</script>
