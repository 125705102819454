<template>
  <tr>
    <td>
      <div
        class="pim-tree-entry"
        :class="{focused: entry.focused}"
        :style="{'margin-left': level * 20 + 'px'}"
      >
        <!--a class="pim-tree-open" v-if="entry.loading">
                    <feather-icon name="clock"/>
                </a-->
        <a href="javascript:void(0)"
           class="pim-tree-open"
           v-if="entry.children != null && entry.children.length"
           tabindex="-1"
           @click="$emit(entry.opened ? 'close' : 'open', entry)">
          <feather-icon :name="entry.opened ? 'book-open' : 'book'"/>
        </a>
        <div class="pim-tree-entry-head">
          <gp-check v-if="multiselect" v-model="entry.selected" :inline="true"></gp-check>
          <img v-if="hasImage" :src="entry.image">
          <span
            ref="name"
            class="pim-tree-entry-name"
            tabindex="0"
            @focus="$emit('focus', entry)"
            @blur="$emit('blur', entry)">
            <template v-if="formattedName">
              <span
                v-for="part in formattedName"
                :class="{matched: part.matched}"
              >{{part.text}}</span>
            </template>
            <template v-else>
              {{entry.name || "[name]"}}
            </template>
          </span>
          <span class="pim-tree-entry-desc" v-if="entry.desc">
            –
            <template v-if="formattedDesc">
              <span
                v-for="part in formattedDesc"
                :class="{matched: part.matched}"
              >{{part.text}}</span>
            </template>
            <template v-else>
              {{entry.desc}}
            </template>
          </span>
          <span v-if="entry.children && entry.children.length" class="pim-tree-entry-info">
            – {{Number(entry.children.length).toLocaleString()}}
            {{entry.children.length > 1 ? pluralize(entry.children[0].type) : entry.children[0].type}}
          </span>
          <span class="pim-tree-entry-error" v-if="entry.error">
            – {{entry.error}}
          </span>
        </div>
      </div>
    </td>
    <!--td v-for="{key,val} of entry.attrs" :class="`pim-tree-entry-attr-${key}`">
            <feather-icon name="chevron-right" v-if="entry.focused"/>
            <span>{{formatColumn(key, val)}}</span>
        </td-->
  </tr>
</template>
<script>
let utils = require('../my-utils');
module.exports = {
  props: {
    entry: { type: Object },
    multiselect: { type: Boolean },
    formatColumn: { type: Function },
    pluralize: { type: Function },
  },
  methods: {
    focus() {
      this.$refs.name.focus();
    },
  },
  computed: {
    hasImage(type) {
      return ['item', 'variation', 'sku', 'digital asset'].includes(this.entry.type);
    },
    level() {
      let level = 0;
      let entry = this.entry;
      while (entry) {
        level += 1;
        entry = entry.parent;
      }
      return level;
    },
    formattedName() {
      let search = this.entry.search;
      if (search && search.matches) {
        return utils.formatSearchItem(this.entry.name, search.matches.filter(match => match.key == 'name'));
      }
    },
    formattedDesc() {
      let search = this.entry.search;
      if (search && search.matches) {
        return utils.formatSearchItem(this.entry.name, search.matches.filter(match => match.key == 'desc'));
      }
    },
  },
};
</script>
