<template>
  <div class="plan-table">
    <div class="plan-table__buttons-row">
      <ao-button
        type="secondary"
        class="plan-table__setting-button"
        size="small"
        minified
        rounded
        :disabled="idDownloadButtonDisabled"
        @click="createReport"
      >
        <download-icon />
      </ao-button>

      <ao-button
        ref="visibilitySetButton"
        type="secondary"
        class="plan-table__setting-button"
        size="small"
        minified
        rounded
        :disabled="isLoading"
        @click="toggleColumnVisibilitySetting"
      >
        <settings-icon />
      </ao-button>

      <ao-column-visible-popup
        v-if="showColumnVisibilitySettings"
        v-model="showColumnVisibilitySettings"
        xPosition="left"
        :scrollableAreaSelector="scrollableContainer"
        :parent="$refs.visibilitySetButton.$el"
        :tableColumns="tableColumnsForSettings"
        :checkedColumnIds="visibleColumnIds"
        @onChangeColumnVisible="columnCheckedChange"
      />

      <ao-button
        ref="configButton"
        type="secondary"
        class="plan-table__setting-button"
        size="small"
        minified
        rounded
        :class="{ active: !isConfigEmpty }"
        :disabled="isLoading"
        @click="showConfigReset = !showConfigReset"
      >
        <sliders-icon />
      </ao-button>

      <ao-configs-popup
        v-if="showConfigReset"
        v-model="showConfigReset"
        :scrollableAreaSelector="scrollableContainer"
        :modelName="modelName"
        :isConfigEmpty="isConfigEmpty"
        :tableConfigParams="configParams"
        :savedConfigId="savedConfigId"
        :parent="$refs.configButton.$el"
        @onReset="resetTableColumnConfig"
        @onSelectConfig="savedConfigSelect"
      />
    </div>

    <ao-table
      :scrollableContainerSelector="scrollableContainer"
      :endpoint="endpoint"
      :tableColumns="visibleColumns"
      :currentTableConfig="configParams"
      :parentName="parentName"
      :columnsSizeStorageKey="columnsSizeStorageKey"
      :modelName="modelName"
      :needToResetConfig="needToResetConfig"
      :additionalPayload="additionalPayload"
      @onConfigChange="configChangedHandler"
    />
  </div>
</template>

<script>
const { REPORT_STATUS } = require('../../constants/reports');
const { getConfigFromRequestBody, getSortingFilteringRequestBody } = require('../../utils/formatters');
const { downloadResource } = require('../../utils/download');
const AoButton = require('../shared/ao-button.vue').default;
const AoTable = require('../ao-table.vue').default;
const AoConfigsPopup = require('../custom-popups/ao-configs-popup.vue').default;
const AoColumnVisiblePopup = require('../custom-popups/ao-column-visible-popup.vue').default;
const SlidersIcon = require('../../assets/icons/sliders-icon.vue').default;
const SettingsIcon = require('../../assets/icons/settings-icon.vue').default;
const DownloadIcon = require('../../assets/icons/download-icon.vue').default;

module.exports = {
  components: {
    AoButton,
    AoTable,
    AoConfigsPopup,
    AoColumnVisiblePopup,
    SlidersIcon,
    SettingsIcon,
    DownloadIcon,
  },

  props: {
    parentName: {
      type: String,
      required: false,
      default: null,
    },
    columnsSizeStorageKey: {
      type: String,
      required: false,
      default: null,
    },
    orderDateFilter: {
      type: Object,
      required: false,
      default: () => {},
    },
    modelName: {
      type: String,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    endpoint: {
      type: String,
      required: false,
      default: null,
    },
    configEndpoint: {
      type: String,
      required: false,
      default: null,
    },
    reportsEndpoint: {
      type: String,
      required: false,
      default: null,
    },
    reportIdStorageKey: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      configParams: {},
      savedConfigId: null,
      showConfigReset: false,
      needToResetConfig: false,
      showColumnVisibilitySettings: false,
      visibleColumnIds: [],
      reportId: null,
      reportStatus: null,
    };
  },

  computed: {
    visibleColumns() {
      return this.columns.filter(({ id }) => this.visibleColumnIds.includes(id));
    },
    tableColumnsForSettings() {
      return this.columns.map(({ id, title }) => ({ id, title }));
    },
    isConfigEmpty() {
      return Object.keys(this.configParams).length === 0;
    },
    scrollableContainer() {
      return this.parentName ? `#${this.parentName}` : null;
    },
    visibleColumnsKey() {
      return this.parentName ? `${this.parentName}-table-visible-cols` : 'plan-table-visible-cols';
    },
    additionalPayload() {
      return {
        filtering: {
          ...this.orderDateFilter,
        },
      };
    },
    idDownloadButtonDisabled() {
      return !!this.reportId || !!this.reportStatus || this.isLoading;
    },
  },

  watch: {
    visibleColumnIds(newIds) {
      this.saveVisibleColumnIdsToStorage(newIds);
    },
    configParams(newParams) {
      this.$emit('onConfigChange', newParams);
    },
    reportId(newId) {
      if (!newId) {
        return;
      }

      setTimeout(() => {
        this.fetchReportStatus(newId);
      }, 1000);

      if (this.reportIdStorageKey) {
        localStorage.setItem(this.reportIdStorageKey, newId);
      }
    },
    async reportStatus(newStatus) {
      switch (newStatus) {
        case REPORT_STATUS.CREATED:
        case REPORT_STATUS.PROGRESS:
          setTimeout(() => {
            this.fetchReportStatus(this.reportId);
          }, 1000);
          break;
        case REPORT_STATUS.ERROR:
          this.createNotification(`Не удалось сформировать отчёт id - ${this.reportId}`, 'error');
          this.clearReportInfo();
          break;
        case REPORT_STATUS.DONE:
          await this.fetchReportResult(this.reportId);
          this.clearReportInfo();
          break;
        default:
          break;
      }
    },
  },

  mounted() {
    this.visibleColumnIds = this.getVisibleColumnIdsFromStorage();

    if (this.reportIdStorageKey) {
      const id = Number(localStorage.getItem(this.reportIdStorageKey)) || null;
      this.reportId = id;
    }
  },

  methods: {
    // column configs
    resetTableColumnConfig() {
      this.needToResetConfig = true;

      this.$nextTick(() => {
        this.needToResetConfig = false;
      });
    },
    configChangedHandler(newConfig) {
      this.configParams = newConfig;
      this.savedConfigId = null;
    },
    savedConfigSelect({ id, config }) {
      this.savedConfigId = id;

      if (config) {
        this.configParams = getConfigFromRequestBody(config);
      }
    },

    // column visibility
    columnCheckedChange(newIds) {
      this.visibleColumnIds = newIds;
      this.showColumnVisibilitySettings = false;
    },
    getVisibleColumnIdsFromStorage() {
      const cols = localStorage.getItem(this.visibleColumnsKey);
      return cols
        ? JSON.parse(cols)
        : this.columns.map(({ id }) => id);
    },
    saveVisibleColumnIdsToStorage(ids) {
      localStorage.setItem(this.visibleColumnsKey, JSON.stringify(ids));
    },
    toggleColumnVisibilitySetting() {
      this.showColumnVisibilitySettings = !this.showColumnVisibilitySettings;
    },

    // get report
    async createReport() {
      if (!this.reportsEndpoint) {
        return;
      }

      try {
        this.isLoading = true;
        const config = getSortingFilteringRequestBody(
          this.configParams,
          { filtering: { ...this.orderDateFilter }, sorting: {} },
        );
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({
            model_name: this.modelName,
            config,
          }),
        };

        const response = await fetch(this.reportsEndpoint, options);

        if (response.ok) {
          const data = await response.json();
          const { id } = data;
          this.reportId = id;
          this.createNotification(`Отчет id-${id} создан. По готовности будет загружен csv-файл`, 'success');
        } else {
          throw new Error();
        }
      } catch (error) {
        this.createNotification('Ошибка при создании отчета', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchReportStatus(id) {
      if (!this.reportsEndpoint) {
        return;
      }

      try {
        const url = `${this.reportsEndpoint}${id}/status/`;
        const response = await fetch(url);

        if (response.ok) {
          const data = await response.json();
          const { status } = data;
          this.reportStatus = status;
        } else {
          throw new Error();
        }
      } catch (error) {
        this.createNotification(`Ошибка при получении статуса отчета id-${id}`, 'error');
      }
    },
    async fetchReportResult(id) {
      if (!this.reportsEndpoint) {
        return;
      }

      try {
        const url = `${this.reportsEndpoint}${id}/result/`;
        const response = await fetch(url);

        if (response.ok) {
          const data = await response.json();
          const { file } = data;
          downloadResource(file);
        } else {
          throw new Error();
        }
      } catch (error) {
        this.createNotification(`Ошибка при загрузке csv-файла отчета id-${id}`, 'error');
      }
    },
    clearReportInfo() {
      this.reportId = null;
      this.reportStatus = null;

      if (this.reportIdStorageKey) {
        localStorage.removeItem(this.reportIdStorageKey);
      }
    },
  },
};
</script>

<style scoped>
.plan-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.plan-table__buttons-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.plan-table__setting-button {
  position: relative;
  padding: 4px;
}

.plan-table__setting-button.active::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: #5295D5;
}

.plan-table__button {
  align-self: flex-end;
}
</style>
