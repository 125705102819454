const checkStringInvalid = (string) => {
  // eslint-disable-next-line no-useless-escape
  const invalidPattern = /[._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g;
  return !!String(string).match(invalidPattern);
};

const checkDatesValid = (start, finish) => {
  if (!start || !finish) {
    return false;
  }
  const openDate = new Date(start).valueOf();
  const closedDate = new Date(finish).valueOf();
  return closedDate > openDate;
};

const checkStartWith = (payload, subString) => payload && String(payload).startsWith(subString);

module.exports = {
  checkStringInvalid,
  checkDatesValid,
  checkStartWith,
};
