const { DOMAIN } = require('./endpoint');

// storage keys
const storageKeys = {
  parentName: 'orders',
  orderTabs: 'order-tabs',
  ordersEntity: 'orders-cards',
  register: {
    visibleColumnsKey: 'order-register-visible-cols',
    columnsSizeKey: 'order-register-columns-size',
  },
};

// orders
const COMPONENT_NAMES = {
  register: 'OrdersRegister',
  order: 'OrderCard',
};
const registerTab = { id: 'order-register', title: 'Реестр заказов', componentName: COMPONENT_NAMES.register };

// register
const registerColumns = [
  {
    id: 'id',
    title: 'Информация по заказу (ID)',
    field: 'id',
    config: {
      filterType: 'numeric',
    },
  },
  {
    id: 'supplier_name',
    title: 'Отправитель',
    field: 'supplier_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'supplier_code',
    title: 'Код отправителя',
    field: 'supplier_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_name',
    title: 'Получатель',
    field: 'receiver_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_code',
    title: 'Код получателя',
    field: 'receiver_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'created_at',
    title: 'Текущая дата',
    field: 'created_at',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_formation',
    title: 'Дата формирования',
    field: 'date_formation',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_send',
    title: 'Дата отправки',
    field: 'date_send',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_processing',
    title: 'Дата обработки',
    field: 'date_processing',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'replenish_date',
    title: 'Дата отгрузки',
    field: 'replenish_date',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'status_approval',
    title: 'Статус согласования',
    field: 'status_approval',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'status_send',
    title: 'Статус отправки',
    field: 'status_send',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'time_acceptance_application',
    title: 'Время приема заявок',
    field: 'time_acceptance_application',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'limit_orders_chain_supplier',
    title: 'Период действия цепочки',
    field: 'limit_orders_chain_supplier',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'chain_type',
    title: 'Вид цепочки',
    field: 'chain_type',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'supplier_stock',
    title: 'Склады отгрузки',
    field: 'supplier_stock',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'min_borders',
    title: 'Минимальные границы',
    field: 'min_borders',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'max_borders',
    title: 'Максимальные границы',
    field: 'max_borders',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_stock',
    title: 'Склад получателя',
    field: 'receiver_stock',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'chain_created',
    title: 'Дата создания цепочки поставки',
    field: 'chain_created',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_delivery',
    title: 'Срок поставки',
    field: 'date_delivery',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  modelName: 'order',
  endpoint: `${DOMAIN}/orders/filtered/`,
  reportIdKey: 'order-register-report-id',
};

// order card
const ORDER_DATA_DEFAULT = {
  orderInfo: {
    id: null,
    supplierName: null,
    supplierStock: null,
    receiverName: null,
    receiverStock: null,
    currentDate: null,
    dateFormation: null,
    dateSend: null,
    dateProcessing: null,
    dateReplenish: null,
    dateArrival: null,
    statusApproval: null,
    statusSend: null,
    activePeriod: null,
    chainType: null,
    applicationAcceptanceTime: null,
    minBorder: null,
    maxBorder: null,
    dateDelivery: null,
    chainCreatedDate: null,
  },
};

const orderItemsTableColumns = [
  {
    id: 'product_name',
    title: 'Наименование товара',
    field: 'product_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'product_code',
    title: 'Код товара',
    field: 'product_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'recommendation_order',
    title: 'Рекомендация по заказу',
    field: 'recommendation_order',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'order',
    title: 'Заказ',
    field: 'order',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'product_price',
    title: 'Цена товара',
    field: 'product_price',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 2,
      },
    },
  },
  {
    id: 'multiplicity',
    title: 'Кратность',
    field: 'multiplicity',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'expected_quality_ratio',
    title: 'Плановый сервис',
    field: 'expected_quality_ratio',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 2,
      },
    },
  },
  {
    id: 'incoming_inventory',
    title: 'Входящий остаток',
    field: 'incoming_inventory',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'forecast',
    title: 'Прогноз',
    field: 'forecast',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'arrivals',
    title: 'Приходы',
    field: 'arrivals',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'write_offs',
    title: 'Списания',
    field: 'write_offs',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'point_A',
    title: 'Точка А',
    field: 'point_A',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'point_B',
    title: 'Точка B',
    field: 'point_B',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'sales_O_A',
    title: 'Продажи O-A',
    field: 'sales_O_A',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'sales_A_B',
    title: 'Продажи A-B',
    field: 'sales_A_B',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'arrivals_O_A',
    title: 'Приходы O-A',
    field: 'arrivals_O_A',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'arrivals_A_B',
    title: 'Приходы A-B',
    field: 'arrivals_A_B',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'write_offs_O_A',
    title: 'Списания O-A',
    field: 'write_offs_O_A',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'write_offs_A_B',
    title: 'Списания A-B',
    field: 'write_offs_A_B',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'presentation',
    title: 'Презентация',
    field: 'presentation',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'safety_stock',
    title: 'Страховой запас',
    field: 'safety_stock',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'other_minimums',
    title: 'Другие виды минимума',
    field: 'other_minimums',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'lower_line',
    title: 'Нижняя граница',
    field: 'lower_line',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'lower_line_A',
    title: 'Нижняя граница в А',
    field: 'lower_line_A',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'settlement_inventory',
    title: 'Расчетный остаток в А',
    field: 'settlement_inventory',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'target_inventory',
    title: 'Целевой остаток в А',
    field: 'target_inventory',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
  {
    id: 'recommendation_receipt',
    title: 'Рекомендация по приходу в А',
    field: 'recommendation_receipt',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
        isNotInteger: true,
        integerMaxLength: 7,
        fractionalMaxLength: 3,
      },
    },
  },
];

const orderItemsTableProps = {
  columns: orderItemsTableColumns,
  componentName: 'order-items',
  modelName: 'orderItem',
  tableColumnsSizeKey: 'order-items-table-columns-size',
  reportIdKey: 'order-items-report-id',
};

// endpoints
const ENDPOINTS = {
  ORDERS: `${DOMAIN}/orders/`,
  REPORTS: `${DOMAIN}/reports/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  ORDER_DATA_DEFAULT,
  registerTab,
  storageKeys,
  registerTableProps,
  orderItemsTableProps,
};
