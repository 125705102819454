<template>
  <div class="accordions-container">
    <my-accordion
      openedByDefault
      @accordionOpened="handleAccordionOpened('accordion-1')"
      ref="accordion-1"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Информация по заказу" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Номер заказа:</b> 000646 <br />
          <b>Отправитель:</b> V7_770 (код: 770) &nbsp;   <b>Получатель:</b> Белая Дача (код: 732) <br />
          <b>Текущая дата:</b> 27.12.2023<br />
          <b>Дата формирования:</b> 27.12.2023<br />
          <b>Дата отправки:</b> 27.12.2023&nbsp; <b>Дата обработки:</b> 27.12.2023&nbsp; <b>Дата отгрузки:</b> 29.12.2023&nbsp; <b>Дата прихода:</b> 29.12.2023<br />
          <b>Время приема заявок:</b> 10:00<br />
          <b>Статус согласования:</b> <span style="color: orange">на проверке</span>&nbsp; <b>Статус отправки:</b> <span style="color: orange">не отправлен</span>
        </div>
      </template>
    </my-accordion>
    <my-accordion
      @accordionOpened="handleAccordionOpened('accordion-2')"
      ref="accordion-2"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Ограничения по заказам по цепочке поставщика" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Период действия:</b> <span style="color: orange">31.12.2099</span>
          <b>Вид цепочки:</b> Прямая поставка <br />
          <b>Склады отгрузки:</b> V7_770 (код: 770) <br />
          <b>Ассортиментные наборы:</b> ассортимент гипермаркета <br />
          <b>Время обработки заявок:</b> 2 дня <br />
          <b>Минимальные границы:</b> - &nbsp;&nbsp;&nbsp; <b>Максимальные границы:</b> - <br />
          <b>Склады получатели:</b>  Белая Дача (код: 732) <br />
          <b>Срок поставки:</b>  29.12.2023 <br />
          <b>Дата создания цепочки поставки:</b> 01.01.2022 <br />
        </div>
      </template>
    </my-accordion>
    <my-accordion
      @accordionOpened="handleAccordionOpened('accordion-3')"
      ref="accordion-3"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Информация по контрагенту" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Наименование контрагента:</b> V7_770 <br />
          <b>Id карточки:</b> 908653 <br />
          <b>Артикул:</b> 770 <br />
          <b>ИНН:</b> - <br />
          <b>Телефон:</b> - &nbsp;&nbsp;&nbsp; <b>Телеграм:</b> -
          <b>Договоры:</b> - <br />
          <b>Склады отгрузки:</b> - <br />
          <b>Контактное лицо:</b> - <br />
          <b>Адрес приема заказов:</b> - <br />
          <b>График приема заказов:</b> <span style="color: orange">понедельник - пятница 9:00 - 18: 00</span>&nbsp; <b>Время отправки заказов (до):</b> <span style="color: orange">до 14:00</span>
        </div>
      </template>
    </my-accordion>
  </div>
</template>

<script>
const MyAccordion = require('../my-accordion.vue').default;

module.exports = {
  components: {
    'my-accordion': MyAccordion,
  },

  methods: {
    handleAccordionOpened(accordionRef) {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('accordion') && ref !== accordionRef) {
          this.$refs[ref].closeAccordion();
        }
      });
    },
  },
};
</script>

<style scoped>
.accordions-container {
  width: 49.5%;
}
.accordion-title {
  margin-left: 0.75rem;
  font-size: 14px;
  font-weight: 500;
}
</style>
