<template>
  <div class="promos" id="promos">
    <header class="promos__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>

    <main class="promos__main">
      <keep-alive v-if="currentComponent" :include="['promos-catalog']">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  COMPONENT_NAMES,
  promoTabsStorageKey,
  promoEntityStorageKey,
  promoRulesEntityStorageKey,
  newPromo,
  newPromoRule,
  catalogTab,
  registerTab,
} = require('../../constants/promos');
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const {
  getExistTabCounts, getCardCounter, sortTabsByCounter, isTabExist, removeTab,
} = require('../../utils/cardTabsHelpers');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const PromoCard = (resolve) => {
  require(['./promo-card.vue'], resolve);
};
const PromoRulesCard = (resolve) => {
  require(['./promo-rules-card.vue'], resolve);
};
const PromosCatalog = (resolve) => {
  require(['./promos-catalog.vue'], resolve);
};
const PromosRegister = (resolve) => {
  require(['./promos-register.vue'], resolve);
};
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  PromoCard,
  PromoRulesCard,
  PromosCatalog,
  PromosRegister,
  AoTabs,
  CloseTabModal,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      // https://ru.vuejs.org/v2/api/#provide-inject
      reactiveProvidedPromoData: this.reactiveData,

      promoCard: {
        entityStorageKey: promoEntityStorageKey,
        openCatalog: this.openCatalog,
        createNew: this.createNewPromoCard,
        duplicate: this.duplicatePromoCard,
        openExist: this.openExistPromoCard,
        onSuccessfulCreate: this.onSuccessfulCreatePromo,
      },

      promoRule: {
        entityStorageKey: promoRulesEntityStorageKey,
        openRegister: this.openRegister,
        createNew: this.createNewRulesCard,
        duplicate: this.duplicateRulesCard,
        openExist: this.openExistRulesCard,
        onSuccessfulCreate: this.onSuccessfulCreateRule,
      },
    };
  },

  data() {
    return {
      tabs: [
        catalogTab,
        registerTab,
      ],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
        duplicatedPromoState: null,
        duplicatedRuleState: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    promoTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.promo)
        .map(({ id }) => id);
    },
    ruleTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.rules)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: promoTabsStorageKey, tabs: newTabs });

      clearCardStatesCache({
        entity: promoEntityStorageKey,
        tabIds: this.promoTabIds,
      });

      clearCardStatesCache({
        entity: promoRulesEntityStorageKey,
        tabIds: this.ruleTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(promoTabsStorageKey);
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.promo:
            this.openCatalog();
            break;

          case COMPONENT_NAMES.rules:
            this.openRegister();
            break;

          default:
            this.openCatalog();
            break;
        }
      }
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },

    // open static component tab
    openCatalog() {
      this.selectTab(catalogTab);
    },
    openRegister() {
      this.selectTab(registerTab);
    },

    // open dynamic component tab
    openPromoCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка промо - ${id}`,
        componentName: COMPONENT_NAMES.promo,
      };

      this.selectOrAddTab(newTab);
    },
    openExistPromoCard(id) {
      this.openPromoCard({ id });
    },
    openRulesCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка привязки - ${id}`,
        componentName: COMPONENT_NAMES.rules,
      };

      this.selectOrAddTab(newTab);
    },
    openExistRulesCard(id) {
      this.openRulesCard({ id });
    },

    // create new cards
    createNewPromoCard() {
      const counts = getExistTabCounts(newPromo, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newPromo}-${counter}`,
        title: `Новая карточка - ${counter}`,
        isNew: true,
      };

      this.openPromoCard(tabBody);
    },
    createNewRulesCard() {
      const counts = getExistTabCounts(newPromoRule, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newPromoRule}-${counter}`,
        title: `Новая привязка - ${counter}`,
        isNew: true,
      };

      this.openRulesCard(tabBody);
    },

    // duplicate cards
    duplicatePromoCard(cardState) {
      this.reactiveData.duplicatedPromoState = cardState;
      this.createNewPromoCard();
    },
    duplicateRulesCard(cardState) {
      this.reactiveData.duplicatedRuleState = cardState;
      this.createNewRulesCard();
    },

    // successful create card handle
    onSuccessfulCreatePromo(id) {
      if (!id) {
        return;
      }

      const newPromoCard = { id, componentName: COMPONENT_NAMES.promo, title: `Карточка промо - ${id}` };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newPromoCard : card));
      this.selectTab(newPromoCard);
    },
    onSuccessfulCreateRule(id) {
      if (!id) {
        return;
      }

      const newRuleCard = { id, componentName: COMPONENT_NAMES.rules, title: `Карточка привязки - ${id}` };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newRuleCard : card));
      this.selectTab(newRuleCard);
    },
  },
};
</script>

<style  scoped>
.promos {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  padding: 12px;
  overflow: auto;
}

.promos__main {
  flex: 1 0 auto;
}
</style>
