<template>
  <select
    ref="select"
    style="_display:none"
    :readonly="readonly"
    :disabled="disabled"
    :multiple="multiple"
    :placeholder="placeholder"
    :data-placeholder="placeholder"
  >
    <template v-if="true">
      <option key="" value="" disabled="disabled"></option>
      <option
        v-for="option in optionsEx"
        ref="options"
        :value="option.value"
      >
        {{option.name}}
      </option>
    </template>
  </select>
</template>
<script>
let utils = require('../my-utils');
require('../chosen.jquery.js');
module.exports = {
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    multiple: { type: Boolean },
    value: { type: [String, Array] },
    options: { type: [Array, Object], default: () => [] },
    placeholder: { type: String, default: 'Select an option' },
    allowSingleDeselect: { type: Boolean },
    disableSearchThreshold: { type: Number },
    noResultsText: { type: String },
    maxSelectedOptions: { type: Number },
    width: { type: [String, Number] },
    rtl: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
  },
  data() {
    return { renderOptions: true };
  },
  computed: {
    optionsEx() {
      let value = this.value;
      let options = _.isPlainObject(this.options)
        ? _(this.options).toPairs().map(([value, name]) => ({ name, value })).value()
        : _.map(this.options, x => _.isString(x) ? { name: x, value: x } : x);
      let values = _.isArray(value) ? value : value != null ? [value] : [];
      for (let value of values) {
        if (!options.find(option => option.value == value)) {
          options.push({ name: value, value: value });
        }
      }
      return options;
    },
  },
  methods: {
    updateSelected() {
      if (!this.multiple) {
        this.$refs.select.value = this.value;
      }
      for (let option of this.$refs.options || []) {
        if (option.disabled) {
          option.selected = !this.value || this.value.length == 0;
        } else {
          option.selected = this.multiple ? this.value && this.value.indexOf(option.value) != -1 : this.value == option.value;
        }
      }
    },
  },
  mounted() {
    this.updateSelected();
    $(this.$refs.select).chosen({
      rtl: this.rtl,
      width: this.width,
      max_selected_options: this.maxSelectedOptions,
      no_results_text: this.noResultsText,
      allow_single_deselect: this.allowSingleDeselect,
      disable_search_threshold: this.disableSearchThreshold,
    }).change(() => {
      let value = _(this.$refs.select.options)
        .filter(({ selected, disabled }) => selected && !disabled)
        .map(({ value }) => value)
        .filter()
        .value();
      if (this.multiple) {
        this.$emit('change', value);
      } else {
        this.$emit('change', value[0] || null);
      }
    });
    $(this.$el).find('+ .chosen-container .chosen-single')
      .on('touchstart', function(e) {
        e.stopPropagation();
        e.preventDefault();
        // Trigger the mousedown event.
        $(this).trigger('mousedown');
      })
      .addClass(this.$refs.select.className);
    $(this.$el).find('+ .chosen-container .chosen-search-input')
      .attr('placeholder', this.placeholder);
    Vue.nextTick(() => this.renderOptions = false);
  },
  beforeDestroy() {
    $(this.$refs.select).chosen('destroy');
  },
  watch: {
    value(newVal) {
      if (!_.isEqual(newVal, this.oldVal)) {
        this.oldVal = _.cloneDeep(newVal);
        this.renderOptions = true;
        Vue.nextTick(() => {
          this.updateSelected();
          $(this.$refs.select).trigger('chosen:updated');
          this.renderOptions = false;
        });
      }
    },
    optionsEx(newVal) {
      if (!_.isEqual(newVal, this.oldVal)) {
        this.oldVal = _.cloneDeep(newVal);
        this.renderOptions = true;
        Vue.nextTick(() => {
          this.updateSelected();
          $(this.$refs.select).trigger('chosen:updated');
          this.renderOptions = false;
        });
      }
    },
  },
};
</script>
<style>
.my-root .chosen-container {
    margin-bottom: 5px;
    width: auto!important;
    min-width: 70px;
    font-size: 14px;
    font-weight: 500;
}
.my-root .chosen-container-single .chosen-single abbr {
    right: 22px;
}
.my-root .chosen-container-multi .chosen-choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 8px 10px 12px;
    border: none;
    border-radius: 8px;
    background-image: none;
    background-color: #F7F7F7;
}
.my-dark-theme .chosen-container-multi .chosen-choices {
    border: 1px solid #ffffff;
    background-color: #222;
}
.my-root .chosen-container-multi .chosen-choices li.search-choice,
.my-root .chosen-container-multi .chosen-choices li.search-field {
    float: none;
    margin: 2px;
}
.my-root .chosen-container-multi .chosen-choices li.search-choice {
    order: 2;
    background-color: #3385FF;
    background-image: none;
    border: none;
    border-radius: 5px;
    color: #ffffff;
}
.my-root .chosen-container-multi .chosen-choices li.search-field {
    order: 1;
}
.my-root .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin-top: -2px;
    margin-bottom: -2px;
}
.my-root .chosen-container .chosen-drop {
    width: auto;
    padding: 2px;
    border: none;
    margin-top: 2px;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.my-root .chosen-container .chosen-results li {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-root .chosen-container .chosen-single {
    background: none;
    box-shadow: none;
    border: none;
}
.my-dark-theme .my-root .chosen-container .chosen-drop {
    background: #333;
    color: white;
}
.my-dark-theme .my-root .chosen-container .chosen-search input {
    color: white;
}
.my-dark-theme .my-root .chosen-container .chosen-results {
    color: white;
}
.my-root .chosen-container .chosen-results li.highlighted {
    background: var(--cyan);
    background-image: none;
}
.my-root .chosen-container-single .chosen-default {
    color: var(--gray);
}

</style>
