var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autoorder-modal", {
    staticClass: "config-save-modal",
    attrs: {
      modalTitle: "Сохранить фильтры",
      closable: "",
      closeButton: "",
      modalVisible: _vm.isVisible
    },
    on: { onClose: _vm.handleDiscard },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function() {
          return [
            _c(
              "h2",
              { staticClass: "config-save-modal__input-title" },
              [_c("l10n", { attrs: { value: "Название фильтра" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              staticClass: "config-save-modal__input",
              attrs: {
                size: "small",
                maxLength: 200,
                invalid: !_vm.getTrimmedString(_vm.filterName) && _vm.tryToSend,
                placeholder: "Введите название фильтра"
              },
              model: {
                value: _vm.filterName,
                callback: function($$v) {
                  _vm.filterName = $$v
                },
                expression: "filterName"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "config-save-modal__buttons-row" },
              [
                _c(
                  "ao-button",
                  {
                    attrs: { type: "secondary", disabled: _vm.isLoading },
                    on: { click: _vm.handleDiscard }
                  },
                  [_c("l10n", { attrs: { value: "Отмена" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ao-button",
                  {
                    attrs: {
                      disabled:
                        (_vm.isFormInvalid && _vm.tryToSend) || _vm.isLoading
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_c("l10n", { attrs: { value: "Сохранить" } })],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }