const { DOMAIN } = require('./endpoint');

// plan table
const columns = [
  {
    id: 'order_date',
    title: 'Дата заказа',
    field: 'order_date',
    config: {
      sorting: true,
    },
  },
  {
    id: 'product_name',
    title: 'Товар',
    field: 'product_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'product_code',
    title: 'Код товара',
    field: 'product_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'product_categories',
    title: 'Категория товара',
    field: 'product_categories',
  },
  {
    id: 'receiver_name',
    title: 'Получатель',
    field: 'receiver_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_code',
    title: 'Код получателя',
    field: 'receiver_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'incoming_inventory',
    title: 'Входящий остаток',
    field: 'incoming_inventory',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'supplier_name',
    title: 'Отправитель',
    field: 'supplier_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'supplier_code',
    title: 'Код отправителя',
    field: 'supplier_code',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'order_available',
    title: 'Дни когда возможен заказ',
    field: (row) => (row.order_available ? row.order_date : null),
  },
  {
    id: 'point_a',
    title: 'Точка A',
    field: 'point_a',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'point_b',
    title: 'Точка B',
    field: 'point_b',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'sales',
    title: 'Продажи',
    field: 'sales',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receipt',
    title: 'Приходы',
    field: 'receipt',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'settlement_inventory_without_optimization',
    title: 'Расчетный остаток без оптимизации',
    field: 'settlement_inventory_without_optimization',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'presentation_stock',
    title: 'Презентационный запас',
    field: 'presentation_stock',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'safety_stock',
    title: 'Страховой',
    field: 'safety_stock',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'other_types_minimum',
    title: 'Другие виды минимума',
    field: 'other_types_minimum',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'lower_line',
    title: 'Нижняя граница',
    field: 'lower_line',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'target_inventory',
    title: 'Целевой остаток',
    field: 'target_inventory',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'recommendation_receipt',
    title: 'Рекомендация по приходу',
    field: 'recommendation_receipt',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'recommendation_order',
    title: 'Рекомендация по заказу',
    field: 'recommendation_order',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'multiplicity',
    title: 'Кратность',
    field: 'multiplicity',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'service_plan',
    title: 'Плановый сервис',
    field: 'service_plan',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        integerMaxLength: 6,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'order',
    title: 'Заказ',
    field: 'order',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receipt_service',
    title: 'Приходы с учетом сервиса',
    field: 'receipt_service',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'inventory_order',
    title: 'Остаток с заказом',
    field: 'inventory_order',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'write_off',
    title: 'Списания',
    field: 'write_off',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 3,
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'product_price',
    title: 'Цена товара',
    field: 'product_price',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        isNotInteger: true,
        integerMaxLength: 6,
        fractionalMaxLength: 2,
        filterByUniqueValue: true,
      },
    },
  },
];

const tableProps = {
  columns,
  componentName: 'replenishment-plan',
  modelName: 'replenishmentPlan',
  tableColumnsSizeKey: 'replenishment-table-columns-size',
  reportIdKey: 'replenishment-report-id',
};

const ENDPOINTS = {
  TABLE_FILTERED: `${DOMAIN}/replenishment_plan/filtered/`,
  FILTERED_CONFIG: `${DOMAIN}/replenishment_plan/filtered/config/`,
  REPORTS: `${DOMAIN}/reports/`,
};

module.exports = {
  tableProps,
  ENDPOINTS,
};
