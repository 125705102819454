/* eslint-disable no-nested-ternary */
const moment = require('moment');

const getProductName = (row) => row.product?.name || null;
const getProductCategoryName = (row) => row.product_category?.name || null;
const getWarehouseName = (row) => row.warehouse?.name || null;
const getWarehouseCategoryName = (row) => row.warehouse_category?.name || null;
const getAuthorName = (row) => row.author || null;
const getCalendarName = (row) => row.calendar?.name || null;
const getPromoName = (row) => row.promo?.name || null;
const getPolicyName = (row) => row.policy?.name || null;
const getChainName = (row) => row.chain?.name || null;
const getStartAt = (row) => (row.start_at ? moment(row.start_at).format('YYYY-MM-DD') : null);
const getEndAt = (row) => (row.end_at ? moment(row.end_at).format('YYYY-MM-DD') : null);
const getCreatedAt = (row) => (row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : null);

// hierarchy lists getters
const getIdNameField = (row) => {
  if (row.nodeType === 'node') {
    return row.category?.name;
  }

  if (row.nodeType === 'leaf') {
    return row.id;
  }

  return null;
};
const getLeafsField = (row, field) => (row.nodeType === 'leaf' ? row[field] : null);

// catalog table leaf getters
const getLeafType = (row) => getLeafsField(row, 'type')?.name || null;
const getLeafName = (row) => getLeafsField(row, 'name');
const getLeafPromo = (row) => getLeafsField(row, 'promo')?.name || null;
const getLeafWarehouse = (row) => getLeafsField(row, 'warehouse')?.name || null;
const getLeafWarehouseCategory = (row) => getLeafsField(row, 'warehouse_category')?.name || null;
const getLeafPromoActivity = (row) => (row.nodeType === 'leaf' ? row?.promo?.is_active ? 'Активно' : 'Не активно' : null);
const getLeafDescription = (row) => getLeafsField(row, 'description');
const getLeafDuration = (row) => getLeafsField(row, 'duration');
const getLeafHasPromo = (row) => (row.nodeType === 'leaf' ? row.is_promo ? 'Да' : 'Нет' : null);
const getLeafCode = (row) => getLeafsField(row, 'code');
const getLeafPolicyName = (row) => getLeafsField(row, 'name');
const getLeafAuthor = (row) => getLeafsField(row, 'creator')?.username || null;
const getLeafLine = (row) => getLeafsField(row, 'line')?.name || null;
const getLeafArticle = (row) => getLeafsField(row, 'code');
const getLeafMechanicsBuy = (row) => getLeafsField(row, 'mechanics_buy')?.name || null;
const getLeafMechanicsExec = (row) => getLeafsField(row, 'mechanics_exec')?.name || null;
const getLeafUnionOrderFlag = (row) => (row.nodeType === 'leaf' ? row?.is_union_orders ? 'Да' : 'Нет' : null);
const getLeafLocation = (row) => getLeafsField(row, 'location');
const getLeafOpenDate = (row) => {
  const date = getLeafsField(row, 'open_at');
  return date ? moment(date).format('YYYY-MM-DD') : null;
};
const getLeafCloseDate = (row) => {
  const date = getLeafsField(row, 'closed_at');
  return date ? moment(date).format('YYYY-MM-DD') : null;
};
const getLeafFinishDate = (row) => {
  const date = getLeafsField(row, 'finish_at');
  return date ? moment(date).format('YYYY-MM-DD') : null;
};
const getLeafCreatedAt = (row) => {
  const date = getLeafsField(row, 'created_at');
  return date ? moment(date).format('YYYY-MM-DD') : null;
};
const getLeafStartAt = (row) => {
  const date = getLeafsField(row, 'start_at');
  return date ? moment(date).format('YYYY-MM-DD') : null;
};

module.exports = {
  getProductName,
  getProductCategoryName,
  getWarehouseName,
  getWarehouseCategoryName,
  getAuthorName,
  getCalendarName,
  getPromoName,
  getPolicyName,
  getChainName,
  getStartAt,
  getEndAt,
  getCreatedAt,
  getIdNameField,
  getLeafsField,
  getLeafType,
  getLeafName,
  getLeafPromo,
  getLeafWarehouse,
  getLeafWarehouseCategory,
  getLeafPromoActivity,
  getLeafDescription,
  getLeafDuration,
  getLeafHasPromo,
  getLeafCode,
  getLeafPolicyName,
  getLeafAuthor,
  getLeafCreatedAt,
  getLeafLine,
  getLeafArticle,
  getLeafStartAt,
  getLeafFinishDate,
  getLeafMechanicsBuy,
  getLeafMechanicsExec,
  getLeafUnionOrderFlag,
  getLeafLocation,
  getLeafOpenDate,
  getLeafCloseDate,
};
