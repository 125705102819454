var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M7.40411 5.98959C7.01359 5.59907 6.38042 5.59907 5.9899 5.98959C5.59938 6.38012 5.59938 7.01328 5.9899 7.40381L10.5861 12L5.9899 16.5962C5.59938 16.9867 5.59938 17.6199 5.9899 18.0104C6.38042 18.4009 7.01359 18.4009 7.40411 18.0104L12.0003 13.4142L16.5965 18.0104C16.987 18.4009 17.6202 18.4009 18.0107 18.0104C18.4012 17.6199 18.4012 16.9867 18.0107 16.5962L13.4145 12L18.0107 7.4038C18.4012 7.01328 18.4012 6.38012 18.0107 5.98959C17.6202 5.59907 16.987 5.59907 16.5965 5.98959L12.0003 10.5858L7.40411 5.98959Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }