<template>
  <div class="gp-grid">
    <a href="#" @click.prevent="addItem" class="gp-grid-add">
      <feather-icon name="plus"/>
    </a>
    <slot/>
    <grid-layout
      v-model:layout="layout"
      :col-num="colNum"
      :row-height="rowHeight"
      :is-draggable="true"
      :is-resizable="true"
      :responsive="responsive"
      :margin="[15,15]"
      :vertical-compact="verticalCompact"
      :use-css-transforms="useCssTransforms"
      @layout-created="layoutCreatedEvent"
      @layout-before-mount="layoutBeforeMountEvent"
      @layout-mounted="layoutMountedEvent"
      @layout-ready="layoutReadyEvent"
      @layout-updated="layoutUpdatedEvent">
      <grid-item
        v-for="item in layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :data-i="item.i"
        tabindex="0"
        drag-allow-from=".grid-item-head"
        drag-ignore-from=".grid-item-body"
        @resize="resizeEvent"
        @move="moveEvent"
        @resized="resizedEvent"
        @container-resized="containerResizedEvent"
        @moved="movedEvent">
        <div class="grid-item-head">
          <h2><l10n :value="item.name"/></h2>
          <a href="#" @click.prevent="showItemMenu = item">
            <feather-icon name="more-vertical"/>
          </a>
        </div>
        <div class="grid-item-body">
          <div v-if="flippedItems[item.i]">
            <form @submit.prevent="flipItem(item)">
              <table class="grid-item-settings">
                <tbody>
                  <tr>
                    <td><label><l10n value="Name"/></label></td>
                    <td><input class="form-control form-control" :value="item.name"
                               @change="$set(item, 'name', $event.target.value)"/></td>
                  </tr>
                  <tr>
                    <td><label><l10n value="Path"/></label></td>
                    <td><input class="form-control form-control" :value="item.path"
                               @change="$set(item, 'path', $event.target.value)"/></td>
                  </tr>
                </tbody>
              </table>
              <div class="form-group" style="text-align: right;">
                <button class="btn btn-primary" @click="flipItem(item)">
                  <l10n value="Done"/>
                </button>
              </div>
            </form>
          </div>
          <gp-page
            v-else-if="item.type == 'page'"
            :key="refreshKeys[item.i]"
            :path="item.path"
            :darkTheme="darkTheme"
            :config="config"
            :username="username"
            :bounds="bounds"
            :storageKey="storageKey ? `${storageKey}.${item.i}` : null"
          />
        </div>
      </grid-item>
    </grid-layout>
    <my-popup
      v-if="showItemMenu"
      @escape="showItemMenu = null"
      @clickoutside="showItemMenu = null"
      placement="bottom-center"
      :anchor='`.gp-grid .vue-grid-item[data-i="${showItemMenu.i}"] .grid-item-head a`'>
      <div class="popover show gp-grid-menu">
        <div class="popover-body">
          <ul>
            <li>
              <a href="#" @click.prevent="refreshItem(showItemMenu); showItemMenu = null">
                <feather-icon name="refresh-cw"/>
                <l10n value="Refresh"/>
              </a>
            </li>
            <li>
              <a :href="showItemMenu.path.startsWith('http') ? showItemMenu.path : `/pages/${showItemMenu.path}`" @click="showItemMenu = null" target="_blank">
                <feather-icon name="external-link"/>
                <l10n value="Open"/>
              </a>
            </li>
            <li>
              <a href="" @click.prevent="deleteItem(showItemMenu); showItemMenu = null">
                <feather-icon name="x"/>
                <l10n value="Remove"/>
              </a>
            </li>
            <li>
              <a href="#" @click.prevent="flipItem(showItemMenu); showItemMenu = null">
                <feather-icon name="settings"/>
                <l10n value="Settings"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </my-popup>
    <my-popup
      v-if="showGridMenu"
      @escape="showGridMenu = false"
      @clickoutside="showGridMenu = false"
      placement="bottom-center"
      :anchor="$refs.gridMenuLink">
      <div class="popover show gp-grid-menu">
        <div class="popover-body">
          <ul>
            <li>
              <a href="#" @click.prevent="addItem(); showGridMenu = false">
                <feather-icon name="plus"/>
                <l10n value="Add new widget"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </my-popup>
  </div>
</template>
<script>
module.exports = {
  model: {
    prop: 'layout',
    event: 'change',
  },
  props: {
    darkTheme: { type: Boolean },
    config: { type: Object },
    username: { type: String },
    bounds: { type: String },
    storageKey: { type: String },

    colNum: { type: Number, default: 12 },
    rowHeight: { type: Number, default: 50 },
    newItemWidth: { type: Number, default: 4 },
    newItemHeight: { type: Number, default: 8 },
    verticalCompact: { type: Boolean, default: true },
    useCssTransforms: { type: Boolean, default: true },

    layout: { type: Array, default: () => [] },
    responsive: { type: Boolean, default: false },
  },
  data() {
    return {
      showItemMenu: null,
      showGridMenu: false,
      flippedItems: {},
      refreshKeys: {},
    };
  },
  watch: {
    layout: {
      deep: true,
      handler() {
        this.$emit('change', this.layout);
      },
    },
  },
  methods: {
    refreshItem(item) {
      this.$set(this.refreshKeys, item.i, (this.refreshKeys[item.i] || 0) + 1);
    },
    flipItem(item) {
      this.$set(this.flippedItems, item.i, !this.flippedItems[item.i]);
      if (this.flippedItems[item.i]) {
        this.$nextTick(() => $(`[data-i="${item.i}"] input`).first().focus());
      }
    },
    addItem() {
      let i = utils.randomId();
      let n = 0;
      let x = 0;
      let y = 0;
      let w = 4;
      let h = 4;
      let collides = (a,b) => {
        let x_out = a.x + a.w <= b.x || a.x >= b.x + b.w;
        let y_out = a.y + a.h <= b.y || a.y >= b.y + b.h;
        return !(x_out || y_out);
      };
      for (;;n++) {
        x = n % this.colNum;
        y = Math.floor(n / this.colNum);
        if (x + w > this.colNum) {
          continue;
        }
        let collison = false;
        for (let item of this.layout) {
          if (collides(item, { x,y,w,h })) {
            collison = true;
          }
        }
        if (collison) {
          continue;
        }
        break;
      }

      this.$set(this.flippedItems, i, true);
      let item = {
        i,
        x,
        y,
        w,
        h,
        name: '',
        type: 'page',
        path: '',
      };
      this.layout.push(item);
      this.$nextTick(() => $(`[data-i="${i}"] input`).first().focus());
    },
    deleteItem(item) {
      if (window.confirm(
        utils.l10n('Are sure you want to remove widget {widget}?')
          .replace('{widget}', item.name))) {
        this.layout.splice(this.layout.indexOf(item), 1);
      }
    },
    moveEvent: function(i, newX, newY){
    },
    movedEvent: function(i, newX, newY){
    },
    resizeEvent: function(i, newH, newW, newHPx, newWPx){
    },
    resizedEvent: function(i, newX, newY, newHPx, newWPx){
    },
    containerResizedEvent: function(i, newH, newW, newHPx, newWPx){
    },
    layoutCreatedEvent: function(newLayout){
    },
    layoutBeforeMountEvent: function(newLayout){
    },
    layoutMountedEvent: function(newLayout){
    },
    layoutReadyEvent: function(newLayout){
    },
    layoutUpdatedEvent: function(newLayout){
    },
  },
};
</script>
<style>
.grid-item-settings {
    width: 100%;
    margin-bottom: 10px;
}
.grid-item-settings td {
    padding: 4px 0;
}
.grid-item-settings label {
    margin: 0;
    margin-right: 10px;
}
.grid-item-settings input {
    width: 100%;
}
.gp-grid > a {
    float: right;
}
.gp-grid > a svg {
    width: 20px;
}
.gp-grid-menu .popover-body > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.gp-grid-menu .popover-body > ul > li {
    padding: 2px 0;
}
.gp-grid-menu .popover-body > ul > li svg {
    width: 16px;
    margin: -3px 0;
    vertical-align: top;
    display: inline-block;
}
#search-grid {
    display: flex;
    flex-wrap: wrap;
}
#search-grid .form-check {
    display: none;
}
#search-grid .gp-filter {
    font-size: 1em;
    margin-top: 2px;
}
.grid-item-head {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    padding-bottom: 0;
    border-bottom: 1px solid var(--light);
    background: rgb(250,250,250);
}
.grid-item-head > h2 {
    font-weight: 400;
    font-size: 18px;
    flex-grow: 1;
}
.grid-item-head > a svg {
    width: 18px;
    vertical-align: top;
}
.grid-item-body {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 10px 15px;
    background: white;
}
.grid-item-body h1 {
    display: none;
}
/*.grid-item-body .kpi-add,*/
/*.grid-item-body .kpi-edit {*/
/*    display: none;*/
/*}*/
.vue-grid-item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.vue-grid-item .kpis {
    float: none;
    margin: 0;
    justify-content: flex-start;
}
.vue-grid-item .kpis .my-popup-popup {
    /*position: fixed!important;*/
    /*z-index: 10000;*/
}
.vue-grid-layout {
    /*background: #eee;*/
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    border: 1px solid var(--gray);
    border-radius: 4px;
}
.vue-grid-item:focus {
    z-index:1 ;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
.vue-grid-item.vue-grid-placeholder {
    background: var(--cyan)!important;
}
</style>
