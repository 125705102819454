var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "accordions-container" },
    _vm._l([{}].concat(_vm.datasets), function(dataset, i) {
      return _c("my-accordion", {
        key: dataset.label,
        ref: "accordion-" + i,
        refInFor: true,
        attrs: { openedByDefault: dataset === _vm.datasets[0] },
        on: {
          accordionOpened: function($event) {
            return _vm.handleAccordionOpened("accordion-" + i)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _c(
                    "span",
                    { staticClass: "accordion-title" },
                    [
                      _c("l10n", {
                        attrs: {
                          value: i === 0 ? "Общие настройки" : dataset.label
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [
                  i === 0
                    ? _c("common-settings")
                    : _c("dataset-settings", {
                        attrs: { index: i - 1, datasets: _vm.datasets }
                      })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }