<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <transition name="modal-fade">
    <div
      v-if="isVisible"
      class="custom-modal__wrapper"
      :class="[`y-${positionY}`, `x-${positionX}`]"
      @click.self="closeModal"
    >
      <div
        class="custom-modal"
        :style="{ minWidth }"
      >
        <header class="custom-modal__header">
          <p class="custom-modal__title">{{ modalTitle }}</p>
          <button
            type="button"
            v-if="closeButton && closable"
            class="custom-modal__close-btn"
            @click="closeModal"
          >
            <cross-icon />
          </button>
        </header>
        <main class="custom-modal__main" :class="{ separated: sectionSeparated }">
          <slot name="main" />
        </main>
        <footer class="custom-modal__footer" v-if="$slots.footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
const CrossIcon = require('../assets/icons/cross-icon.vue').default;

module.exports = {
  name: 'autoorder-modal',

  components: {
    CrossIcon,
  },

  props: {
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Закрыть модальное окно',
    },
    modalVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    positionY: {
      type: String,
      required: false,
      default: 'center', // 'top' | 'center' | 'bottom'
    },
    positionX: {
      type: String,
      required: false,
      default: 'center', // 'left' | 'center' | 'right'
    },
    sectionSeparated: {
      type: Boolean,
      required: false,
      default: false,
    },
    minWidth: {
      type: String,
      required: false,
      default: '30%',
    },
  },

  data() {
    return {
      isVisible: this.modalVisible,
    };
  },

  watch: {
    modalVisible(newValue) {
      this.isVisible = newValue;
    },
    isVisible(newValue) {
      if (newValue) {
        this.$emit('onOpen');
      }
    },
  },

  methods: {
    closeModal() {
      if (this.closable) {
        this.isVisible = false;
        this.$emit('onClose');
      }
    },
  },
};
</script>

<style scoped>
.custom-modal {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  max-width: 75vw;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-modal__wrapper {
  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
}

.custom-modal__wrapper.y-center {
  align-items: center;
}

.custom-modal__wrapper.y-top {
  align-items: flex-start;
  padding-top: 24px;
}

.custom-modal__wrapper.y-bottom {
  align-items: flex-end;
  padding-bottom: 24px;
}

.custom-modal__wrapper.x-center {
  justify-content: center;
}

.custom-modal__wrapper.x-left {
  justify-content: flex-start;
  padding-left: 24px;
}

.custom-modal__wrapper.x-right {
  justify-content: flex-end;
  padding-right: 24px;
}

.custom-modal__header {
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-modal__main {
  padding: 10px 20px 10px 20px;
  max-height: 75vh;
  overflow-y: auto;
}

.custom-modal__main.separated {
  border-top: 1px solid #E9E9E9;
  border-bottom: 1px solid #E9E9E9;
}

.custom-modal__footer {
  padding: 10px 20px 10px 20px;
}

.custom-modal__close-btn {
  background-color: #F7F7F7;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  transition: 0.1s all ease-in;
  cursor: pointer;
}

.custom-modal__close-btn:hover {
  background-color: #E9E9E9;
}

.custom-modal__close-btn:active {
  opacity: 0.7;
}

.custom-modal__close-btn svg {
  width: 24px;
  height: 24px;
}

.custom-modal__title {
  max-height: 100px;
  overflow-y: auto;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .3s;
}

.modal-fade-enter-active .custom-modal,
.modal-fade-leave-active .custom-modal {
  transition: all .3s;
  transform: translateY(0);
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter .custom-modal,
.modal-fade-leave-to .custom-modal {
  opacity: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 1399px) {
  .custom-modal  {
    max-width: 90vw;
  }
}
</style>
