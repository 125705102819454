var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-settings" },
    [
      _c("div", { staticClass: "commmon-settings__checkboxes-container" }, [
        _c(
          "label",
          {
            staticClass:
              "common-settings__checkbox common-settings__checkbox--first",
            attrs: { for: "legendDisplay" }
          },
          [
            _c("ao-checkbox", {
              attrs: {
                id: "legendDisplay",
                checked: _vm.currentLegend.display
              },
              on: { onChange: _vm.changeLegendDisplay }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Показывать легенду" } })
          ],
          1
        ),
        _vm._v(" "),
        _vm.currentLegend.display
          ? _c(
              "label",
              {
                staticClass: "common-settings__checkbox",
                attrs: { for: "legendReverse" }
              },
              [
                _c("ao-checkbox", {
                  attrs: {
                    id: "legendReverse",
                    checked: _vm.currentLegend.reverse
                  },
                  on: { onChange: _vm.changeLegendReverse }
                }),
                _vm._v(" "),
                _c("l10n", { attrs: { value: "Развернуть легенду" } })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("my-transition-expand", [
        _vm.currentLegend.display
          ? _c("div", [
              _c("div", { staticClass: "common-settings__inputs-container" }, [
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "span",
                      { staticClass: "inputblock__label" },
                      [
                        _c("l10n", { attrs: { value: "Расположение легенды" } })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("my-multiselect", {
                      attrs: {
                        selectedOption: _vm.legendPositions.find(function(pos) {
                          return pos.value === _vm.currentLegend.position
                        }).title,
                        options: _vm.legendPositions.map(function(item) {
                          return item.title
                        }),
                        label: "legendPosition",
                        placeholder: "Выберите расположение легенды"
                      },
                      on: { select: _vm.selectLegendPosition }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "legendLabelFontSize" }
                      },
                      [_c("l10n", { attrs: { value: "Размер шрифта" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("ao-input", {
                      attrs: {
                        id: "legendLabelFontSize",
                        type: "number",
                        placeholder: "Введите размер шрифта",
                        onlyInteger: "",
                        invalid: false
                      },
                      on: { onInput: _vm.updateLegengLabelFontSize },
                      model: {
                        value: _vm.currentLegend.labels.fontSize,
                        callback: function($$v) {
                          _vm.$set(_vm.currentLegend.labels, "fontSize", $$v)
                        },
                        expression: "currentLegend.labels.fontSize"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "span",
                      { staticClass: "inputblock__label" },
                      [_c("l10n", { attrs: { value: "Стиль текста" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("my-multiselect", {
                      attrs: {
                        selectedOption: _vm.legendLabelStyleSelectedOption,
                        options: _vm.fontStyles.map(function(item) {
                          return item.title
                        }),
                        label: "fontStyles",
                        placeholder: "Выберите стиль текста"
                      },
                      on: { select: _vm.legendLabelStyleSelect }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "common-settings__checkbox",
                    attrs: { for: "usePointStyle" }
                  },
                  [
                    _c("ao-checkbox", {
                      attrs: {
                        id: "usePointStyle",
                        checked: _vm.currentLegend.labels.usePointStyle
                      },
                      on: { onChange: _vm.changeUsePointStyle }
                    }),
                    _vm._v(" "),
                    _c("l10n", { attrs: { value: "Повторять стиль точки" } })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "commmon-settings__labelsettings-container" },
                [
                  _c(
                    "div",
                    { staticClass: "inputblock" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "inputblock__label",
                          attrs: { for: "labelLegendPadding" }
                        },
                        [
                          _c("l10n", {
                            attrs: { value: "Отступы элементов легенды" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ao-input", {
                        attrs: {
                          id: "labelLegendPadding",
                          type: "number",
                          placeholder: "Введите размер отступов",
                          onlyInteger: "",
                          invalid: false
                        },
                        on: { onInput: _vm.updateLegendLabelPadding },
                        model: {
                          value: _vm.currentLegend.labels.padding,
                          callback: function($$v) {
                            _vm.$set(_vm.currentLegend.labels, "padding", $$v)
                          },
                          expression: "currentLegend.labels.padding"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inputblock" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "inputblock__label",
                          attrs: { for: "labelLegendBoxWidth" }
                        },
                        [
                          _c("l10n", {
                            attrs: {
                              value: "Размер контейнера элементов легенды"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ao-input", {
                        attrs: {
                          id: "labelLegendBoxWidth",
                          type: "number",
                          placeholder: "Введите ширину контейнера",
                          onlyInteger: "",
                          invalid: false
                        },
                        on: { onInput: _vm.updateLegendLabelBoxWidth },
                        model: {
                          value: _vm.currentLegend.labels.boxWidth,
                          callback: function($$v) {
                            _vm.$set(_vm.currentLegend.labels, "boxWidth", $$v)
                          },
                          expression: "currentLegend.labels.boxWidth"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "common-settings__colorinput" }, [
                    _c(
                      "label",
                      {
                        staticClass: "common-settings__colorinput",
                        attrs: { for: "legendLabelColor" }
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "color",
                            id: "legendLabelColor",
                            name: "legendLabelColorpicker`"
                          },
                          domProps: {
                            value: _vm.currentLegend.labels.fontColor
                          },
                          on: { input: _vm.selectLegendLabelsColor }
                        }),
                        _vm._v(" "),
                        _c("l10n", { attrs: { value: "Цвет текста" } })
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass:
            "common-settings__checkbox common-settings__checkbox--first",
          attrs: { for: "titleDisplay" }
        },
        [
          _c("ao-checkbox", {
            attrs: { id: "titleDisplay", checked: _vm.currentTitle.display },
            on: { onChange: _vm.changeTitleDisplay }
          }),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Показывать название графика" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("my-transition-expand", [
        _vm.currentTitle.display
          ? _c("div", [
              _c("div", { staticClass: "common-settings__inputs-container" }, [
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "chartTitle" }
                      },
                      [_c("l10n", { attrs: { value: "Название графика" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("ao-input", {
                      attrs: {
                        id: "chartTitle",
                        placeholder: "Введите название графика",
                        invalid: false
                      },
                      on: { onInput: _vm.updateChartTitle },
                      model: {
                        value: _vm.currentTitle.text,
                        callback: function($$v) {
                          _vm.$set(_vm.currentTitle, "text", $$v)
                        },
                        expression: "currentTitle.text"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "titleFontSize" }
                      },
                      [_c("l10n", { attrs: { value: "Размер шрифта" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("ao-input", {
                      attrs: {
                        id: "titleFontSize",
                        type: "number",
                        placeholder: "Введите размер шрифта",
                        onlyInteger: "",
                        invalid: false
                      },
                      on: { onInput: _vm.updateTitleFontSize },
                      model: {
                        value: _vm.currentTitle.fontSize,
                        callback: function($$v) {
                          _vm.$set(_vm.currentTitle, "fontSize", $$v)
                        },
                        expression: "currentTitle.fontSize"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "titlePadding" }
                      },
                      [
                        _c("l10n", { attrs: { value: "Вертикальные отступы" } })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("ao-input", {
                      attrs: {
                        id: "titlePadding",
                        type: "number",
                        placeholder: "Введите размер отступов",
                        onlyInteger: "",
                        invalid: false
                      },
                      on: { onInput: _vm.updateTitlePadding },
                      model: {
                        value: _vm.currentTitle.padding,
                        callback: function($$v) {
                          _vm.$set(_vm.currentTitle, "padding", $$v)
                        },
                        expression: "currentTitle.padding"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-settings__inputs-container" }, [
                _c(
                  "label",
                  {
                    staticClass: "common-settings__colorinput",
                    attrs: { for: "chartTitleColor" }
                  },
                  [
                    _c("input", {
                      attrs: {
                        type: "color",
                        id: "chartTitleColor",
                        name: "chartTitleColorpicker`"
                      },
                      domProps: { value: _vm.currentTitle.fontColor },
                      on: { input: _vm.selectTitleColor }
                    }),
                    _vm._v(" "),
                    _c("l10n", { attrs: { value: "Цвет текста" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inputblock" },
                  [
                    _c(
                      "span",
                      { staticClass: "inputblock__label" },
                      [_c("l10n", { attrs: { value: "Стиль текста" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("my-multiselect", {
                      attrs: {
                        selectedOption: _vm.titleStyleSelectedOption,
                        options: _vm.fontStyles.map(function(item) {
                          return item.title
                        }),
                        label: "fontStyles",
                        placeholder: "Выберите стиль текста"
                      },
                      on: { select: _vm.fontStyleSelect }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "commmon-settings__checkboxes-container" }, [
        _c(
          "label",
          {
            staticClass:
              "common-settings__checkbox common-settings__checkbox--first",
            attrs: { for: "xGridlinesDisplay" }
          },
          [
            _c("ao-checkbox", {
              attrs: {
                id: "xGridlinesDisplay",
                checked: _vm.currentScales.xAxes[0].gridLines.display
              },
              on: { onChange: _vm.changeXGridlinesDisplay }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Вертикальные линии сетки" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "common-settings__checkbox",
            attrs: { for: "yGridlinesDisplay" }
          },
          [
            _c("ao-checkbox", {
              attrs: {
                id: "yGridlinesDisplay",
                checked: _vm.currentScales.yAxes[0].gridLines.display
              },
              on: { onChange: _vm.changeYGridlinesDisplay }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Горизонтальные линии сетки" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "common-settings__inputs-container" }, [
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "xGridLinesWidth" }
              },
              [_c("l10n", { attrs: { value: "Ширина вертикальных линии" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              attrs: {
                id: "xGridLinesWidth",
                type: "number",
                min: "0",
                placeholder: "Введите ширину линии",
                onlyInteger: "",
                invalid: false
              },
              on: { onInput: _vm.gridLinesWidthUpdate },
              model: {
                value: _vm.currentScales.xAxes[0].gridLines.lineWidth,
                callback: function($$v) {
                  _vm.$set(
                    _vm.currentScales.xAxes[0].gridLines,
                    "lineWidth",
                    $$v
                  )
                },
                expression: "currentScales.xAxes[0].gridLines.lineWidth"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "yGridLinesWidth" }
              },
              [_c("l10n", { attrs: { value: "Ширина горизонтальных линии" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              attrs: {
                id: "yGridLinesWidth",
                type: "number",
                min: "0",
                placeholder: "Введите ширину линии",
                onlyInteger: "",
                invalid: false
              },
              on: { onInput: _vm.gridLinesWidthUpdate },
              model: {
                value: _vm.currentScales.yAxes[0].gridLines.lineWidth,
                callback: function($$v) {
                  _vm.$set(
                    _vm.currentScales.yAxes[0].gridLines,
                    "lineWidth",
                    $$v
                  )
                },
                expression: "currentScales.yAxes[0].gridLines.lineWidth"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "canvasRadius" }
              },
              [_c("l10n", { attrs: { value: "Радиус закругления" } })],
              1
            ),
            _vm._v(" "),
            _c("ao-input", {
              attrs: {
                id: "canvasRadius",
                type: "number",
                min: "0",
                placeholder: "Введите радиус закругления",
                onlyInteger: "",
                invalid: false
              },
              on: { onInput: _vm.updateCanvasRadius },
              model: {
                value: _vm.currentCanvas.borderRadius,
                callback: function($$v) {
                  _vm.$set(_vm.currentCanvas, "borderRadius", $$v)
                },
                expression: "currentCanvas.borderRadius"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "common-settings__colorinput",
            attrs: { for: "canvasColor" }
          },
          [
            _c("input", {
              attrs: {
                type: "color",
                id: "canvasColor",
                name: "canvasColorpicker"
              },
              domProps: { value: _vm.currentCanvas.backgroundColor },
              on: { input: _vm.selectCanvasColor }
            }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Цвет фона полотна" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("h6", { staticStyle: { "margin-top": "5px" } }, [
        _vm._v("Отступы полотна графика")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commmon-settings__paddingsettings-container" },
        [
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                {
                  staticClass: "inputblock__label",
                  attrs: { for: "canvasPaddingLeft" }
                },
                [_c("l10n", { attrs: { value: "Слева" } })],
                1
              ),
              _vm._v(" "),
              _c("ao-input", {
                attrs: {
                  id: "canvasPaddingLeft",
                  type: "number",
                  min: "0",
                  placeholder: "Введите отступ слева",
                  onlyInteger: "",
                  invalid: false
                },
                on: { onInput: _vm.updateCanvasPadding },
                model: {
                  value: _vm.currentLayout.padding.left,
                  callback: function($$v) {
                    _vm.$set(_vm.currentLayout.padding, "left", $$v)
                  },
                  expression: "currentLayout.padding.left"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                {
                  staticClass: "inputblock__label",
                  attrs: { for: "canvasPaddingRight" }
                },
                [_c("l10n", { attrs: { value: "Справа" } })],
                1
              ),
              _vm._v(" "),
              _c("ao-input", {
                attrs: {
                  id: "canvasPaddingRight",
                  type: "number",
                  min: "0",
                  placeholder: "Введите отступ справа",
                  onlyInteger: "",
                  invalid: false
                },
                on: { onInput: _vm.updateCanvasPadding },
                model: {
                  value: _vm.currentLayout.padding.right,
                  callback: function($$v) {
                    _vm.$set(_vm.currentLayout.padding, "right", $$v)
                  },
                  expression: "currentLayout.padding.right"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                {
                  staticClass: "inputblock__label",
                  attrs: { for: "canvasPaddingTop" }
                },
                [_c("l10n", { attrs: { value: "Сверху" } })],
                1
              ),
              _vm._v(" "),
              _c("ao-input", {
                attrs: {
                  id: "canvasPaddingTop",
                  type: "number",
                  min: "0",
                  placeholder: "Введите отступ сверху",
                  onlyInteger: "",
                  invalid: false
                },
                on: { onInput: _vm.updateCanvasPadding },
                model: {
                  value: _vm.currentLayout.padding.top,
                  callback: function($$v) {
                    _vm.$set(_vm.currentLayout.padding, "top", $$v)
                  },
                  expression: "currentLayout.padding.top"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                {
                  staticClass: "inputblock__label",
                  attrs: { for: "canvasPaddingBottom" }
                },
                [_c("l10n", { attrs: { value: "Снизу" } })],
                1
              ),
              _vm._v(" "),
              _c("ao-input", {
                attrs: {
                  id: "canvasPaddingBottom",
                  type: "number",
                  min: "0",
                  placeholder: "Введите отступ снизу",
                  onlyInteger: "",
                  invalid: false
                },
                on: { onInput: _vm.updateCanvasPadding },
                model: {
                  value: _vm.currentLayout.padding.bottom,
                  callback: function($$v) {
                    _vm.$set(_vm.currentLayout.padding, "bottom", $$v)
                  },
                  expression: "currentLayout.padding.bottom"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }