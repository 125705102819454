<template>
  <div class="chains" id="chains">
    <header class="chains__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="chains__main">
      <keep-alive v-if="currentComponent" :include="['chains-catalog']">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const {
  getExistTabCounts, getCardCounter, sortTabsByCounter, isTabExist, removeTab,
} = require('../../utils/cardTabsHelpers');
const {
  COMPONENT_NAMES,
  chainTabsStorageKey,
  chainEntityStorageKey,
  chainRulesEntityStorageKey,
  newChain,
  newChainRule,
  catalogTab,
  registerTab,
} = require('../../constants/chains');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const ChainCard = (resolve) => {
  require(['./chain-card.vue'], resolve);
};
const ChainsCatalog = (resolve) => {
  require(['./chains-catalog.vue'], resolve);
};
const ChainRulesCard = (resolve) => {
  require(['./chain-rules-card.vue'], resolve);
};
const ChainsRegister = (resolve) => {
  require(['./chains-register.vue'], resolve);
};
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  ChainsCatalog,
  ChainsRegister,
  ChainCard,
  ChainRulesCard,
  AoTabs,
  CloseTabModal,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      // https://ru.vuejs.org/v2/api/#provide-inject
      reactiveProvidedChainData: this.reactiveData,

      chainCard: {
        entityStorageKey: chainEntityStorageKey,
        openCatalog: this.openCatalog,
        createNew: this.createNewChainCard,
        duplicate: this.duplicateChainCard,
        openExist: this.openExistChainCard,
        onSuccessfulCreate: this.onSuccessfulCreateChain,
      },

      chainRule: {
        entityStorageKey: chainRulesEntityStorageKey,
        openRegister: this.openRegister,
        createNew: this.createNewRulesCard,
        duplicate: this.duplicateRulesCard,
        openExist: this.openExistRulesCard,
        onSuccessfulCreate: this.onSuccessfulCreateRule,
      },
    };
  },

  data() {
    return {
      tabs: [
        catalogTab,
        registerTab,
      ],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
        duplicatedChainState: null,
        duplicatedRuleState: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    chainTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.chain)
        .map(({ id }) => id);
    },
    ruleTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.rules)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: chainTabsStorageKey, tabs: newTabs });

      clearCardStatesCache({
        entity: chainEntityStorageKey,
        tabIds: this.chainTabIds,
      });

      clearCardStatesCache({
        entity: chainRulesEntityStorageKey,
        tabIds: this.ruleTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(chainTabsStorageKey);
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.chain:
            this.openCatalog();
            break;

          case COMPONENT_NAMES.rules:
            this.openRegister();
            break;

          default:
            this.openCatalog();
            break;
        }
      }
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },

    // open static component tab
    openCatalog() {
      this.selectTab(catalogTab);
    },
    openRegister() {
      this.selectTab(registerTab);
    },

    // open dynamic component tab
    openChainCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка цепочки - ${id}`,
        componentName: COMPONENT_NAMES.chain,
      };

      this.selectOrAddTab(newTab);
    },
    openExistChainCard(id) {
      this.openChainCard({ id });
    },
    openRulesCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка привязки - ${id}`,
        componentName: COMPONENT_NAMES.rules,
      };

      this.selectOrAddTab(newTab);
    },
    openExistRulesCard(id) {
      this.openRulesCard({ id });
    },

    // create new cards
    createNewChainCard() {
      const counts = getExistTabCounts(newChain, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newChain}-${counter}`,
        title: `Новая карточка - ${counter}`,
        isNew: true,
      };

      this.openChainCard(tabBody);
    },
    createNewRulesCard() {
      const counts = getExistTabCounts(newChainRule, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newChainRule}-${counter}`,
        title: `Новая привязка - ${counter}`,
        isNew: true,
      };

      this.openRulesCard(tabBody);
    },

    // duplicate cards
    duplicateChainCard(cardState) {
      this.reactiveData.duplicatedChainState = cardState;
      this.createNewChainCard();
    },
    duplicateRulesCard(cardState) {
      this.reactiveData.duplicatedRuleState = cardState;
      this.createNewRulesCard();
    },

    // successful create card handle
    onSuccessfulCreateChain(id) {
      if (!id) {
        return;
      }

      const newChainCard = { id, title: `Карточка цепочки - ${id}`, componentName: COMPONENT_NAMES.chain };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newChainCard : card));
      this.selectTab(newChainCard);
    },
    onSuccessfulCreateRule(id) {
      if (!id) {
        return;
      }

      const newRuleCard = { id, title: `Карточка привязки - ${id}`, componentName: COMPONENT_NAMES.rules };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newRuleCard : card));
      this.selectTab(newRuleCard);
    },
  },
};
</script>

<style  scoped>
.chains {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow: auto;
  padding: 12px;
}

.chains__main {
  flex: 1 0 auto;
}
</style>
