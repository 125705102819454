<template>
  <div class="gp-table-search">
    <div>
      <a href="javascript:void(0)" @click="expanded = !expanded">
        <feather-icon name="search"/>
      </a>
      <input
        v-if="expanded"
        class="form-control form-control-sm"
        ref="search"
        type="search"
        :placeholder="l10n(placeholder)"
        @search="value = $event.target.value"/>
    </div>
  </div>
</template>
<script>
let utils = require('../my-utils');
module.exports = {
  props: {
    placeholder: { type: String, default: 'Search...' },
  },
  data() {
    return {
      l10n: utils.l10n,
      value: '',
      expanded: false,
    };
  },
  watch: {
    value() {
      this.$emit('change', this.value);
    },
    expanded() {
      if (this.expanded) {
        Vue.nextTick(() => this.$refs.search?.focus());
      } else {
        this.value = '';
      }
    },
  },
};
</script>
<style>
.gp-table-search {
	display: inline-block;
}
.gp-table-search > div {
	display: flex;
}
.gp-table-search > div > a {
}
.gp-table-search > div > .form-control {
	margin-left: 6px;
	width: 150px;
    border: none;
    padding: 0 4px;
    height: 24px;
}
</style>
