<template>
  <label class="ao-checkbox" :class="{ 'ao-checkbox_checked': isChecked, 'ao-checkbox_disabled': disabled }">
    <check-icon v-if="isChecked" />
    <input :id="id" :disabled="disabled" class="ao-checkbox__input" type="checkbox" :checked="isChecked" @change="handleChangeEvent" name="ao-checkbox" />
  </label>
</template>

<script>
const CheckIcon = require('../../assets/icons/check-icon.vue').default;

module.exports = {
  components: {
    CheckIcon,
  },
  model: {
    prop: 'checked',
    event: 'onChange',
  },
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isChecked: this.checked,
    };
  },

  watch: {
    checked(newValue) {
      this.isChecked = newValue;
    },
  },

  methods: {
    handleChangeEvent(event) {
      this.$emit('onChange', event.target.checked);
    },
  },
};
</script>

<style scoped>
.ao-checkbox {
  width: 16px;
  height: 16px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #AFAFAF;
  background-color: transparent;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.ao-checkbox:not(.ao-checkbox_disabled):hover {
  border-color: #9B9B9B;
}

.ao-checkbox_disabled {
  border-color: #E9E9E9;
  cursor: not-allowed;
}

.ao-checkbox_checked.ao-checkbox_disabled {
  background-color: #D4D4D4;
  color: #E9E9E9;
}

.ao-checkbox_checked {
  background-color: #5295D5;
  border: none;
}

.ao-checkbox_checked:not(.ao-checkbox_disabled):hover {
  background-color: #3C80C1;
}

.ao-checkbox__input {
  display: none;
}
</style>
