var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [
            _c("div"),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("div")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isDataLoaded
        ? _c("chart", { ref: "chart", attrs: { data: _vm.data } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ao-chart__button-container" },
        [
          _c(
            "ao-button",
            {
              staticClass: "modal-btn",
              attrs: { type: "text" },
              on: { click: _vm.onModalOpen }
            },
            [_vm._v("\n      Настройки графика\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("chart-modal", {
        attrs: {
          headerTitle: "Настройки графика",
          datasets: _vm.data.datasets
        },
        on: { "chart-updated": _vm.updateChart },
        model: {
          value: _vm.isModalOpened,
          callback: function($$v) {
            _vm.isModalOpened = $$v
          },
          expression: "isModalOpened"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }