const { mathsFilterRules, textFilterRules } = require('../constants/filterRules');

const getFeatureValue = (item) => `${item?.feature_name?.name}: ${item?.value}`;

const getTrimmedString = (value) => (value ? value.trim() : value);

const getSortingFilteringRequestBody = (tableParams, additionalPayload) => {
  let requestBody = { ...additionalPayload };
  const tableParamsKeys = Object.keys(tableParams || {});

  if (tableParamsKeys.length) {
    let filtering = {};
    let sorting = {};

    tableParamsKeys.forEach((field) => {
      const { filter, sortBy, uniqueValues } = tableParams[field];

      if (filter?.rule) {
        filtering = {
          ...filtering,
          [field]: {
            [filter.rule.id]: filter.value,
          },
        };
      }

      if (uniqueValues?.length) {
        filtering = {
          ...filtering,
          [field]: {
            ...filtering[field],
            ...(uniqueValues?.length ? { oneof: uniqueValues } : {}),
          },
        };
      }

      sorting = sortBy ? { ...sorting, [field]: sortBy } : sorting;
    });

    requestBody = {
      filtering: { ...requestBody.filtering, ...filtering },
      sorting: { ...requestBody.sorting, ...sorting },
    };
  }

  return requestBody;
};

const getConfigFromRequestBody = (body) => {
  const config = {};
  const filters = body?.filtering || null;
  const sorting = body?.sorting || null;

  if (sorting) {
    Object.entries(sorting).forEach(([key, value]) => {
      config[key] = config[key] ? { ...config[key], sortBy: value } : { sortBy: value };
    });
  }

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.oneof && Array.isArray(value.oneof)) {
        const uniqueValues = [...value.oneof];
        config[key] = config[key]
          ? { ...config[key], uniqueValues } : { uniqueValues };
      } else {
        const [ruleId, ruleValue] = Object.entries(value)[0];
        const rule = [...mathsFilterRules, ...textFilterRules].find(
          (_rule) => _rule.id === ruleId,
        );
        const filter = {
          rule,
          value: ruleValue,
        };
        config[key] = config[key] ? { ...config[key], filter } : { filter };
      }
    });
  }

  return config;
};

const getDateByOffset = (offset) => {
  if (!offset) {
    return null;
  }

  const millisecondsInDay = 8.64e7;
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = now.getMonth();
  const dd = now.getDate();
  const todayMs = new Date(yyyy, mm, dd).valueOf();

  return new Date(todayMs + (millisecondsInDay * offset));
};

const getPluralizedText = (text, count, { single = 'а', multiple = 'и' }) => {
  const stringifiedCount = String(count);

  const getEnding = (number) => {
    const num = Math.abs(number);
    // eslint-disable-next-line no-nested-ternary
    return num === 1 ? single : num >= 2 && num <= 4 ? multiple : '';
  };

  if (stringifiedCount.length > 1) {
    const lastTwoDigit = stringifiedCount.slice(stringifiedCount.length - 2);
    const lastDigit = lastTwoDigit.slice(lastTwoDigit.length - 1);

    return lastTwoDigit >= 10 && lastTwoDigit <= 20
      ? `${count} ${text}`
      : `${count} ${text}${getEnding(lastDigit)}`;
  }
  return `${count} ${text}${getEnding(count)}`;
};

module.exports = {
  getFeatureValue,
  getTrimmedString,
  getSortingFilteringRequestBody,
  getConfigFromRequestBody,
  getDateByOffset,
  getPluralizedText,
};
