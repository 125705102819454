<template>
  <div class="calendars" id="calendars">
    <header class="calendars__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="calendars__main">
      <keep-alive v-if="currentComponent" :include="['calendars-catalog']">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const {
  getExistTabCounts, getCardCounter, sortTabsByCounter, isTabExist, removeTab,
} = require('../../utils/cardTabsHelpers');
const {
  COMPONENT_NAMES,
  calendarTabsStorageKey,
  calendarEntityStorageKey,
  calendarRulesEntityStorageKey,
  calendarSchedulesEntityStorageKey,
  newCalendar,
  newCalendarRule,
  newSchedule,
  catalogTab,
  registerTab,
} = require('../../constants/calendars');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const CalendarCard = (resolve) => {
  require(['./calendar-card.vue'], resolve);
};
const CalendarRulesCard = (resolve) => {
  require(['./calendar-rules-card.vue'], resolve);
};
const ScheduleCard = (resolve) => {
  require(['./schedule-card.vue'], resolve);
};
const CalendarsCatalog = (resolve) => {
  require(['./calendars-catalog.vue'], resolve);
};
const CalendarsRegister = (resolve) => {
  require(['./calendars-register.vue'], resolve);
};
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  CalendarsCatalog,
  CalendarsRegister,
  CalendarCard,
  CalendarRulesCard,
  AoTabs,
  CloseTabModal,
  ScheduleCard,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      reactiveProvidedCalendarData: this.reactiveData, // https://ru.vuejs.org/v2/api/#provide-inject

      calendarCard: {
        entityStorageKey: calendarEntityStorageKey,
        openCatalog: this.openCatalog,
        createNew: this.createNewCalendarCard,
        duplicate: this.duplicateCalendarCard,
        openExist: this.openExistCard,
        onSuccessfulCreate: this.onSuccessfulCreateCalendar,
      },

      calendarRule: {
        newPrefix: newCalendarRule,
        entityStorageKey: calendarRulesEntityStorageKey,
        openRegister: this.openRegister,
        createNew: this.createNewRulesCard,
        duplicate: this.duplicateRulesCard,
        openExist: this.openExistRulesCard,
        onSuccessfulCreate: this.onSuccessfulCreateCalendarRule,
      },

      calendarSchedule: {
        entityStorageKey: calendarSchedulesEntityStorageKey,
        createNew: this.createNewScheduleCard,
        onSuccessfulCreate: this.onSuccessfulCreateSchedule,
      },
    };
  },

  data() {
    return {
      tabs: [
        catalogTab,
        registerTab,
      ],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
        duplicatedCalendarState: null,
        duplicatedRuleState: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    calendarTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.calendar)
        .map(({ id }) => id);
    },
    ruleTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.rules)
        .map(({ id }) => id);
    },
    scheduleTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.schedule)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: calendarTabsStorageKey, tabs: newTabs });

      clearCardStatesCache({
        entity: calendarEntityStorageKey,
        tabIds: this.calendarTabIds,
      });

      clearCardStatesCache({
        entity: calendarRulesEntityStorageKey,
        tabIds: this.ruleTabIds,
      });

      clearCardStatesCache({
        entity: calendarSchedulesEntityStorageKey,
        tabIds: this.scheduleTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(calendarTabsStorageKey);
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.calendar:
            this.openCatalog();
            break;

          case COMPONENT_NAMES.rules:
            this.openRegister();
            break;

          default:
            this.openCatalog();
            break;
        }
      }
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },

    // open static component tab
    openCatalog() {
      this.selectTab(catalogTab);
    },
    openRegister() {
      this.selectTab(registerTab);
    },

    // open dynamic component tab
    openCalendarCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка календаря - ${id}`,
        componentName: COMPONENT_NAMES.calendar,
      };

      this.selectOrAddTab(newTab);
    },
    openExistCard(id) {
      this.openCalendarCard({ id });
    },
    openRulesCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка привязки - ${id}`,
        componentName: COMPONENT_NAMES.rules,
      };

      this.selectOrAddTab(newTab);
    },
    openExistRulesCard(id) {
      this.openRulesCard({ id });
    },
    openScheduleCard({ id, title }) {
      const newTab = {
        id,
        title: title || `Карточка расписания - ${id}`,
        componentName: COMPONENT_NAMES.schedule,
      };

      this.selectOrAddTab(newTab);
    },

    // create new cards
    createNewCalendarCard() {
      const counts = getExistTabCounts(newCalendar, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newCalendar}-${counter}`,
        title: `Новая карточка - ${counter}`,
        isNew: true,
      };

      this.openCalendarCard(tabBody);
    },
    createNewRulesCard() {
      const counts = getExistTabCounts(newCalendarRule, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newCalendarRule}-${counter}`,
        title: `Новая привязка - ${counter}`,
        isNew: true,
      };

      this.openRulesCard(tabBody);
    },
    createNewScheduleCard() {
      const counts = getExistTabCounts(newSchedule, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newSchedule}-${counter}`,
        title: `Новое расписание - ${counter}`,
      };

      this.openScheduleCard(tabBody);
    },

    // duplicate cards
    duplicateCalendarCard(cardState) {
      this.reactiveData.duplicatedCalendarState = cardState;
      this.createNewCalendarCard();
    },
    duplicateRulesCard(cardState) {
      this.reactiveData.duplicatedRuleState = cardState;
      this.createNewRulesCard();
    },

    // successful create card handle
    onSuccessfulCreateCalendar(id) {
      if (!id) {
        return;
      }

      const newCalendarCard = { id, title: `Карточка календаря - ${id}`, componentName: COMPONENT_NAMES.calendar };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newCalendarCard : card));
      this.selectTab(newCalendarCard);
    },
    onSuccessfulCreateCalendarRule(id) {
      if (!id) {
        return;
      }

      const newRuleCard = { id, title: `Карточка привязки - ${id}`, componentName: COMPONENT_NAMES.rules };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? newRuleCard : card));
      this.selectTab(newRuleCard);
    },
    onSuccessfulCreateSchedule(tab) {
      this.cardTabRemove(tab);
    },
  },
};
</script>

<style  scoped>
.calendars {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow: auto;
  padding: 12px;
}

.calendars__main {
  flex: 1 0 auto;
}
</style>
