<template>
  <div class="ao-pagination">
    <div class="ao-pagination__buttons-wrapper">
      <button
        type="button"
        class="ao-pagination__button ao-pagination__button_prev"
        :disabled="page === 1 || isDisabled"
        @click="goToPrevPage"
      >
        <button-arrow-icon />
      </button>

      <div class="ao_pagination__pages">
        <button
          v-for="(pageBtn, index) of buttons"
          type="button"
          class="ao-pagination__button"
          :disabled="pageBtn.inactive || isDisabled"
          :key="index"
          :class="{ 'ao-pagination__button_active': pageBtn.value === page }"
          @click="pageChange(pageBtn)"
        >
          {{ pageBtn.title }}
        </button>
      </div>

      <button
        type="button"
        class="ao-pagination__button ao-pagination__button_next"
        :disabled="page === lastPage || isDisabled"
        @click="goToNextPage"
      >
        <button-arrow-icon />
      </button>
    </div>

    <div class="ao-pagination__options">
      <span class="ao-pagination__items-info">{{ paginationOptionsTitle }}</span>
      <ao-button
        ref="countButton"
        minified
        type="text"
        class="ao-pagination__items-count-button"
        @click="showItemsPerPagePopover = true"
      >{{ itemsPerPageTitle }}</ao-button>
    </div>

    <ao-popup
      v-if="showItemsPerPagePopover"
      closable
      xPosition="left"
      yPosition="top"
      class="ao-pagination__popup"
      :parent="$refs.countButton.$el"
      @onClose="showItemsPerPagePopover = false"
    >
      <template #header>
        <h2 class="ao-pagination__popup-title">Строк на странице</h2>
      </template>
      <template>
        <ul class="ao-pagination__list">
          <li
            v-for="variant of itemsOnPageVariants"
            :key="variant"
            class="ao-pagination__list-item"
          >
            <i v-if="variant === itemsPerPage" class="ao-pagination__list-check-icon">
              <check-large-icon />
            </i>
            <button
              type="button"
              class="ao-pagination__list-button"
              @click="changeItemsPerPage(variant)"
            >{{ variant }} / стр</button>
          </li>
        </ul>
      </template>
    </ao-popup>
  </div>
</template>

<script>
const { getPluralizedText } = require('../utils/formatters');
const AoPopup = require('./ao-popup.vue').default;
const AoButton = require('./shared/ao-button.vue').default;
const ButtonArrowIcon = require('../assets/icons/button-arrow-icon.vue').default;
const CheckLargeIcon = require('../assets/icons/check-large-icon.vue').default;

module.exports = {
  name: 'ao-pagination',

  components: {
    AoButton,
    AoPopup,
    ButtonArrowIcon,
    CheckLargeIcon,
  },

  model: {
    prop: 'currentPage',
    event: 'onChange',
  },

  props: {
    totalItems: {
      type: Number,
      required: true,
      default: 25,
    },
    pageButtonLimit: {
      type: Number,
      required: false,
      default: 8,
    },
    itemsOnPage: {
      type: Number,
      required: false,
      default: 25,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    itemsOnPageVariants: {
      type: Array,
      required: false,
      default: () => [25, 50, 75, 100],
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      page: this.currentPage,
      itemsPerPage: this.itemsOnPage,
      showItemsPerPagePopover: false,
    };
  },

  computed: {
    buttonsCount() {
      return this.lastPage < this.pageButtonLimit ? this.lastPage : this.pageButtonLimit;
    },
    lastPage() {
      return this.totalItems > this.itemsOnPage
        ? Math.ceil(this.totalItems / this.itemsOnPage)
        : 1;
    },
    buttons() {
      let result = [];
      const hiddenStartIndex = 4;
      const halfIndex = this.buttonsCount / 2;
      let dynamicButtonCount = this.buttonsCount - 2;
      let startHidden = false;
      let endHidden = false;

      /* eslint-disable no-continue */
      for (let i = 1; i <= this.buttonsCount; i += 1) {
        const payload = {
          value: i,
          title: `${i}`,
        };

        if (this.lastPage > this.pageButtonLimit) {
          if (i === this.buttonsCount) {
            payload.value = this.lastPage;
            payload.title = `${this.lastPage}`;

            result.push(payload);
            continue;
          }

          // hide buttons on start
          if (this.page > hiddenStartIndex) {
            if (i === 2) {
              result.push({
                value: null,
                title: '...',
                inactive: true,
              });
              startHidden = true;
              dynamicButtonCount -= 1;
              continue;
            }
          }

          // hide buttons on end
          if (this.page < this.lastPage - halfIndex) {
            if (i === this.buttonsCount - 1) {
              result.push({
                value: null,
                title: '...',
                inactive: true,
              });
              endHidden = true;
              dynamicButtonCount -= 1;
              continue;
            }
          }
        }

        result.push(payload);
      }
      /* eslint-enable no-continue */

      if (startHidden && !endHidden) {
        const head = result.slice(0, 2);
        const body = [];
        let pageCount = this.lastPage;

        for (let i = this.buttonsCount; i > head.length; i -= 1) {
          body.push({
            value: pageCount,
            title: `${pageCount}`,
          });

          pageCount -= 1;
        }

        result = [...head, ...body.reverse()];
      } else if (startHidden && endHidden) {
        const head = result.slice(0, 2);
        const body = [];
        const tail = result.slice(result.length - 2);
        const ceilDifferent = this.page - Math.round(dynamicButtonCount / 2);
        const initialIndex = ceilDifferent >= hiddenStartIndex ? ceilDifferent : hiddenStartIndex;

        for (let i = initialIndex; i < initialIndex + dynamicButtonCount; i += 1) {
          body.push({
            value: i,
            title: `${i}`,
          });
        }

        result = [...head, ...body, ...tail];
      }

      return result;
    },
    itemsPerPageTitle() {
      return `${this.itemsPerPage} / стр`;
    },
    paginationOptionsTitle() {
      const rowsTitle = getPluralizedText('строк', this.totalItems, { single: 'а', multiple: 'и' });
      const pagesTitle = getPluralizedText('страниц', this.lastPage, { single: 'а', multiple: 'ы' });
      return `${rowsTitle}, ${pagesTitle}`;
    },
  },

  watch: {
    currentPage(newPage) {
      this.page = newPage;
    },
    itemsOnPage(newValue) {
      this.itemsPerPage = newValue;
    },
  },

  methods: {
    // navigation
    pageChange({ inactive, value }) {
      if (this.isLoading) {
        return;
      }

      if (!inactive) {
        this.page = value;
        this.$emit('onChange', value);
      }
    },
    goToNextPage() {
      if (this.isLoading) {
        return;
      }

      const newPage = this.page + 1;
      this.page = newPage;
      this.$emit('onChange', newPage);
    },
    goToPrevPage() {
      if (this.isLoading) {
        return;
      }

      const newPage = this.page - 1;
      this.page = newPage;
      this.$emit('onChange', newPage);
    },

    // options change
    changeItemsPerPage(value) {
      this.itemsPerPage = value;
      this.showItemsPerPagePopover = false;
      this.$emit('onChangeItemsPerPage', value);
    },
  },
};
</script>

<style scoped>
.ao-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ao-pagination__buttons-wrapper {
  display: flex;
  gap: 24px;
}

.ao_pagination__pages {
  display: flex;
  gap: 4px;
}

.ao-pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #F7F7F7;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: #9B9B9B;
  cursor: pointer;
  transition: all 0.05s ease;
}

.ao-pagination__button:not(:disabled):not(.ao-pagination__button_active):hover {
  background-color: #E9E9E9;
  color: #000000;
}

.ao-pagination__button_active:not(:disabled){
  background-color: transparent;
  border: 1px solid #5295D5;
  color: #000000;
}

.ao-pagination__button:not(:disabled):active {
  border: 1px solid #5295D5;
}

.ao-pagination__button:disabled {
  color: #D5D5D5;
  cursor: default;
}

.ao-pagination__button_prev svg {
  transform: rotate(90deg);
}

.ao-pagination__button_next svg {
  transform: rotate(-90deg);
}

.ao-pagination__items-info {
  font-size: 12px;
  font-weight: 500;
}

.ao-pagination__options {
  display: flex;
  gap: 10px;
}

.ao-pagination__popup >>> .ao-popup__main {
  padding: 0;
  margin-bottom: 16px;
}

.ao-pagination__popup-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.ao-pagination__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ao-pagination__list-item {
  position: relative;
}

.ao-pagination__list-check-icon {
  position: absolute;
  display: flex;
  align-items: center;
  left: 12px;
  top: 4px;
}

.ao-pagination__list-button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 9px 20px;
  padding-left: 42px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.ao-pagination__list-button:hover {
  background-color: #F7F7F7;
}
</style>
