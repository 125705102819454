var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ao-date-field" },
    [
      _c("div", { staticClass: "ao-date-field__info" }, [
        _c(
          "p",
          { staticClass: "ao-date-field__subtitle" },
          [_c("l10n", { attrs: { value: "Дата" } })],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ao-date-field__text" }, [
          _vm._v(_vm._s(_vm.selectedValue))
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          ref: "toggleButton",
          staticClass: "ao-date-field__button",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.showDatepicker = true
            }
          }
        },
        [_c("calendar-icon")],
        1
      ),
      _vm._v(" "),
      _vm.showDatepicker
        ? _c(
            "ao-popup",
            {
              attrs: {
                parent: _vm.$refs.toggleButton,
                scrollableAreaSelector: _vm.scrollableSelector,
                xPosition: "left",
                closable: ""
              },
              on: {
                onClose: function($event) {
                  _vm.showDatepicker = false
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "datepicker__wrapper" },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "is-range": "",
                      "min-date": _vm.minDate,
                      "max-date": _vm.maxDate
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }