<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <ao-popup
    :parent="parent"
    :xPosition="xPosition"
    :scrollableAreaSelector="scrollableAreaSelector"
    closable
    @onClose="hideElement"
  >
    <template #header>
      <h2 class="header-title">
        <l10n value="Настройка столбцов таблицы" />
      </h2>
    </template>
    <template>
      <ul class="column-names__list" v-if="tableColumns.length">
        <li class="column-names__item" v-for="{ id, title } in tableColumns" :key="id">
          <button
            type="button"
            class="column-names__button"
            :class="{ disabled: isColumnPined(id) }"
            @click.prevent="checkColumn(id)"
          >
            <ao-checkbox :checked="isChecked(id)" :disabled="isColumnPined(id)" />
            <span>{{ title }}</span>
          </button>
        </li>
      </ul>
    </template>
    <template #footer>
      <ao-button @click="confirmChange">
        <l10n value="Применить" />
      </ao-button>
    </template>
  </ao-popup>
</template>

<script>
const AoPopup = require('../ao-popup.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const AoCheckbox = require('../shared/ao-checkbox.vue').default;

module.exports = {
  model: {
    prop: 'isVisible',
    event: 'onChangeVisibility',
  },

  components: {
    AoPopup,
    AoButton,
    AoCheckbox,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    parent: {
      type: [Object, Element],
      required: false,
      default: () => null,
    },
    xPosition: {
      type: String, // 'left' | 'right'
      required: false,
      default: 'right',
    },
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    checkedColumnIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    pinnedColumnIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    scrollableAreaSelector: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      showTableSettings: false,
      checkedIds: [],
    };
  },

  mounted() {
    this.checkedIds = this.checkedColumnIds;
  },

  watch: {
    checkedColumnIds(newIds) {
      this.checkedIds = newIds;
    },
  },

  methods: {
    hideElement() {
      this.$emit('onChangeVisibility', false);
    },
    checkColumn(id) {
      if (this.isColumnPined(id)) {
        return;
      }

      if (this.isChecked(id)) {
        this.checkedIds = this.checkedIds.filter((_id) => _id !== id);
      } else {
        this.checkedIds = [...this.checkedIds, id];
      }
    },
    isChecked(id) {
      return this.checkedIds.includes(id);
    },
    confirmChange() {
      this.$emit('onChangeColumnVisible', this.checkedIds);
    },
    isColumnPined(id) {
      return this.pinnedColumnIds.includes(id);
    },
  },
};
</script>

<style scoped>
  .header-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .column-names__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
  }

  .column-names__item {
    margin: 0;
  }

  .column-names__button {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 6px;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
  }

  .column-names__button:not(.disabled):hover {
    cursor: pointer;
    background-color: #F7F7F7;
  }

  .column-names__button.disabled {
    cursor: default;
  }
</style>
