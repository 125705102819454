var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ao-table__container" },
    [
      _c("table", { ref: "table", staticClass: "ao-table" }, [
        _c("thead", { staticClass: "ao-table__head" }, [
          _c(
            "tr",
            { ref: "headRow", staticClass: "ao-table__row head-row" },
            _vm._l(_vm.columns, function(column) {
              return _c(
                "th",
                {
                  key: column.id,
                  staticClass: "ao-table__head-cell",
                  attrs: { id: column.id }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ao-table__cell-container ao-table__cell-container_head"
                    },
                    [
                      _c(
                        "button",
                        {
                          ref: _vm.getColButtonRefLink(column.id),
                          refInFor: true,
                          staticClass: "head-cell__button",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              column.config && _vm.openColumnPopup(column)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(column.title) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isColumnConfigured(column.id)
                        ? _c("i", {
                            staticClass: "head-cell__configured-column-mark"
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          { ref: "tBody", staticClass: "ao-table__body" },
          _vm._l(_vm.rows, function(row, rowIndex) {
            return _c(
              "tr",
              {
                key: row.nodeType + "_" + rowIndex + "_" + row.id,
                staticClass: "ao-table__row body-row",
                on: {
                  click: function($event) {
                    return _vm.handleRowClick(row)
                  }
                }
              },
              _vm._l(_vm.columns, function(column) {
                return _c(
                  "td",
                  { key: column.title, staticClass: "ao-table__cell" },
                  [
                    _c("div", { staticClass: "ao-table__cell-container" }, [
                      _c("span", { staticClass: "ao-table__cell-text" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.getValueOfCell(column.field, row)) +
                            "\n            "
                        )
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("ao-pagination", {
        attrs: {
          totalItems: _vm.totalItems,
          itemsOnPage: _vm.itemsPerPage,
          isLoading: _vm.isLoading
        },
        on: { onChangeItemsPerPage: _vm.setItemsPerPage },
        model: {
          value: _vm.currentPage,
          callback: function($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage"
        }
      }),
      _vm._v(" "),
      _vm.columnPopupIsVisible
        ? _c("ao-table-config-popup", {
            attrs: {
              parent: _vm.columnPopupParent,
              config: _vm.columnConfig,
              columnId: _vm.configuredColumnId,
              configPreset: _vm.getColumnParams(_vm.configuredColumnId),
              modelName: _vm.modelName,
              scrollableAreaSelector: ".ao-table"
            },
            on: {
              onApply: _vm.applyColumnConfig,
              onReset: _vm.resetColumnConfig
            },
            model: {
              value: _vm.columnPopupIsVisible,
              callback: function($$v) {
                _vm.columnPopupIsVisible = $$v
              },
              expression: "columnPopupIsVisible"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }