<template>
  <div class="kpis3" :class="{loading: reportId}">
    <template v-if="editing">
      <gp-portal to="modal">
        <my-popup
          @escape="stopEditing"
          placement="bottom"
          :anchor="getReference">
          <div class="kpi3-form">
            <table>
              <tbody>
                <tr>
                  <th><l10n value="KPI name"/></th>
                  <td>
                    <input
                      autofocus
                      class="form-control"
                      v-model="editingEntryName"/>
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Metric 1"/></th>
                  <td>
                    <gp-select
                      :options="metricOptions"
                      v-model="editingMetric1"
                      recentOptionsKey="recentMetrics"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Timeframe 1"/></th>
                  <td>
                    <gp-select
                      :options="timeframeOptions"
                      v-model="editingTimeframe1"
                      recentOptionsKey="recentTimeframes"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Metric 2"/></th>
                  <td>
                    <gp-select
                      :options="metricOptions"
                      v-model="editingMetric2"
                      recentOptionsKey="recentMetrics"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Timeframe 2"/></th>
                  <td>
                    <gp-select
                      :options="timeframeOptions"
                      v-model="editingTimeframe2"
                      recentOptionsKey="recentTimeframes"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Metric 3"/></th>
                  <td>
                    <gp-select
                      :options="metricOptions"
                      v-model="editingMetric3"
                      recentOptionsKey="recentMetrics"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Timeframe 3"/></th>
                  <td>
                    <gp-select
                      :options="timeframeOptions"
                      v-model="editingTimeframe3"
                      recentOptionsKey="recentTimeframes"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="kpi3-form-actions">
              <button
                v-if="editingEntry"
                class="btn btn-danger"
                @click="
                  removeEntry()
                  stopEditing()
                ">
                <l10n value="Delete"/>
              </button>
              <span/>
              <button
                class="btn btn-primary"
                @click="submitEditing"
                :disabled="
                  !editingMetric1 ||
                    !editingTimeframe1 ||
                    !editingEntryName">
                <l10n v-if="editingEntry" value="Submit"/>
                <l10n v-else value="Create"/>
              </button>
              <button
                class="btn btn-secondary"
                @click="stopEditing">
                <l10n value="Cancel"/>
              </button>
            </div>
          </div>
        </my-popup>
      </gp-portal>
    </template>
    <div class="kpi3">
      <label>&nbsp;</label>
      <div :class="{primary: primaryMetric==0}" style="text-align: right;">
        <a :href="primaryMetric==0 ? '':'#'" @click.prevent="primaryMetric = 0">
          <l10n :value="metric1" />
        </a>
      </div>
      <div :class="{primary: primaryMetric==1}" style="text-align: right;">
        <a :href="primaryMetric==1 ? '':'#'" @click.prevent="primaryMetric = 1">
          <l10n :value="metric2" />
        </a>
      </div>
      <div :class="{primary: primaryMetric==2}" style="text-align: right;">
        <a :href="primaryMetric==2 ? '':'#'" @click.prevent="primaryMetric = 2">
          <l10n :value="metric3" />
        </a>
      </div>
    </div>
    <div class="kpi3" v-for="entry, i in entries">
      <label>
        {{entry.name}}
        <a href="javascript:void(0)"
           class="kpi3-edit"
           @click="startEditing(entry)">
          <feather-icon name="edit-3"/>
        </a>
      </label>
      <template v-for="metric, j in entry.metrics" v-if="values.has(metricKey(metric))">
        <div :class="{primary: j==primaryMetric}">
          <span
            :set1="val0 = entry.metrics[primaryMetric] ? values.get(metricKey(entry.metrics[primaryMetric])).value : null"
            :set2="valn = values.get(metricKey(metric)).value"
            :set3="column = values.get(metricKey(metric)).column"
            :set4="format = values.get(metricKey(metric)).column.format"
          >
            {{format(valn, [], column)}}
            <template v-if="
              j != primaryMetric &&
                val0 != null &&
                val0 != 0 &&
                (isPositive(valn, val0) ||
                  isNegative(valn, val0))">
              <span
                :class="{
                  positive: isPositive(valn, val0),
                  negative: isNegative(valn, val0),
                }">
                <template v-if="val0 != 0">
                  {{formatPercent(valn, val0)}}
                </template>
                <template v-else>
                  {{valn > val0 ? '+' : ''}}{{format(valn-val0, [], column)}}
                </template>
                <feather-icon v-if="isPositive(valn, val0)" name="trending-up"/>
                <feather-icon v-if="isNegative(valn, val0)" name="trending-down"/>
              </span>
            </template>
          </span>
        </div>
      </template>
    </div>
    <div class="kpi3-add">
      <a href="javascript:void(0)" @click="startEditing()">
        <feather-icon name="plus"/>
      </a>
    </div>
    <gp-data
      v-if="referenceDate"
      id="gp-kpis3"
      ref="data"
      :stream="stream"
      :source="source"
      :groups="groups"
      :vars="vars"
      :vals="vals"
      :instant="false"
      v-model="report"
      @reportId="reportId=$event"
    />
  </div>
</template>
<script>
let utils = require('../my-utils');

module.exports = {
  mixins: [
    utils.extraFilters,
    utils.configHelpers,
    utils.referenceDateHelper,
  ],
  model: {
    prop: 'entries',
    event: 'change',
  },
  props: {
    stream: { type: String, default: 'default' },
    vars: { type: Object, default: () => ({}) },
    groups: { type: Array },
    entries: { type: Array, default: () => [] },
    metric1: { type: String, default: 'Forecast' },
    metric2: { type: String, default: 'Adj. Forecast' },
    metric3: { type: String, default: 'Plan' },
  },
  data() {
    let entries = [];

    return {
      report: null,
      reportId: null,
      editing: false,
      editingEntry: null,
      editingMetric1: null,
      editingMetric2: null,
      editingMetric3: null,
      editingTimeframe1: null,
      editingTimeframe2: null,
      editingTimeframe3: null,
      editingEntryName: null,
      primaryMetric: 0,
      primaryDims: [],
    };
  },
  mounted() {
    window.kpis3 = this;
    if (window.gptable) {
      this.primaryDims = this.cleanupDims(gptable.dims);
    }
    utils.bridge.bind('primaryDimsChanged', this.primaryDimsChanged);
  },
  beforeDestroy() {
    utils.bridge.unbind('primaryDimsChanged', this.primaryDimsChanged);
  },
  watch: {
    entries: {
      deep: true,
      handler() {
        localStorage.kpiEntries = JSON.stringify(this.entries);
      },
    },
  },
  methods: {
    metricKey(metric) {
      return `${metric.formula}-${metric.timeframe}`;
    },
    cleanupDims(dims) {
      return _.map(dims, dim => _.pick(dim, ['calc']));
    },
    primaryDimsChanged(primaryDims) {
      primaryDims = this.cleanupDims(primaryDims);
      if (!_.isEqual(this.primaryDims, primaryDims)) {
        this.primaryDims = primaryDims;
      }
    },
    getReference() {
      if (this.editingEntry) {
        return $(this.$el).find('.kpi3')[this.entries.indexOf(this.editingEntry)];
      } else {
        return $(this.$el).find('.kpi3-add')[0];
      }
    },
    parseDate(text) {
      let [y,m,d] = text.split('-').map((x) => parseInt(x));
      return new Date(y,m-1,d);
    },
    formatDate(date) {
      let y = date.getFullYear();
      let m = date.getMonth()+1;
      let d = date.getDate();
      return `${y}-${m<10?'0':''}${m}-${d<10?'0':''}${d}`;
    },
    isPositive(valn, val0) {
      let x = valn / val0 - 1;
      return x > 0.001;
    },
    isNegative(valn, val0) {
      let x = valn / val0 - 1;
      return x < -0.001;
    },
    formatPercent(valn, val0) {
      let x = valn / val0 - 1;
      let maximumFractionDigits = 2;
      if (Math.abs(x) > 0.01) {
        maximumFractionDigits = 1;
      }
      if (Math.abs(x) > 0.1) {
        maximumFractionDigits = 0;
      }
      let text;
      if (Math.abs(x) > 10) {
        text = Number(valn / val0).toLocaleString(this.locale, {
          style: 'decimal',
          maximumFractionDigits,
        }) + 'x';
      } else {
        text = Number(x).toLocaleString(this.locale, {
          style: 'percent',
          maximumFractionDigits,
        });
        if (x > 0) {
          text = `+${text}`;
        }
      }
      return text;
    },
    startEditing(entry) {
      this.stopEditing();
      this.editing = true;
      if (entry) {
        this.editingEntry = entry;
        this.editingEntryName = entry.name;
        if (entry.metrics[0]) {
          let metric = entry.metrics[0];
          this.editingMetric1 = this.metricsByFormula[metric.formula];
          this.editingTimeframe1 = _.assign({ id: metric.timeframe }, this.timeframes[metric.timeframe]);
        }
        if (entry.metrics[1]) {
          let metric = entry.metrics[1];
          this.editingMetric2 = this.metricsByFormula[metric.formula];
          this.editingTimeframe2 = _.assign({ id: metric.timeframe }, this.timeframes[metric.timeframe]);
        }
        if (entry.metrics[2]) {
          let metric = entry.metrics[3];
          this.editingMetric3 = this.metricsByFormula[metric.formula];
          this.editingTimeframe3 = _.assign({ id: metric.timeframe }, this.timeframes[metric.timeframe]);
        }
      }
    },
    stopEditing() {
      this.editing = false;
      this.editingEntry = null;
      this.editingEntryName = '';
      this.editingMetric1 = null;
      this.editingMetric2 = null;
      this.editingMetric3 = null;
      this.editingTimeframe1 = null;
      this.editingTimeframe2 = null;
      this.editingTimeframe3 = null;
    },
    submitEditing() {
      let name = this.editingEntryName;
      let metrics = [];
      if (this.editingMetric1 && this.editingTimeframe1) {
        metrics.push({
          formula: this.editingMetric1.formula.split(/[\s,]+/g)[0],
          format: this.editingMetric1.format,
          timeframe: this.editingTimeframe1.id,
        });
      }
      if (this.editingMetric2 && this.editingTimeframe2) {
        metrics.push({
          formula: this.editingMetric2.formula.split(/[\s,]+/g)[0],
          format: this.editingMetric2.format,
          timeframe: this.editingTimeframe2.id,
        });
      }
      if (this.editingMetric3 && this.editingTimeframe3) {
        metrics.push({
          formula: this.editingMetric3.formula.split(/[\s,]+/g)[0],
          format: this.editingMetric3.format,
          timeframe: this.editingTimeframe3.id,
        });
      }
      let entry = { name, metrics };
      if (this.editingEntry) {
        let entries = _.clone(this.entries);
        entries.splice(
          entries.indexOf(this.editingEntry), 1, entry);
        this.$emit('change', entries);
      } else {
        let entries = _.clone(this.entries);
        entries.push(entry);
        this.$emit('change', entries);
      }
      this.stopEditing();
    },
    removeEntry() {
      let entries = _.clone(this.entries);
      entries.splice(entries.indexOf(this.editingEntry), 1);
      this.$emit('change', entries);
    },
    makeVals(vals, metric) {
      let referenceDate = this.parseDate(this.referenceDate);
      let timeframe = metric.timeframe;
      if (!this.timeframes[timeframe]) {
        timeframe = 'reference_date';
      }

      let [startDate, endDate] =
                eval(this.timeframes[timeframe].calc)(referenceDate);

      let resolveSubstitutes = (calc, depth = 0) => {
        if (depth == 10) {
          return calc;
        }
        return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
          let formula = this.formulas[symbol];
          if (formula !== undefined && !this.isAggregationFormula(formula)) {
            return `(${resolveSubstitutes(formula, depth + 1)})`;
          } else {
            return symbol;
          }
        });
      };

      let registerFormula = (symbol) => {
        let formula = this.formulas[symbol];
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) {
            vals[`${symbol}_${timeframe}`] =
                            this.resolveDateConditions(
                              resolveSubstitutes(formula),
                              startDate,
                              endDate,
                              referenceDate);
          } else {
            for (let [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
              registerFormula(symbol);
            }
          }
        }
      };

      let symbols = metric.formula.split(/[\s,]+/g);
      for (let symbol of symbols) {
        registerFormula(symbol);
      }
    },
    makeCols(cols, metric) {
      let calc = undefined;
      let symbols = metric.formula.split(/[\s,]+/g);
      let symbol = symbols[0];

      let formula = this.formulas[symbol];
      let timeframe = metric.timeframe;
      if (!this.timeframes[timeframe]) {
        timeframe = 'reference_date';
      }
      if (formula !== undefined) {
        if (this.isAggregationFormula(formula)) {
          calc = `${symbol}_${timeframe}`;
        } else {
          let resolveSubstitutes = (calc, depth = 0) => {
            if (depth == 10) {
              return calc;
            }
            return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
              let formula = this.formulas[symbol];
              if (formula !== undefined) {
                if (this.isAggregationFormula(formula)) {
                  return `${symbol}_${timeframe}`;
                } else {
                  return `(${resolveSubstitutes(formula, depth + 1)})`;
                }
              } else {
                return symbol;
              }
            });
          };
          calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            return resolveSubstitutes(symbol);
          });
        }
      }

      let name = metric.name;

      let format = this.formats[metric.format] || metric.format;
      if (_.isString(format)) {
        try {
          format = eval(format);
        } catch (ex) {
          console.warn(format, ex);
        }
      }
      if (!_.isFunction(format)) {
        format = x => x;
      }

      if (calc !== undefined) {
        cols.push(_.assign({
          name,
          calc,
          format,
          metric,
        },
        _.omit(metric, ['name', 'type', 'format'])));
      }
    },
  },
  computed: {
    source() {
      let vals = {};
      let cols = [];
      let dims = []; // this.primaryDims

      for (let entry of this.entries) {
        for (let metric of entry.metrics) {
          this.makeVals(vals, metric);
          this.makeCols(cols, metric);
        }
      }

      return {
        dims,
        cols,
        vals: _(vals)
          .toPairs()
          .map(([name, calc]) => ({
            name,
            calc: `${calc} as ${name}`,
            show: false }))
          .sortBy('calc')
          .value(),
        filter0: this.filter0,
      };
    },
    vals() {
      return _.map(this.source.cols,
        ({ metric, format, calc }, i) => {
          let aggregation = 'sum';
          if (metric.format == 'percent') {
            aggregation = 'avg';
          }

          if (metric.formula.startsWith('avg')) {
            aggregation = 'avg';
          }

          if (_.startsWith(calc, 'avg')) {
            aggregation = 'avg';
          }
          if (_.startsWith(calc, 'min')) {
            aggregation = 'min';
          }
          if (_.startsWith(calc, 'max')) {
            aggregation = 'max';
          }

          let precision = undefined;
          if (aggregation == 'sum') {
            precision = 0;
          }

          let col = `col${i+this.source.dims.length+1}`;

          return {
            metric,
            format,
            precision,
            calc: `${aggregation}(${col} if ${col} != 0)`,
          };
        });

    },
    filter0() {
      let dates = new Set();
      let referenceDate = this.parseDate(this.referenceDate);
      for (let date of [
        referenceDate,
        utils.nextDate(referenceDate),
        utils.prevDate(referenceDate)]) {
        dates.add(this.formatDate(date));
      }
      for (let entry of this.entries) {
        for (let metric of entry.metrics) {
          let timeframe = metric.timeframe;
          if (!this.timeframes[timeframe]) {
            timeframe = 'reference_date';
          }
          let [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);
          if (endDate >= startDate) {
            if (metric.formula) {
              let formula = this.resolveSubstitutes(metric.formula);
              if (_.includes(formula, 'date_before_start')) {
                startDate = utils.prevDate(startDate);
              }
              if (_.includes(formula, 'date_after_end')) {
                endDate = utils.nextDate(endDate);
              }
            }
            let date = new Date(startDate);
            while (date.getTime() <= endDate.getTime()) {
              dates.add(this.formatDate(date));
              date = utils.nextDate(date);
            }
          }
        }
      }
      const datesArray = Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
      const datesLength = datesArray.length;
      const [firstDate] = datesArray;

      if (datesLength > 1) {
        const lastDate = datesArray[datesLength - 1];

        return `date >= \`${firstDate}\` && date <= \`${lastDate}\``;
      }

      return `date == \`${firstDate}\``;
    },
    values() {
      return this.report && this.report.rows && this.report.rows.length == 1 ?
        new Map(this.report.meta.columns.map((column, i) => [this.metricKey(column.metric), { column, i, value: this.report.rows[0][i] }])) : new Map();
    },
    metricOptions() {
      return _(this.metrics).filter(metric => metric.name && !metric.deleted).sortBy(metric => metric.name).value();
    },
    timeframeOptions() {
      return _(this.computedTimeframes)
        .toPairs()
        .map(([id, timeframe]) => _.assign({ id }, timeframe))
        .value();
    },
    computedTimeframes() {
      let referenceDate = this.parseDate(this.referenceDate);
      return _(this.timeframes)
        .toPairs()
        .filter(([id, { deleted }]) => !deleted)
        .map(([id, { calc, name }]) => {
          try {
            let [startDate, endDate] = eval(calc)(referenceDate);
            return [id, { calc, name, startDate, endDate, referenceDate }];
          } catch (ex) {
            console.warn(id, name, calc, ex);
          }
        })
        .filter()
        .sortBy(([id, { startDate }]) => _.isDate(startDate) ? startDate.getTime() : 0)
        .fromPairs()
        .value();
    },
  },
};
</script>
<style type="text/css">
.kpis3 {
    float: right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
}
.kpi3 {
    text-align: left;
    padding-right: 20px;
    margin-bottom: 10px;
}
.kpi3 > div {
    display: flex;
    flex-direction: column;
}
.kpi3 > label {
    margin: auto;
    font-size: 0.8em;
    opacity: 0.9;
    margin-top: -2px;
    display: block;
}
.kpi3 .my-progress {
    display: none;
}
.kpi3 .feather-icon-trending-up svg,
.kpi3 .feather-icon-trending-down svg {
    width: 18px;
    margin-top: -2px;
    margin-right: 8px;
}
.kpi3 > .primary {
    font-size: 19px;
}
.kpi3 > :not(.primary) {
    font-size: 15px;
    margin-top: 0;
}
.kpi3 > div > .my-chart {
    display: flex;
    /*flex-direction: row-reverse;*/
}
#elast .x .tick {
    display: none!important;
}
@media only screen and (max-width: 600px) {
    .gp-side-bar {
        position: absolute;
        z-index: 4;
        width: 100%;
        min-width: none;
        max-width: none;
        background-color: white;
        top: 36px;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .gp-side-bar-hide.close {
        z-index: 5;
    }
    input {
        font-size: 18px;
        border-radius: 0;
    }
    .kpis3 {
        float: none;
        max-width: inherit;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.kpi3 {
    position: relative;
}
.kpi3-edit {
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
}
.kpi3-edit svg {
    visibility: hidden;
    width: 16px;
    height: 16px;
}
.kpi3:hover .kpi3-edit svg {
    visibility: initial;
}
.kpi3-add {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px dotted var(--gray);*/
    /*border-radius: 8px;*/
    margin-bottom: 10px;
}
.kpi3-add > * {
    display: block;
    margin: auto;
    vertical-align: middle;
}
.kpi3-form {
    background-color: white;
    padding: 15px;
    border: 1px solid var(--dark);
    box-shadow: 0 0 10px 0px var(--dark);
}
.kpi3-form table {
    margin-bottom: 20px;
    width: 100%;
}
.kpi3-form table td {
    width: 300px;
}
.kpi3-form table th {
    font-weight: normal;
}
.kpi3-form table th,
.kpi3-form table td {
    padding: 4px 8px;
}
.kpi3-form-actions {
    text-align: right;
}
.kpi3-form-actions .btn {
    min-width: 100px;
}
.kpi3-form-actions .btn + .btn {
    margin-left: 10px;
}
.kpis3 {
    margin-top: -4px;
    margin-right: 10px;
}
.kpis.loading .kpi3 {
    opacity: 0.5;
}
.kpi3 > label {
    margin-top: -1px;
    font-size: 15px;
}
.kpi3-add {
    margin-bottom: 0;
}
.kpi3-add svg {
    color: var(--gray);
    width: 40px;
    height: 40px;
    padding: 7px;
    border: 1px dotted var(--gray);
    border-radius: 4px;
}
.kpi3 .positive {
    color: var(--green);
}
.kpi3 .negative {
    color: var(--red);
}
.kpi3-form-actions {
    display: flex;
}
.kpi3-form-actions span {
    flex-grow: 1;
    flex-basis: 1px;
}
.kpi3-form td > input {
    border: none;
    border-bottom: 1px solid var(--dark);
    border-radius: 0;
    padding: 0 8px;
    height: 28px;
    color: inherit;
    background-color: transparent;
}
.my-dark-theme .kpi3-form {
    background-color: rgb(39,53,72);
    border: 1px solid black;
}
.my-dark-theme .kpi3-form td > input {
    border-color: var(--light);
}
.kpi3 a[href=""] {
    pointer-events: none;
    color: #222;
}
.my-dark-theme .kpi3 a[href=""] {
    color: white;
}
.kpis3 .kpi3 > label {
  font-size: 14px;
  opacity: 0.8;
}
.kpis3 .positive,
.kpis3 .negative {
  vertical-align: super;
  font-size: 0.8em;
}
.kpis3 .positive svg,
.kpis3 .negative svg {
  width: 14px;
}
.kpis3 {
  float: none;
}
</style>
