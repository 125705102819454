var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d: "M7 9.5L12 14.5L17 9.5",
          stroke: "currentColor",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }