<template>
  <div class="event-simulation-container">
    <div class="event-simulation-table">
      <table style="width: 100%" class="ao-table">
        <tbody>
          <tr class="ao-table__row bold">
            <td
              v-for="column, i in tableColumns"
              :key="column"
              class="ao-table__cell"
              :class="{ 'without-border': i === 0, 'width-limited': i !== 0 }"
            >
              <span class="ao-table__cell-text">
                {{ column }}
              </span>
            </td>
          </tr>
          <tr
            v-for="(row, i) in rows"
            :key="`row-${i}`"
            class="ao-table__row"
          >
            <td
              v-for="column, i in row"
              :key="`column-${i}`"
              class="ao-table__cell"
              :class="{ bold: i === 0, 'without-border': i === 0, 'width-limited': i !== 0 }"
            >
              <span
                class="ao-table__cell-text"
                :contenteditable="i === 0 ? false : true"
              >
                {{ column }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <vue-chart id="chart-container" type="bar" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
module.exports = {
  data() {
    return {
      tableColumns: ['', '2021-08-02', '2021-08-03', '2021-08-04', '2021-08-05', '2021-08-06', '2021-08-07', '2021-08-08', '2021-08-09', '2021-08-10', '2021-08-11', '2021-08-12', '2021-08-13', '2021-08-14', '2021-08-15', '2021-08-16', '2021-08-17', '2021-08-18', '2021-08-19', '2021-08-20', '2021-08-21', '2021-08-22', '2021-08-23', '2021-08-24', '2021-08-25', '2021-08-26', '2021-08-27', '2021-08-28', '2021-08-29', '2021-08-30', '2021-08-31', '2021-09-01', '2021-09-02', '2021-09-03', '2021-09-04', '2021-09-05', '2021-09-06', '2021-09-07', '2021-09-08', '2021-09-09', '2021-09-10', '2021-09-11', '2021-09-12', '2021-09-13', '2021-09-14', '2021-09-15', '2021-09-16', '2021-09-17', '2021-09-18', '2021-09-19', '2021-09-20', '2021-09-21', '2021-09-22', '2021-09-23', '2021-09-24', '2021-09-25', '2021-09-26', '2021-09-27', '2021-09-28', '2021-09-29', '2021-09-30', '2021-10-01', '2021-10-02', '2021-10-03'],
      rows: [
        ['Продажи', 8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Базовое значение', ...new Array(63).fill(4.03)],
        ['Фактор недельной динамики', 1, 1.09, 0.81, 1, 1.09, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.13, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94, 1, 1.09, 0.81, 1, 1.08, 1.14, 0.94],
        ['Фактор месячной динамики', 1, 1, 1, 1, 1, 1, 1, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 1.29, 1.29, 1.29, 1.29, 1.29, 1.29, 1.29, 1.08, 1.08, 1.08, 1.08, 1.08, 1.08, 1.08, 1.39, 1.39, 1.39, 1.39, 1.39, 1.39, 1.39, 1.33, 1.33, 1.33, 1.33, 1.33, 1.33, 1.33, 1.45, 1.45, 1.45, 1.45, 1.45, 1.45, 1.45, 1.48, 1.48, 1.48, 1.48, 1.48, 1.48, 1.48, 1.56, 1.56, 1.56, 1.56, 1.56, 1.56, 1.56],
        ['Фактор эластичности', 0.82, 0.85, 0.81, 0.82, 0.82, 0.84, 0.89, 0.87, 0.85, 0.82, 0.82, 0.82, 0.8, 0.8, 0.81, 0.82, 0.8, 0.8, 0.81, 0.8, 0.81, 0.8, 0.82, 0.85, 0.82, 0.8, 0.82, 0.83, 0.31, 0.31, 0.32, 0.32, 0.32, 0.32, 0.2, 0.1, 0.12, 0.31, 0.7, 0.98, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Фактор обнуления и виртуального сток', 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, ...new Array(23).fill(0)],
        ['Очищенные продажи', 7.71, 0.88, 5.93, 5.77, 2.66, 5.9, 3.11, 2.06, 4.71, 11.34, 1.01, 1.88, 0, 0, 10.37, 3.4, 3.64, 5.15, 1.36, 3.21, 7.06, 0.88, 3.26, 0, 3.55, 3.26, 2.32, 1.89, 4.94, 1.69, 11.43, 0.61, 6.25, 3.23, 6.38, 0, 0, 3.99, 1.4, 2.74, 7.71, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['Прогноз продаж', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.72, 5.09, 5.5, 4.76, 5.08, 6.11, 6.55, 5.07, 5.88, 6.42, 4.77, 5.9, 6.39, 6.75, 5.54, 6, 6.55, 4.87, 6.02, 6.53, 6.89, 5.66, 6.31, 6.89, 5.12, 6.34, 6.87, 7.25, 5.95],
      ],
      chartData: {
        labels: ['2021-08-02', '2021-08-03', '2021-08-04', '2021-08-05', '2021-08-06', '2021-08-07', '2021-08-08', '2021-08-09', '2021-08-10', '2021-08-11', '2021-08-12', '2021-08-13', '2021-08-14', '2021-08-15', '2021-08-16', '2021-08-17', '2021-08-18', '2021-08-19', '2021-08-20', '2021-08-21', '2021-08-22', '2021-08-23', '2021-08-24', '2021-08-25', '2021-08-26', '2021-08-27', '2021-08-28', '2021-08-29', '2021-08-30', '2021-08-31', '2021-09-01', '2021-09-02', '2021-09-03', '2021-09-04', '2021-09-05', '2021-09-06', '2021-09-07', '2021-09-08', '2021-09-09', '2021-09-10', '2021-09-11', '2021-09-12', '2021-09-13', '2021-09-14', '2021-09-15', '2021-09-16', '2021-09-17', '2021-09-18', '2021-09-19', '2021-09-20', '2021-09-21', '2021-09-22', '2021-09-23', '2021-09-24', '2021-09-25', '2021-09-26', '2021-09-27', '2021-09-28', '2021-09-29', '2021-09-30', '2021-10-01', '2021-10-02', '2021-10-03'],
        datasets: [
          {
            label: 'Очищенные продажи',
            data: [7.715555667877197, 0.8895764350891113, 5.93445348739624, 5.772295951843262, 2.6645357608795166, 5.900936603546143, 3.1120235919952393, 2.069221019744873, 4.7188897132873535, 11.340137481689453, 1.0195958614349365, 1.8805489540100098, 0, 0, 10.375873565673828, 3.4036381244659424, 3.6408348083496094, 5.157071113586426, 1.3621817827224731, 3.21516489982605, 7.065043926239014, 0.8871782422065735, 3.2665393352508545, 0, 3.5527145862579346, 3.263806104660034, 2.3243935108184814, 1.8941291570663452, 4.942502021789551, 1.6981765031814575, 11.436501502990723, 0.6162541508674622, 6.25546932220459, 3.2328133583068848, 6.382678508758545, 0, 0, 3.9910643100738525, 1.406543254852295, 2.7485275268554688, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: 'line',
            borderColor: 'rgba(255,255,0,0)',
            backgroundColor: 'yellow',
            order: 0,
            pointRadius: 0,
          },
          {
            label: 'Продажи',
            data: [8, 1, 5, 6, 3, 7, 3, 2, 5, 9, 1, 2, 8, 8, 14, 5, 4, 7, 2, 5, 9, 1, 4, 0, 4, 4, 3, 2, 8, 3, 15, 1, 11, 6, 10, 29, 25, 5, 2, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: 'green',
            borderColor: 'green',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Фактор недельной динамики',
            data: [19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766, 19.75806427001953, 21.564516067504883, 16.032257080078125, 19.83333396911621, 21.489248275756836, 22.69354820251465, 18.629032135009766],
            type: 'line',
            fill: false,
            borderColor: 'gray',
            backgroundColor: 'gray',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 2,
          },
          {
            label: 'Фактор месячной динамики',
            data: [15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 15.550819396972656, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 14.665573120117188, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 20.213115692138672, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 16.849180221557617, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 21.747539520263672, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 20.7442626953125, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 22.691802978515625, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 23.163936614990234, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047, 24.37377166748047],
            type: 'line',
            fill: false,
            borderColor: 'lightgreen',
            backgroundColor: 'lightgreen',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 3,
          },
          {
            label: 'Фактор эластичности',
            data: [23.878564834594727, 24.81728172302246, 23.679990768432617, 24.062955856323242, 24.104442596435547, 24.42902374267578, 25.856300354003906, 25.515031814575195, 24.89271354675293, 23.8445987701416, 23.990802764892578, 23.878564834594727, 23.394540786743164, 23.233198165893555, 23.71722412109375, 24.062955856323242, 23.233198165893555, 23.41470718383789, 23.6480770111084, 23.233198165893555, 23.53106117248535, 23.41470718383789, 24.062955856323242, 24.685274124145508, 24.104442596435547, 23.456594467163086, 23.8445987701416, 24.322254180908203, 9.293279647827148, 9.293279647827148, 9.465376853942871, 9.453508377075195, 9.434086799621582, 9.50448989868164, 6.0406317710876465, 3.0228140354156494, 3.7173118591308594, 9.293279647827148, 20.57797622680664, 28.59470558166504],
            type: 'line',
            fill: false,
            borderColor: 'blue',
            backgroundColor: 'blue',
            borderDash: [5, 5],
            pointRadius: 2,
            order: 4,
          },
          {
            label: 'Фактор обнуления и виртуального сток',
            data: [...new Array(12).fill(20), NaN, NaN, ...new Array(21).fill(20), NaN, NaN, 20, 20, 20],
            type: 'line',
            fill: false,
            borderColor: 'black',
            backgroundColor: 'black',
            pointRadius: 2,
            order: 5,
          },
          {
            label: 'Базовое значение',
            data: new Array(63).fill(4.03),
            type: 'line',
            fill: false,
            borderColor: 'orange',
            backgroundColor: 'orange',
            pointRadius: 2,
            order: 6,
          },
          {
            label: 'Прогноз продаж',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.7246649265289307, 5.094363689422607, 5.501387596130371, 4.76611852645874, 5.081233501434326, 6.118488311767578, 6.551771640777588, 5.070675849914551, 5.8828935623168945, 6.42075777053833, 4.773547649383545, 5.905304908752441, 6.398346900939941, 6.756922721862793, 5.546727657318115, 6.005294322967529, 6.554349899291992, 4.872867584228516, 6.028172016143799, 6.531472682952881, 6.897509574890137, 5.662134647369385, 6.318946838378906, 6.896678447723389, 5.127373695373535, 6.343019008636475, 6.872607231140137, 7.257761478424072, 5.957863807678223],
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 0,
            barPercentage: 0.5,
            order: 7,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: 30,
            },
          }],
        },
        tooltips: {
          mode: 'x',
          position: 'nearest',
        },
      },
    };
  },
};
</script>

<style scoped>
.event-simulation-container {
  height: 100%;
  padding: 20px 0;
}
.event-simulation-table {
  height: 30%;
  overflow: overlay;
}
.ao-table__row.bold,
.ao-table__cell.bold {
  font-weight: bold;
  font-size: 16px;
}
.ao-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
}

.ao-table__cell {
  padding: 0 5px;
  border: 1px solid #E9E9E9;
}
.ao-table__cell.without-border {
  border: none;
}

.ao-table__cell.width-limited .ao-table__cell-text {
   display: block;
   width: 70px;
}
.ao-table__cell-text {
  width: 100%;
  max-width: 300px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
  font-size: 11.05px;
}
#chart-container {
  padding-bottom: 20px;
}
</style>
