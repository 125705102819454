import { render, staticRenderFns } from "./scatter-plot.vue?vue&type=template&id=03776088&"
import script from "./scatter-plot.vue?vue&type=script&lang=js&"
export * from "./scatter-plot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/a.mavrayev/dev/work/gloriajeans_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03776088')) {
      api.createRecord('03776088', component.options)
    } else {
      api.reload('03776088', component.options)
    }
    module.hot.accept("./scatter-plot.vue?vue&type=template&id=03776088&", function () {
      api.rerender('03776088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/dc/scatter-plot.vue"
export default component.exports