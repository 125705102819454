const { DOMAIN } = require('./endpoint');
const {
  getProductName,
  getProductCategoryName,
  getWarehouseName,
  getWarehouseCategoryName,
  getAuthorName,
  getStartAt,
  getEndAt,
  getCreatedAt,
  getChainName,
  getIdNameField,
  getLeafType,
  getLeafName,
  getLeafPromo,
  getLeafWarehouse,
  getLeafWarehouseCategory,
  getLeafPromoActivity,
} = require('../utils/tableColumnNameGetters');

// chains
const COMPONENT_NAMES = {
  catalog: 'ChainsCatalog',
  register: 'ChainsRegister',
  chain: 'ChainCard',
  rules: 'ChainRulesCard',
};
const chainTabsStorageKey = 'chain-tabs';
const chainEntityStorageKey = 'chain-cards';
const chainRulesEntityStorageKey = 'chain-rules-cards';
const newChain = 'new-chain-card';
const newChainRule = 'new-chain-rule';
const catalogTab = { id: 'chain-catalog', title: 'Справочник цепочек', componentName: COMPONENT_NAMES.catalog };
const registerTab = { id: 'chain-register', title: 'Реестр привязок цепочек', componentName: COMPONENT_NAMES.register };

// storage keys
const storageKeys = {
  parentName: 'chains',
  register: {
    visibleColumnsKey: 'chain-register-visible-cols',
    columnsSizeKey: 'chain-register-columns-size',
  },
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID цепочки', getter: getIdNameField },
  { id: 'type', title: 'Тип цепочки', getter: getLeafType },
  { id: 'name', title: 'Название цепочки', getter: getLeafName },
  { id: 'isPromoActive', title: 'Признак промо', getter: getLeafPromoActivity },
  { id: 'promoName', title: 'Название промо', getter: getLeafPromo },
  { id: 'warehouse', title: 'Узел отправителя', getter: getLeafWarehouse },
  { id: 'warehouseCategory', title: 'Категория отправителей', getter: getLeafWarehouseCategory },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/chains/categories/hierarchy/`,
  leafsField: 'chains',
};

// register
const registerColumns = [
  {
    id: 'id',
    title: 'ID связи',
    field: 'id',
    config: {
      sorting: true,
      filterType: 'numeric',
    },
  },
  {
    id: 'product_name',
    title: 'Товар',
    field: getProductName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'product_category_name',
    title: 'Категория товара',
    field: getProductCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_name',
    title: 'Узел',
    field: getWarehouseName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_category_name',
    title: 'Категория узла',
    field: getWarehouseCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'start_at',
    title: 'Дата действия с',
    field: getStartAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'end_at',
    title: 'Дата действия до',
    field: getEndAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'description',
    title: 'Описание',
    field: 'description',
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'created_at',
    title: 'Дата создания',
    field: getCreatedAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'author',
    title: 'Автор',
    field: getAuthorName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'chain_name',
    title: 'Цепочка',
    field: getChainName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  endpoint: `${DOMAIN}/chains/connections/filtered/`,
  configEndpoint: `${DOMAIN}/chains/connections/filtered/config/`,
};

// endpoints
const ENDPOINTS = {
  CHAINS: `${DOMAIN}/chains/`,
  RULES: `${DOMAIN}/chains/connections/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/chains/categories/hierarchy/`,
  FEATURES: `${DOMAIN}/chains/features/`,
  FEATURE_NAMES: `${DOMAIN}/chains/feature_names/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  chainTabsStorageKey,
  chainEntityStorageKey,
  chainRulesEntityStorageKey,
  newChain,
  newChainRule,
  catalogTab,
  registerTab,
  storageKeys,
  hierarchyTableProps,
  registerTableProps,
};
