<template>
  <div class="policies" id="policies">
    <header class="policies__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="policies__main">
      <keep-alive v-if="currentComponent" :include="['policies-catalog']">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const {
  getExistTabCounts, getCardCounter, sortTabsByCounter, isTabExist, removeTab,
} = require('../../utils/cardTabsHelpers');
const {
  COMPONENT_NAMES,
  policyTabsStorageKey,
  policyEntityStorageKey,
  policyRulesEntityStorageKey,
  newPolicy,
  newPolicyRule,
  catalogTab,
  registerTab,
} = require('../../constants/policies');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const PolicyCard = (resolve) => {
  require(['./policy-card.vue'], resolve);
};
const PoliciesCatalog = (resolve) => {
  require(['./policies-catalog.vue'], resolve);
};
const PoliciesRegister = (resolve) => {
  require(['./policies-register.vue'], resolve);
};
const PolicyRulesCard = (resolve) => {
  require(['./policy-rules-card.vue'], resolve);
};
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  PoliciesCatalog,
  PoliciesRegister,
  PolicyCard,
  PolicyRulesCard,
  AoTabs,
  CloseTabModal,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      // https://ru.vuejs.org/v2/api/#provide-inject
      reactiveProvidedPolicyData: this.reactiveData,

      policyCard: {
        entityStorageKey: policyEntityStorageKey,
        openCatalog: this.openCatalog,
        createNew: this.createNewPolicyCard,
        duplicate: this.duplicatePolicyCard,
        openExist: this.openExistPolicyCard,
        onSuccessfulCreate: this.onSuccessfulCreatePolicy,
      },

      policyRule: {
        entityStorageKey: policyRulesEntityStorageKey,
        openRegister: this.openRegister,
        createNew: this.createNewRulesCard,
        duplicate: this.duplicateRulesCard,
        openExist: this.openExistRulesCard,
        onSuccessfulCreate: this.onSuccessfulCreateRule,
      },
    };
  },

  data() {
    return {
      tabs: [
        catalogTab,
        registerTab,
      ],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
        duplicatedPolicyState: null,
        duplicatedRuleState: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    policyTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.policy)
        .map(({ id }) => id);
    },
    ruleTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.rules)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: policyTabsStorageKey, tabs: newTabs });

      clearCardStatesCache({
        entity: policyEntityStorageKey,
        tabIds: this.policyTabIds,
      });

      clearCardStatesCache({
        entity: policyRulesEntityStorageKey,
        tabIds: this.ruleTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(policyTabsStorageKey);
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.policy:
            this.openCatalog();
            break;

          case COMPONENT_NAMES.rules:
            this.openRegister();
            break;

          default:
            this.openCatalog();
            break;
        }
      }
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },

    // open static component tab
    openCatalog() {
      this.selectTab(catalogTab);
    },
    openRegister() {
      this.selectTab(registerTab);
    },

    // open dynamic component tab
    openPolicyCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка политики - ${id}`,
        componentName: COMPONENT_NAMES.policy,
      };

      this.selectOrAddTab(newTab);
    },
    openExistPolicyCard(id) {
      this.openPolicyCard({ id });
    },
    openRulesCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Карточка привязки - ${id}`,
        componentName: COMPONENT_NAMES.rules,
      };

      this.selectOrAddTab(newTab);
    },
    openExistRulesCard(id) {
      this.openRulesCard({ id });
    },

    // create new cards
    createNewPolicyCard() {
      const counts = getExistTabCounts(newPolicy, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newPolicy}-${counter}`,
        title: `Новая карточка - ${counter}`,
        isNew: true,
      };

      this.openPolicyCard(tabBody);
    },
    createNewRulesCard() {
      const counts = getExistTabCounts(newPolicyRule, this.cardTabs);
      const counter = getCardCounter(counts);

      const tabBody = {
        id: `${newPolicyRule}-${counter}`,
        title: `Новая привязка - ${counter}`,
        isNew: true,
      };

      this.openRulesCard(tabBody);
    },

    // duplicate cards
    duplicatePolicyCard(cardState) {
      this.reactiveData.duplicatedPolicyState = cardState;
      this.createNewPolicyCard();
    },
    duplicateRulesCard(cardState) {
      this.reactiveData.duplicatedRuleState = cardState;
      this.createNewRulesCard();
    },

    // successful create card handle
    onSuccessfulCreatePolicy(id) {
      if (!id) {
        return;
      }

      const policyCard = { id, componentName: COMPONENT_NAMES.policy, title: `Карточка политики - ${id}` };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? policyCard : card));
      this.selectTab(policyCard);
    },
    onSuccessfulCreateRule(id) {
      if (!id) {
        return;
      }

      const ruleCard = { id, componentName: COMPONENT_NAMES.rules, title: `Карточка привязки - ${id}` };
      this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId
        ? ruleCard : card));
      this.selectTab(ruleCard);
    },
  },
};
</script>

<style scoped>
.policies {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow: auto;
  padding: 12px;
}

.policies__main {
  flex: 1 0 auto;
}
</style>
