var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ao-popup",
    {
      attrs: {
        parent: _vm.parent,
        xPosition: _vm.xPosition,
        scrollableAreaSelector: _vm.scrollableAreaSelector,
        closable: ""
      },
      on: { onClose: _vm.hideElement },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "h2",
                { staticClass: "header-title" },
                [
                  _c("l10n", { attrs: { value: "Настройка столбцов таблицы" } })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "ao-button",
                { on: { click: _vm.confirmChange } },
                [_c("l10n", { attrs: { value: "Применить" } })],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      [
        _vm.tableColumns.length
          ? _c(
              "ul",
              { staticClass: "column-names__list" },
              _vm._l(_vm.tableColumns, function(ref) {
                var id = ref.id
                var title = ref.title
                return _c(
                  "li",
                  { key: id, staticClass: "column-names__item" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "column-names__button",
                        class: { disabled: _vm.isColumnPined(id) },
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.checkColumn(id)
                          }
                        }
                      },
                      [
                        _c("ao-checkbox", {
                          attrs: {
                            checked: _vm.isChecked(id),
                            disabled: _vm.isColumnPined(id)
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(title))])
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }