<template>
  <div class="ao-date-field">
    <div class="ao-date-field__info">
      <p class="ao-date-field__subtitle">
        <l10n value="Дата" />
      </p>
      <p class="ao-date-field__text">{{ selectedValue }}</p>
    </div>

    <button ref="toggleButton" type="button" class="ao-date-field__button" @click="showDatepicker = true">
      <calendar-icon />
    </button>

    <ao-popup
      v-if="showDatepicker"
      :parent="$refs.toggleButton"
      :scrollableAreaSelector="scrollableSelector"
      xPosition="left"
      @onClose="showDatepicker = false"
      closable
    >
      <div class="datepicker__wrapper">
        <v-date-picker
          v-model="date"
          is-range
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>
    </ao-popup>
  </div>
</template>

<script>
const moment = require('moment');
const { getDateByOffset } = require('../../utils/formatters');
const AoPopup = require('../ao-popup.vue').default;
const CalendarIcon = require('../../assets/icons/calendar-icon.vue').default;

module.exports = {
  model: {
    prop: 'range',
    event: 'onChange',
  },

  components: {
    AoPopup,
    CalendarIcon,
  },

  props: {
    range: {
      type: Object,
      required: false,
      default: () => ({
        start: new Date(),
        end: new Date(),
      }),
    },
    minDayBefore: {
      type: Number,
      required: false,
      default: null,
    },
    maxDayAfter: {
      type: Number,
      required: false,
      default: null,
    },
    scrollableSelector: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      date: this.range,
      showDatepicker: false,
    };
  },

  computed: {
    selectedValue() {
      return `${moment(this.date.start).format('DD.MM.YYYY')} - ${moment(this.date.end).format('DD.MM.YYYY')}`;
    },
    minDate() {
      return this.minDayBefore ? getDateByOffset(-this.minDayBefore) : null;
    },
    maxDate() {
      return this.maxDayAfter ? getDateByOffset(this.maxDayAfter) : null;
    },
  },

  watch: {
    range(newValue) {
      this.date = newValue;
    },
    date(newValue) {
      this.$emit('onChange', newValue);
    },
  },
};
</script>

<style scoped>
.ao-date-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #F7F7F7;
}

.ao-date-field__subtitle {
  font-size: 10px;
  font-weight: 500;
  color: #9B9B9B;
  margin: 0;
}

.ao-date-field__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
}

.ao-date-field__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  color: #AFAFAF;
  cursor: pointer;
}

.ao-date-field__button:hover {
  color: #000000;
}

.ao-date-field__button:active {
  color: #5295D5;
}

.ao-date-field >>> .ao-popup__main {
  padding: 0
}

.ao-date-field >>> .vc-container {
  border: none;
}
</style>
