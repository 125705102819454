var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M12.8001 3C13.222 3.00006 13.5985 3.26492 13.7411 3.662L14.2491 5.077C14.3298 5.29951 14.4863 5.48647 14.6911 5.605L16.1981 6.475C16.4028 6.59307 16.6426 6.63488 16.8751 6.593L18.3551 6.326C18.7707 6.25043 19.189 6.44415 19.4001 6.81L20.2001 8.19C20.4118 8.55486 20.3718 9.01333 20.1001 9.336L19.1291 10.484C18.9762 10.6645 18.8922 10.8934 18.8921 11.13V12.87C18.8922 13.1066 18.9762 13.3354 19.1291 13.516L20.1001 14.664C20.3718 14.9866 20.4118 15.4451 20.2001 15.81L19.4001 17.19C19.1891 17.5554 18.7713 17.7491 18.3561 17.674L16.8761 17.407C16.6436 17.3651 16.4037 17.4069 16.1991 17.525L14.6921 18.395C14.4872 18.5135 14.3307 18.7004 14.2501 18.923L13.7421 20.338C13.5994 20.7354 13.2224 21.0003 12.8001 21H11.2001C10.7782 20.9999 10.4017 20.7351 10.2591 20.338L9.75111 18.923C9.67051 18.7008 9.51443 18.5139 9.31011 18.395L7.80212 17.525C7.59747 17.4069 7.35764 17.3651 7.12512 17.407L5.64512 17.674C5.22952 17.7496 4.81126 17.5559 4.60012 17.19L3.80012 15.81C3.5884 15.4451 3.62841 14.9867 3.90012 14.664L4.87112 13.516C5.02405 13.3355 5.10802 13.1066 5.10812 12.87V11.13C5.10803 10.8934 5.02407 10.6646 4.87115 10.484L3.91011 9.336C3.63841 9.01333 3.5984 8.55486 3.81011 8.19L4.61011 6.81C4.82113 6.44454 5.23885 6.25089 5.65411 6.326L7.13411 6.593C7.36663 6.63488 7.60647 6.59307 7.81111 6.475L9.31911 5.605C9.52343 5.48609 9.6795 5.29923 9.76011 5.077L10.2681 3.662C10.4096 3.26801 10.7815 3.00384 11.2001 3L12.8001 3Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M12.0001 14.75C13.5189 14.75 14.7501 13.5188 14.7501 12C14.7501 10.4812 13.5189 9.25 12.0001 9.25C10.4813 9.25 9.25012 10.4812 9.25012 12C9.25012 13.5188 10.4813 14.75 12.0001 14.75Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }