var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M12 3.5C12.5523 3.5 13 3.94772 13 4.5V12.0858L15.2929 9.79289C15.6834 9.40237 16.3166 9.40237 16.7071 9.79289C17.0976 10.1834 17.0976 10.8166 16.7071 11.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L7.29289 11.2071C6.90237 10.8166 6.90237 10.1834 7.29289 9.79289C7.68342 9.40237 8.31658 9.40237 8.70711 9.79289L11 12.0858V4.5C11 3.94772 11.4477 3.5 12 3.5ZM5 13.7666C5 13.2143 4.55229 12.7666 4 12.7666C3.44772 12.7666 3 13.2143 3 13.7666V15.2333V15.2738V15.2739C2.99999 15.9861 2.99997 16.5805 3.03965 17.0662C3.08104 17.5727 3.17052 18.0482 3.39964 18.4979C3.75118 19.1878 4.31211 19.7488 5.00204 20.1003C5.45172 20.3294 5.92726 20.4189 6.43377 20.4603C6.91942 20.5 7.51388 20.5 8.22609 20.4999H8.26667H15.7333H15.7739C16.4861 20.5 17.0806 20.5 17.5662 20.4603C18.0727 20.4189 18.5483 20.3294 18.998 20.1003C19.6879 19.7488 20.2488 19.1878 20.6004 18.4979C20.8295 18.0482 20.919 17.5727 20.9604 17.0662C21 16.5805 21 15.9861 21 15.2739V15.2333V13.7666C21 13.2143 20.5523 12.7666 20 12.7666C19.4477 12.7666 19 13.2143 19 13.7666V15.2333C19 15.9965 18.9992 16.5088 18.967 16.9033C18.9357 17.2861 18.8799 17.4692 18.8183 17.5899C18.6586 17.9035 18.4036 18.1585 18.09 18.3183C17.9692 18.3798 17.7862 18.4356 17.4034 18.4669C17.0089 18.4992 16.4966 18.4999 15.7333 18.4999H8.26667C7.50343 18.4999 6.99114 18.4992 6.59664 18.4669C6.21381 18.4356 6.03076 18.3798 5.91002 18.3183C5.59641 18.1585 5.34145 17.9035 5.18166 17.5899C5.12013 17.4692 5.06429 17.2861 5.03301 16.9033C5.00078 16.5088 5 15.9965 5 15.2333V13.7666Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }