var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parameters-calculation-container" }, [
    _c("div", { staticClass: "filters" }, [
      _c(
        "div",
        { staticClass: "inputblock" },
        [
          _c(
            "label",
            { staticClass: "inputblock__label", attrs: { for: "parameters" } },
            [_vm._v("Наименование параметров")]
          ),
          _vm._v(" "),
          _c("my-multiselect", {
            attrs: {
              id: "parameters",
              options: _vm.parameters,
              selectedOption: _vm.parameters[0],
              placeholder: "Выберите параметр"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "feather feather-refresh-cw ml-auto",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        },
        [
          _c("polyline", { attrs: { points: "23 4 23 10 17 10" } }),
          _c("polyline", { attrs: { points: "1 20 1 14 7 14" } }),
          _c("path", {
            attrs: {
              d:
                "M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "feather feather-save",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
            }
          }),
          _c("polyline", { attrs: { points: "17 21 17 13 7 13 7 21" } }),
          _c("polyline", { attrs: { points: "7 3 7 8 15 8" } })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "parameters-calculation-table" }, [
      _c("table", { staticClass: "ao-table" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.tableColumns, function(column) {
              return _c(
                "td",
                { key: column.id, staticClass: "ao-table__cell head-cell" },
                [
                  _c("div", { staticClass: "head-cell__container" }, [
                    _c(
                      "a",
                      {
                        staticClass: "ao-table__head-title",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(column.title) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    column.extraColumns
                      ? _c(
                          "div",
                          { staticClass: "ao-table-extra-column-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "ao-table__extra-column" },
                              _vm._l(column.extraColumns, function(
                                extraColumn
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: extraColumn.id,
                                    staticClass: "ao-table__extra-head-title",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(extraColumn.title || "") +
                                        "\n                  "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", { staticClass: "ao-table__row" }, [
            _c("td", { staticClass: "ao-table__cell" }, [
              _c(
                "table",
                { staticStyle: { width: "100%" } },
                _vm._l(_vm.locationRows, function(row) {
                  return _c(
                    "tr",
                    {
                      key: row.id,
                      class: {
                        "hierarchy-table__row_leaf": row.nodeType === "leaf"
                      },
                      style: {
                        "padding-left": row.level * 16 + "px",
                        display: "flex"
                      },
                      attrs: { id: "tr-without-borders" }
                    },
                    [
                      _c(
                        "td",
                        { staticClass: "node-button" },
                        [
                          row.nodeType === "node"
                            ? _c("node-button", {
                                attrs: { isOpen: row.isOpen }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(row.value) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "ml-auto" },
                        [
                          _c("gp-check", {
                            key: "location-checkbox-" + row.id,
                            attrs: {
                              id: "location-checkbox-" + row.id,
                              checked: false
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "ao-table__cell" }, [
              _c(
                "table",
                { staticStyle: { width: "100%" } },
                _vm._l(_vm.dfuRows, function(row) {
                  return _c(
                    "tr",
                    {
                      key: row.id,
                      class: {
                        "hierarchy-table__row_leaf": row.nodeType === "leaf"
                      },
                      style: {
                        "padding-left": row.level * 16 + "px",
                        display: "flex"
                      },
                      attrs: { id: "tr-without-borders" }
                    },
                    [
                      _c(
                        "td",
                        { staticClass: "node-button" },
                        [
                          row.nodeType === "node"
                            ? _c("node-button", {
                                attrs: { isOpen: row.isOpen }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(row.value) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "ml-auto" },
                        [
                          _c("gp-check", {
                            key: "dfu-checkbox-" + row.id,
                            attrs: {
                              id: "dfu-checkbox-" + row.id,
                              checked: false
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "ao-table__cell" },
              _vm._l(_vm.radioLevelItems, function(radio) {
                return _c(
                  "div",
                  { key: radio.id, staticClass: "radio-level-row" },
                  [
                    _c(
                      "gp-check",
                      {
                        attrs: {
                          type: "radio",
                          name: "level",
                          checked: _vm.selectedLevel === radio.name
                        },
                        on: {
                          click: function($event) {
                            _vm.selectedLevel === radio.name
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(radio.value) +
                            "\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("td", { staticClass: "ao-table__cell" }, [
              _c("div", { staticClass: "date-row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date1,
                      expression: "date1"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date1",
                    placeholder: "Дата начала",
                    type: "date"
                  },
                  domProps: { value: _vm.date1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date1 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date2,
                      expression: "date2"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date2",
                    placeholder: "Дата окончания",
                    type: "date"
                  },
                  domProps: { value: _vm.date2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date2 = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "date-row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date3,
                      expression: "date3"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date3",
                    placeholder: "Дата начала",
                    type: "date"
                  },
                  domProps: { value: _vm.date3 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date3 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date4,
                      expression: "date4"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date4",
                    placeholder: "Дата окончания",
                    type: "date"
                  },
                  domProps: { value: _vm.date4 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date4 = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "date-row wihout-calendar" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date5,
                      expression: "date5"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date5",
                    placeholder: "Выберите период",
                    type: "date"
                  },
                  domProps: { value: _vm.date5 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date5 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-addon" }, [_vm._v("—")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date6,
                      expression: "date6"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "date6",
                    placeholder: "Выберите период",
                    type: "date"
                  },
                  domProps: { value: _vm.date6 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date6 = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "ao-table__cell" },
              _vm._l(_vm.chechboxes, function(checkbox) {
                return _c(
                  "div",
                  { key: checkbox.id, staticClass: "checkbox-row" },
                  [
                    _c(
                      "gp-check",
                      {
                        attrs: { checked: checkbox.checked },
                        on: {
                          change: function($event) {
                            checkbox.checked = !checkbox.checked
                          }
                        }
                      },
                      [_c("l10n", { attrs: { value: checkbox.name } })],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "ao-table__cell" },
              _vm._l(_vm.radioAlgorithmItems, function(radio) {
                return _c(
                  "div",
                  { key: radio.id, staticClass: "radio-algorithm-row" },
                  [
                    _c(
                      "gp-check",
                      {
                        attrs: {
                          type: "radio",
                          name: "algorithm",
                          checked: _vm.selectedAlgorithm === radio.name
                        },
                        on: {
                          click: function($event) {
                            _vm.selectedAlgorithm === radio.name
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(radio.value) +
                            "\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }