var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "10",
        height: "8",
        viewBox: "0 0 10 8",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M9.07612 2.23272C9.36901 1.93983 9.36901 1.46496 9.07612 1.17206C8.78322 0.879169 8.30835 0.879169 8.01545 1.17206L4.00949 5.17802L2.07142 3.23995C1.75863 2.92715 1.25149 2.92715 0.938696 3.23995C0.625904 3.55274 0.625904 4.05988 0.938696 4.37267L3.34151 6.77548C3.39323 6.8272 3.45026 6.87037 3.51085 6.90498C3.8041 7.11786 4.21673 7.09211 4.48109 6.82774L9.07612 2.23272Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }