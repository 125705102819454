<template>
  <div class="pim-attributes" :class="{loading:reportId}">
    <div v-if="entries.length">
      <div class="pim-attributes-head">
        <gp-check v-model="showEmptyValues">Show empty attributes</gp-check>
        <my-search v-model="searchString"/>
      </div>
      <div
        class="form-group"
        v-for="{item:field, matches, formattedName} in visibleFields"
        v-if="
          attributes[field.key] && (
            showEmptyValues ||
            attributes[field.key].mixed ||
            attributes[field.key].edited !== undefined ||
            attributes[field.key].vals[cursor])"
        :key="field.key">
        <gp-check
          v-if="field.type == 'boolean'"
          :checked="attributes[field.key].edited || attributes[field.key].vals[cursor]"
          @change="$set(attributes[field.key], 'edited', $event)">
          <template v-if="formattedName">
            <span
              v-for="part in formattedName"
              :class="{matched: part.matched}"
            >{{part.text}}</span>
          </template>
          <template v-else>
            {{field.name}}
          </template>
          <span v-if="attributes[field.key].edited !== undefined" class="edited">
            [edited]
            <a href="javascript:void"
               @click="$delete(attributes[field.key], 'edited')">
              <feather-icon name="x"/>
            </a>
          </span>
          <span v-else-if="attributes[field.key].mixed" class="mixed">
            [mixed]
          </span>
        </gp-check>
        <template v-else>
          <label>
            <template v-if="formattedName">
              <span
                v-for="part in formattedName"
                :class="{matched: part.matched}"
              >{{part.text}}</span>
            </template>
            <template v-else>
              {{field.name}}
            </template>
            <span v-if="attributes[field.key].edited !== undefined" class="edited">
              [edited]
              <a href="javascript:void"
                 @click="$delete(attributes[field.key], 'edited')">
                <feather-icon name="x"/>
              </a>
            </span>
            <span v-else-if="attributes[field.key].mixed" class="mixed"
                  :title="attributes[field.key].mixed.join('\n')">
              [mixed]
              <feather-icon name="info"/>
            </span>
          </label>
          <input
            class="form-control form-control-sm"
            v-if="field.type == 'datetime'"
            type="datetime-local"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', $event.target.value)">
          <input
            class="form-control form-control-sm"
            v-if="field.type == 'date'"
            type="date"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', $event.target.value)">
          <select
            class="form-control form-control-sm"
            v-else-if="field.type == 'dropdown'"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', $event.target.value)">
          </select>
          <textarea
            class="form-control form-control-sm"
            v-else-if="field.type == 'textarea'"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', $event.target.value)">
                    </textarea>
          <input
            class="form-control form-control-sm"
            v-else-if="field.type == 'alphanumeric'"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', $event.target.value)">
          <input
            class="form-control form-control-sm"
            type="number"
            v-else-if="field.type == 'decimal' || field.type == 'numeric'"
            :value="attributes[field.key].edited || attributes[field.key].vals[cursor]"
            @change="$set(attributes[field.key], 'edited', parseFloat($event.target.value))">
          <span v-else>{{field.type}}</span>
        </template>
      </div>
      <div class="pim-hierarchies-attributes-actions">
        <button class="btn btn-sm btn-primary" @click="submitAttributes" :disabled="!attributesChanged">
          Submit changes
        </button>
        <button class="btn btn-sm btn-secondary" @click="discardAttributes" :disabled="!attributesChanged">
          Discard changes
        </button>
      </div>
    </div>
    <gp-data
      v-if="fields && entries.length"
      :stream="stream"
      :groups="['pim-attributes']"
      :filter2="`${idColumn} in [${entries.map(entry => `'${entry.id}'`).join(',')}]`"
      :dims="['id']"
      :vals="fields.map(row => row.key)"
      v-model="valuesReport"
      :throttled="false"
      @reportId="reportId = $event"
    />
  </div>
</template>
<script>
let utils = require('../my-utils');
let Fuse = require('fuse.js').default;

module.exports = {
  props: {
    entry: { type: Object },
    entries: { type: Array },
    stream: { type: String, default: 'categories' },
    search: { type: Object },
    fields: { type: Array },
    username: { type: String },
    idColumn: { type: String, default: 'id' },
  },
  data() {
    return {
      reportId: null,
      valuesReport: null,
      searchString: '',
      attributes: {},
      showEmptyValues: false,
    };
  },
  watch: {
    valuesReport() {
      if (this.valuesReport.rows.length == 0) {
        this.attributes = {};
        return;
      }

      let keys = ['id'].concat(this.fields.map(field => field.key));
      let vals = _.unzip(this.valuesReport.rows);
      let types = ['string'].concat(this.fields.map(field => field.type));

      for (let i=0; i<vals.length; ++i) {
        if (types[i] == 'date') {
          vals[i] = vals[i].map(x => x ? moment(new Date(x)).format('YYYY-MM-DD') : '');
        }
        if (types[i] == 'datetime') {
          vals[i] = vals[i].map(x => x ? moment(new Date(x)).format('YYYY-MM-DDThh:mm:ss') : '');
        }
      }

      let attributes = {};
      let same = vals.map(xs => xs.every(x => x == xs[0]));

      for ([key, vals, same] of _.zip(keys, vals, same)) {
        attributes[key] = {
          vals,
          mixed: !same ? _.sortBy(_.uniq(vals)) : null,
          edited: undefined,
        };
      }
      this.attributes = attributes;
    },
  },
  computed: {
    cursor() {
      if (this.entries) {
        let cursor = this.entries.indexOf(this.entry);
        return cursor != -1 ? cursor : 0;
      } else {
        return 0;
      }
    },
    fieldsFuse() {
      return new Fuse(this.validFields, this.search);
    },
    visibleFields() {
      return this.searchString ?
        this.fieldsFuse
          .search(this.searchString)
          .map(entry => {
            entry.formattedName =
                            utils.formatSearchItem(
                              entry.item.filed_name,
                              entry.matches.filter(match => match.key == 'filed_name'));
            return entry;
          }) : this.fields.map(field => ({ item: field }));
    },
    attributesChanged() {
      return _(this.attributes).values().some(attribute => attribute.edited !== undefined);
    },
  },
  methods: {
    async submitAttributes() {
      let changes = {};
      for (let key of _.keys(this.attributes)) {
        let attribute = this.attributes[key];
        let value = attribute.edited;
        if (value !== undefined) {
          changes[key] = value;
          this.$set(attribute, 'vals', attribute.vals.map(() => value));
          this.$delete(attribute, 'edited');
        }
      }

      if (_.isEmpty(changes)) {
        return;
      }

      let actions = [];
      let createUser = this.username;
      let createTime = new Date().toISOString().split('.')[0];
      for (let key of _.keys(changes)) {
        let field = this.fields.find(field => field.key == key);
        let stream = `attr_category_${key}`;
        let value = changes[key];
        let records = [];
        for (let entry of this.entries || [this.entry]) {
          records.push([
            'manual',
            0,
            entry.id,
            changes[key],
            createTime,
            createUser,
            createTime,
            createUser,
          ]);
          let attr = entry.attrs.find(attr => attr.key == key);
          if (attr) {
            this.$st(attr, 'val', value);
          }
        }
        actions.push(
          `\t${key}:appendRecords(
                        stream: ${utils.quote(stream)},
                        format: "json",
                        records: ${utils.quote(JSON.stringify(records))})`);
      }

      if (_.isEmpty(actions)) {
        return;
      }
      let query = `mutation {\n${actions.join('\m')}}`;

      await fetch('/graphql?__submitPimAttributes__', {
        method: 'POST',
        body: JSON.stringify({ query }),
        headers: { 'Content-Type': 'application/json' },
      });
    },
    discardAttributes() {
      for (let attribute of _.values(this.attributes)) {
        if (attribute.edited !== undefined) {
          this.$delete(attribute, 'edited');
        }
      }
    },
  },
};
</script>
