<template>
  <autoorder-modal
    :modalVisible="isVisible"
    :modalTitle="headerTitle"
    minWidth="45%"
    closable
    closeButton
    @onClose="handleDiscard"
    :datasets="datasets"
  >
    <template #main>
      <chart-settings :datasets="datasets" />
    </template>
  </autoorder-modal>
</template>

<script>
const AutoorderModal = require('../autoorder-modal.vue').default;
const ChartSettings = require('./chart-settings.vue').default;

module.exports = {
  components: {
    AutoorderModal,
    ChartSettings,
  },

  model: {
    prop: 'isVisible',
    event: 'onVisibleChange',
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerTitle: {
      type: String,
      required: false,
      default: false,
    },
    datasets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    handleDiscard() {
      this.$emit('onVisibleChange', false);
    },
    handleConfirm() {
      this.$emit('onVisibleChange', false);
    },
  },
};
</script>

  <style scoped>
    .vc-container.full-calendar {
      width: 100%;
    }

    .full-calendar>>>.vc-pane-container {
      overflow-y: auto;
    }
  </style>
