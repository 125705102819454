var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "ao-checkbox",
      class: {
        "ao-checkbox_checked": _vm.isChecked,
        "ao-checkbox_disabled": _vm.disabled
      }
    },
    [
      _vm.isChecked ? _c("check-icon") : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "ao-checkbox__input",
        attrs: {
          id: _vm.id,
          disabled: _vm.disabled,
          type: "checkbox",
          name: "ao-checkbox"
        },
        domProps: { checked: _vm.isChecked },
        on: { change: _vm.handleChangeEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }